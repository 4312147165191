import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { RECOMMENDED_VEHICLES } = ANALYTICS_CATEGORIES;

interface TriggerRecommendedVehicleCardClickedEventParams {
	id: number;
	url: string;
}

export const triggerRecommendedVehicleCardClickedEvent = ({
	id,
	url,
}: TriggerRecommendedVehicleCardClickedEventParams): void => {
	const eventPayload = getSnowplowEventPayload({
		category: RECOMMENDED_VEHICLES,
		customData: { label: `${id}`, url: `${url}` },
		name: 'dlr_recommended_vehicle_card_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});
	logAnalyticsEvent({ eventPayload });
};
