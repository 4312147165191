import { useContext } from 'react';
import flatten from 'lodash/flatten';
import type { StoreApi } from 'zustand';
import { useStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { clearError, setError } from 'Stores/FilterStore/FilterStoreHelpers';
import { createGenericStoreWithDevtools } from 'Stores/store';
import { ModelsContext } from 'Stores/StoresContext/StoresContext';
import type { Model } from 'Types/model';
import { isDev } from 'Utilities/helpers';
import { getModelsDictionaries, getTotalCounts } from 'Utilities/makeModel/makeModelStore.helpers';

import type { InitialModelsStoreValues, ModelCounts, ModelsStore } from './ModelsStore.types';

const createModelsStore = (initialValues: InitialModelsStoreValues) =>
	devtools<ModelsStore>(
		(set, get) => ({
			clearError: clearError(set),
			error: false,
			getModelById: (id) => {
				const { modelsById } = get();
				return modelsById.get(id);
			},
			getModelIds: ({ makeIds = [], modelNames = [] }) => {
				const { getModelsByMakeId } = get();
				const modelIdCollection: Model['id'][] = [];

				return modelNames?.reduce((acc, modelName, index) => {
					const makeId = makeIds?.[index];
					const modelsByMakeId = getModelsByMakeId(makeId);
					const model = modelsByMakeId?.find(({ name }) => name === modelName);
					if (model) {
						acc.push(model.id);
					}
					return acc;
				}, modelIdCollection);
			},
			// @ts-expect-error - In 4.9.5 it can't infer we filter out undefined but in newer versions it can
			getModelsById: (ids = []) => {
				const { modelsById } = get();
				const modelsIds = ids.map((id) => modelsById.get(id)).filter((model) => model !== undefined);
				return modelsIds;
			},
			getModelsByMakeId: (makeId) => {
				const { modelsByMakeId } = get();
				return modelsByMakeId.get(makeId);
			},
			// @ts-expect-error - In 4.9.5 it can't infer we filter out undefined but in newer versions it can
			getModelsByMakeIds: (makeIds = []) => {
				const { modelsByMakeId } = get();
				return flatten(makeIds?.map((makeId) => modelsByMakeId.get(makeId))).filter((model) => model !== undefined);
			},
			models: initialValues?.models,
			modelsById: initialValues?.modelsById,
			modelsByMakeId: initialValues?.modelsByMakeId,
			setCounts: (counts: ModelCounts) => {
				if (!counts) {
					return;
				}
				const { models: storeModels } = get();

				const models = getTotalCounts({ counts, entities: storeModels });

				set({ models }, false, { models, type: 'setCounts' });
			},
			setError: setError(set),
			setModels: (models: Model[]) => {
				set({ models, ...getModelsDictionaries(models) }, false, { models, type: 'setModels' });
			},
		}),
		{ enabled: isDev, name: 'ModelsStore' },
	);

export const createModelsStoreWithInitial = (models: Model[] = []): StoreApi<ModelsStore> => {
	const { modelsById, modelsByMakeId } = getModelsDictionaries(models);
	return createGenericStoreWithDevtools(createModelsStore({ models, modelsById, modelsByMakeId }));
};

export const useModelsStore = <T>(selector: (state: ModelsStore) => T, equalityFn?: () => boolean): T => {
	const store = useContext(ModelsContext);
	if (!store) {
		throw new Error('Missing ModelsStoreContext.Provider in the tree');
	}
	return useStore(store, selector, equalityFn);
};

export const useGetModelsById = (): ModelsStore['getModelsById'] => useModelsStore((store) => store.getModelsById);
export const useGetModelsByMakeId = (): ModelsStore['getModelsByMakeId'] =>
	useModelsStore((store) => store.getModelsByMakeId);
export const useGetModelsByMakeIds = (): ModelsStore['getModelsByMakeIds'] =>
	useModelsStore((store) => store.getModelsByMakeIds);
export const useGetModelIds = (): ModelsStore['getModelIds'] => useModelsStore((store) => store.getModelIds);
export const useSetModelsCount = (): ModelsStore['setCounts'] => useModelsStore((store) => store.setCounts);

export const useGetModelById = (): ModelsStore['getModelById'] => useModelsStore((store) => store.getModelById);

export default useModelsStore;
