import { z } from 'zod';

export enum ServiceRecordValues {
	FULL = 'full',
	NO = 'no',
	NOT_APPLICABLE = 'not_applicable',
	NOT_PROVIDED = '-',
	PARTIAL = 'partial',
	YES = 'yes',
}

export const ServiceHistorySchema = z.nativeEnum(ServiceRecordValues);

export const ServiceHistoryRecordItem = z.object({
	createdAt: z.string(),
	date: z.string(),
	id: z.number(),
	mileage: z.number().nullable(),
	serviceCentre: z.string().nullable(),
	serviceCentreId: z.string().nullable(),
	type: z.string(),
	updatedAt: z.string(),
	userUpdated: z.boolean(),
	vehicleImageId: z.number(),
});
