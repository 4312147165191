import snakeCase from 'lodash/snakeCase';

import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerVehicleCardClickedEvent = (category: string, enquiryId: number): void => {
	const gaEventPayload = {
		action: 'Vehicle card click',
		category,
		isLegacyEvent: false,
		label: String(enquiryId),
	};

	const eventPayload = getSnowplowEventPayload({
		category,
		customData: { label: String(enquiryId) },
		name: `dlr_${snakeCase(category)}_vehicle_card_click`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({ eventPayload: gaEventPayload, provider: GOOGLE_ANALYTICS });
};
