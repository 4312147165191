import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../analytics.const';

const {
	MARKETPLACE: { SAVED_SEARCH_LIST },
} = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerUserClickedShowVehiclesEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: SAVED_SEARCH_LIST,
		name: 'dlr_saved_search_show_vehicles_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'User clicked on show X vehicles',
			category: SAVED_SEARCH_LIST,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};
