import React from 'react';
import T from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';

import texts from '../texts.json';

import styles from './NotFound.module.scss';

T.setTexts(texts);

interface NotFoundProps {
	buttonText?: string;
	description?: React.ReactNode;
	message: React.ReactNode;
	onClickButton?: () => void;
}

const NotFound: React.FC<NotFoundProps> = ({ buttonText, description, message = '', onClickButton }) => (
	<div className={styles.notFoundArea}>
		<section className={styles.notFound}>
			<h1 className={styles.title}>{message}</h1>
			{description && <p className={styles.description}>{description}</p>}
			<div className={styles.button}>
				<Button
					fullWidth
					label={buttonText || String(T.translate('notFoundPage.allVehicles'))}
					onClick={onClickButton}
				/>
			</div>
		</section>
	</div>
);

export default NotFound;
