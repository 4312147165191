import { getSnowplowEventPayload, logAnalyticsEvent } from '../../analytics';
import { ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../../analytics.const';
import { GAEventPayload } from '../../analytics.types';

import { VEHICLE_DETAILS_IMAGE_EVENTS, VEHICLE_DETAILS_IMAGE_SECTIONS } from './galleryEvents.consts';
import { VehicleDetailsImageSectionsKeys } from './galleryEvents.types';

const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerSingleClickOnImageEvent = (section: VehicleDetailsImageSectionsKeys): void => {
	const snowplowEventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS_IMAGE_SECTIONS[section],
		name: VEHICLE_DETAILS_IMAGE_EVENTS.SINGLE_CLICK.snowplow,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	const googleAnalyticsEventPayload: GAEventPayload = {
		action: VEHICLE_DETAILS_IMAGE_EVENTS.SINGLE_CLICK.ga,
		category: VEHICLE_DETAILS_IMAGE_SECTIONS[section],
		isLegacyEvent: false,
		label: null,
	};

	logAnalyticsEvent({ eventPayload: snowplowEventPayload });
	logAnalyticsEvent({ eventPayload: googleAnalyticsEventPayload, provider: GOOGLE_ANALYTICS });
};
