import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const {
	MARKETPLACE: { SORTING },
} = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

export const triggerRefreshVehicleListButtonClickedEvent = (): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: SORTING,
				customData: { label: 'Bid count sort refresh clicked' },
				name: 'dlr_refresh_clicked',
				schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
				version: SNOWPLOW_EVENTS.CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
		{
			eventPayload: {
				action: 'Refresh clicked',
				category: SORTING,
				isLegacyEvent: false,
				label: 'Bid count sort refresh clicked',
			},
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
