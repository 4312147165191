import { ClientError } from 'Services/httpStatusChecker';
import { isSSR } from 'Utilities/helpers';

import { BeforeSendClientLogProps, GetShouldReportClientLogProps } from './logger.types';

export const VALID_CLIENT_PROTOCOLS = { http: 'http:', https: 'https:' };

const isValidClient = (): boolean => Object.values(VALID_CLIENT_PROTOCOLS).includes(window?.location?.protocol);

export const getShouldReportClientLog = ({ statusCode, user }: GetShouldReportClientLogProps): boolean => {
	const isDownloadedPage = !isSSR() && !isValidClient(); // Prevent errors being sent for users who download pages
	const isHandledError = ClientError.isForbidden(statusCode) && !user; // 403s are shown to users so we do not need to send an error
	const shouldSendLog = !isDownloadedPage && !isHandledError;

	return shouldSendLog;
};

// Functionality that applies generically, to all client logs
// This can be extended to add more validations or to enhance client logs
export const beforeSendClientLog = ({ statusCode, user }: BeforeSendClientLogProps): boolean => {
	return getShouldReportClientLog({ statusCode, user });
};
