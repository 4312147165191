import { addGlobalContexts, ConditionalContextProvider, removeGlobalContexts } from '@snowplow/browser-tracker';

import { getSchemaURL } from '../analytics';
import { GlobalContext, GlobalContextData, SnowplowEvent } from '../analytics.types';

export const SNOWPLOW_CONTEXTS = {
	user: {},
	vehicleList: {},
};

interface CreateConditionalGlobalContextProps {
	conditionalFilter: () => boolean;
	previousGlobalContextDataKey: keyof typeof SNOWPLOW_CONTEXTS;
	updatedGlobalContextData: GlobalContext<GlobalContextData>;
}

interface GetGlobalContextParams {
	data: GlobalContextData;
	schemaEvent: SnowplowEvent;
}

export const getGlobalContext = ({ data, schemaEvent }: GetGlobalContextParams): GlobalContext<GlobalContextData> => ({
	data,
	schema: getSchemaURL(schemaEvent),
});

export const createConditionalGlobalContext = ({
	conditionalFilter,
	previousGlobalContextDataKey,
	updatedGlobalContextData,
}: CreateConditionalGlobalContextProps): void => {
	removeGlobalContexts([
		[conditionalFilter, SNOWPLOW_CONTEXTS[previousGlobalContextDataKey]] as ConditionalContextProvider,
	]);

	addGlobalContexts([[conditionalFilter, updatedGlobalContextData]]);

	SNOWPLOW_CONTEXTS[previousGlobalContextDataKey] = updatedGlobalContextData;
};
