import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

interface TriggerShortlistVehicleShortlistClickedEvent {
	id: number;
	isChecked: boolean;
	page: string;
}

export const triggerShortlistVehicleShortlistClickedEvent = ({
	id,
	isChecked,
	page,
}: TriggerShortlistVehicleShortlistClickedEvent): void => {
	const action = isChecked ? 'selected' : 'unselected';
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS,
		customData: { label: `${page} (${id})` },
		name: `dlr_shortlist_vehicle_shortlist_${action}_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});
	logAnalyticsEvent({ eventPayload });
};
