import type { ZodError, ZodIssue } from 'zod';
import { z } from 'zod';

import { logger } from 'Services/logger/logger';

import type { LogLevel } from './logger/logger.types';

type LogZodValidationErrorParams = {
	error: z.ZodError;
	errorType?: LogLevel;
	functionName: string;
};

export const getZodFormErrorCodes = (error: ZodError): z.ZodIssueCode[] =>
	error.flatten((issue: ZodIssue) => issue.code).formErrors;

export const logZodValidationError = ({ error, functionName }: LogZodValidationErrorParams): void => {
	logger.error({
		error,
		message: `Zod schema validation error in ${functionName}`,
		scope: 'logZodValidationError',
	});
};

// This function is used to log Zod validation errors with composite keys, flattening the error paths to a single string
export const logZodValidationWithCompositeKey = ({
	error,
	errorType,
	functionName,
}: LogZodValidationErrorParams): void => {
	// TODO: Remove this DEP-52
	const errorLevel = errorType ?? 'warn';
	try {
		const flattenZodErrorPaths = error.issues.map((issue) => {
			const path = issue.path
				.map((segment) => {
					if (typeof segment === 'number') {
						return `[${segment}]`;
					}
					return segment;
				})
				.join('.')
				.replace(/\.\[/g, '[');

			let compositeIssueKey = '';
			if (issue.code === 'invalid_type') {
				compositeIssueKey = `${path}: Expected "${issue.expected}" but received "${issue.received}"`;
			}
			return {
				...issue,
				compositeIssueKey,
				path: [path],
			};
		});
		error.issues = flattenZodErrorPaths;
		logger[errorLevel]({
			error,
			message: `Zod schema validation ${errorLevel} in ${functionName}`,
			scope: 'logZodValidationWithCompositeKey',
		});
	} catch (err) {
		logger[errorLevel]({
			error,
			message: `Zod schema validation ${errorLevel} in ${functionName}`,
			scope: 'logZodValidationWithCompositeKey',
		});
	}
};

export const zodUnionStringEnumOrArrayOfEnum = <T extends z.EnumLike>(
	nativeEnum: T,
): z.ZodUnion<[z.ZodNativeEnum<T>, z.ZodArray<z.ZodNativeEnum<T>, 'many'>]> =>
	z.union([z.nativeEnum(nativeEnum), z.array(z.nativeEnum(nativeEnum))]);

export const zodUnionStringOrArrayOfString = (): z.ZodUnion<[z.ZodString, z.ZodArray<z.ZodString, 'many'>]> =>
	z.union([z.string(), z.array(z.string())]);

export const zodOptionalBoolean = (): z.ZodOptional<z.ZodBoolean> => z.boolean().optional();
export const zodOptionalString = (): z.ZodOptional<z.ZodString> => z.string().optional();
export const zodCoerceOptionalNumber = (): z.ZodOptional<z.ZodNumber> => z.coerce.number().min(0).optional();
export const zodCoerceOptionalNullableNumber = (): z.ZodNullable<z.ZodOptional<z.ZodNumber>> =>
	zodCoerceOptionalNumber().nullable();
