import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../analytics.const';

interface TriggerManageSavedSearchDailyStockToggleProps {
	id: number;
	sendEmailAlert: boolean;
}

const {
	MARKETPLACE: { MANAGE_SAVED_SEARCH },
} = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerManageSavedSearchDailyStockToggleEvent = ({
	id,
	sendEmailAlert,
}: TriggerManageSavedSearchDailyStockToggleProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: MANAGE_SAVED_SEARCH,
		customData: { label: `${id}` },
		name: `dlr_manage_saved_search_daily_stock_${sendEmailAlert ? 'on' : 'off'}`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	const gaEventPayload = {
		action: `Daily stock alert toggled ${sendEmailAlert ? 'on' : 'off'}`,
		category: MANAGE_SAVED_SEARCH,
		isLegacyEvent: false,
		label: `${id}`,
	};

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({ eventPayload: gaEventPayload, provider: GOOGLE_ANALYTICS });
};
