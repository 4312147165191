import type { GetVehicle } from '../helpers/vehicle.schema';
import { getChargingCable } from '../helpers/vehicle.schema.helpers';
import { FALLBACK } from '../schemas/schema.const';

export const specificationSectionTransformer = (vehicle: GetVehicle) => ({
	batteryOwnership: vehicle.areBatteriesInLease ?? null,
	bodyType: vehicle.spec.body ?? '', // nullable in stage
	chargingCable: getChargingCable(vehicle.chargingCables),
	engineSize: vehicle.spec.engineSize,
	exterior: vehicle.spec.colour,
	fuel: vehicle.spec.fuel,
	interior: vehicle.interiorType,
	transmission: vehicle.spec.transmission ?? FALLBACK, // nullable in stage
});
