/* eslint-disable local-rules/boolean-naming */
// TODO: THIS FILE IS A TEMPORARY FILE TO TEST THE VALIDATION OF THE SCHEMA ON THE OLD VEHICLE DETAILS PAGE
import { z } from 'zod';

import { GetVehicleSchema, ServiceHistoryRecordSchema } from './vehicles/helpers/vehicle.schema';
import type { AncestrySchema } from './vehicles/schemas/limitations.schema';
import { LimitationsSchema as RealLimitationsSchema } from './vehicles/schemas/limitations.schema';

// Types for Schema validation
type GetVehicleShape = typeof GetVehicleSchema.shape;

type LimitationsSchemaShape = typeof RealLimitationsSchema.shape;

type AncestrySchemaShape = typeof AncestrySchema.shape;

type SyncSchema<T> = z.ZodObject<{
	[K in keyof T]: z.ZodTypeAny;
}>;

type LimitationsSchemaType = SyncSchema<LimitationsSchemaShape>;
type AncestrySchemaShapeType = SyncSchema<AncestrySchemaShape>;

type ValidateNewVehicleSchemaShape = SyncSchema<GetVehicleShape>;

const LimitationsSchema: LimitationsSchemaType = z
	.object({
		ancestry: z
			.array<AncestrySchemaShapeType>(
				z.object({
					dateFrom: z.string(),
					dateTo: z.string(),
					recordType: z.string(),
					source: z.string(),
				}),
			)
			.optional(),
	})
	.merge(RealLimitationsSchema.omit({ ancestry: true }));

// This was made to demonstrate how to implement. We should implement the sections as we need
export const ValidateNewVehicleSchema: ValidateNewVehicleSchemaShape = z
	.object({
		exclusiveToDealer: z.string(),
		isVatQualifying: z.boolean().optional().nullable(),
		isVehicleOnFinance: z.boolean().nullable(),
		keepersList: z.array(
			z.object({
				startDate: z.string(),
			}),
		),
		keeperStartDate: z.string(),
		keepPrivatePlate: z.boolean().optional().nullable(),
		keysCount: z.number().nullable(),
		limitations: LimitationsSchema.nullable(),
		mileageRecords: z.array(
			z.object({
				isSuspicious: z.boolean(),
				mileage: z.number(),
				recordTaken: z.string(),
				source: z.string(),
			}),
		),
		serviceHistoryRecords: ServiceHistoryRecordSchema.optional().nullable(),
	})
	.merge(
		GetVehicleSchema.omit({
			exclusiveToDealer: true,
			isVatQualifying: true,
			isVehicleOnFinance: true,
			keepersList: true,
			keeperStartDate: true,
			keepPrivatePlate: true,
			keysCount: true,
			limitations: true,
			mileageRecords: true,
			serviceHistoryRecords: true,
		}),
	);
