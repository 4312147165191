/* eslint-disable camelcase */
export interface EnquiryState {
	category: StateCategory;
	id: number;
	name: StateName;
	slug: StateSlug;
	transportRequested?: TransportRequestedState;
}

export enum StateCategory {
	cancelled = 'cancelled',
	forSale = 'for_sale',
	junk = 'junk',
	motorwayPro = 'motorway_pro',
	profiled = 'profiled',
	quoted = 'quoted',
	rejected = 'rejected',
	sold = 'sold',
	unsold = 'unsold',
}

export enum ProfiledCategory {
	fullDetails = 'full_details',
	fullDetailsContacted = 'full_details_contacted',
	fullDetailsWait = 'full_details_wait',
	partDetails = 'part_details',
	partDetailsContacted = 'part_details_contacted',
	qaFailed = 'qa_failed',
	qaOnHold = 'qa_on_hold',
	qaReady = 'qa_ready',
}

export enum ForSaleCategory {
	forSale = 'for_sale',
	readyForSale = 'ready_for_sale',
	saleActive = 'sale_active',
	underOffer = 'under_offer',
}

export enum SoldCategory {
	sold = 'sold',
	soldConfirmed = 'sold_confirmed',
	soldDocumentsReviewed = 'sold_documents_reviewed',
	soldInvoicePaid = 'sold_invoice_paid',
	soldInvoiceRaised = 'sold_invoice_raised',
	soldPurchaseConfirmed = 'sold_purchase_confirmed',
	soldWait = 'sold_wait',
}

export enum UnsoldCategory {
	unsoldBelowReserveBid = 'unsold_below_reserve_bid',
	unsoldNoBids = 'unsold_no_bids',
}

export enum CanceledCategory {
	cancelledDealerWithdrewBid = 'cancelled_dealer_withdrew_bid',
	cancelledNotAsDescribed = 'cancelled_not_as_described',
	cancelledNotCollectedInTime = 'cancelled_not_collected_in_time',
	cancelledOtherDealerIssue = 'cancelled_other_dealer_issue',
	cancelledOtherSellerIssue = 'cancelled_other_seller_issue',
	cancelledSellerUncontactable = 'cancelled_seller_uncontactable',
	cancelledSoldElsewhere = 'cancelled_sold_elsewhere',
}

export enum RejectedCategory {
	carGone = 'car_gone',
	customerKeepCar = 'customer_keep_car',
	customerLost = 'customer_lost',
	tooExpensive = 'too_expensive',
	unsellable = 'unsellable',
	writeOff = 'write_off',
}

export enum MotorwayProCategory {
	noLongerForSale = 'no_longer_for_sale',
	notYetForSale = 'not_yet_for_sale',
}

export enum StateName {
	cancelled_dealer_withdrew_bid = 'Cancelled - Dealer withdrew bid',
	cancelled_not_as_described = 'Cancelled - Not as described',
	cancelled_not_collected_in_time = 'Cancelled - Not collected in time',
	cancelled_other_dealer_issue = 'Cancelled - Other dealer issue',
	cancelled_other_seller_issue = 'Cancelled - Other seller issue',
	cancelled_seller_uncontactable = 'Cancelled - Seller uncontactable',
	cancelled_sold_elsewhere = 'Cancelled - Sold elsewhere',
	car_gone = 'Rejected - Car sold elsewhere',
	customer_keep_car = 'Rejected - Seller keeping car',
	customer_lost = 'Rejected - Lost contact with seller',
	for_sale = 'For sale',
	full_details = 'Full details',
	full_details_agent_profiling = 'Full details - Agent profiling',
	full_details_contacted = 'Full details - Seller contacted',
	full_details_wait = 'Full details - Awaiting photos',
	manual_quote = 'Manual quote',
	part_details = 'Part details',
	part_details_contacted = 'Part details - Seller contacted',
	qa_failed = 'QA - Failed',
	qa_on_hold = 'QA - On hold',
	qa_ready = 'QA - Ready',
	quote_accepted = 'Quote accepted',
	quoted = 'Quoted',
	quoted_seller_contacted = 'Quoted - Seller contacted',
	ready_for_sale = 'Ready for sale',
	sale_active = 'For sale - Sale active',
	sold = 'Sold',
	sold_confirmed = 'Sold - Seller confirmed',
	sold_documents_reviewed = 'Sold - Documents reviewed',
	sold_invoice_paid = 'Sold - Invoice paid',
	sold_invoice_raised = 'Sold - Invoice raised',
	sold_purchase_confirmed = 'Sold - Purchase confirmed',
	sold_wait = 'Sold - Awaiting confirmation',
	too_expensive = 'Rejected - Price too high',
	under_offer = 'Under offer',
	unsellable = 'Rejected - Unsellable',
	unsold_below_reserve_bid = 'Unsold - Below reserve bid',
	unsold_no_bids = 'Unsold - No bids',
	write_off = 'Rejected - Write-off',
}

export type StateSlug =
	| ForSaleCategory
	| CanceledCategory
	| RejectedCategory
	| UnsoldCategory
	| SoldCategory
	| ProfiledCategory
	| MotorwayProCategory;

export enum TransportRequestedValues {
	NOT_REQUESTED = 'not-requested',
	REQUESTED = 'requested',
	REQUESTED_CANCELLED = 'requested-cancelled',
}

export type TransportRequestedState = TransportRequestedValues | null;
