import { ReactQueryDevtools as Devtools } from '@tanstack/react-query-devtools';

import { isProd } from 'Utilities/helpers';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';

export const ReactQueryDevtools: React.FC = () => {
	const isReactQueryDevtoolsEnabled = useFeatureToggle(FEATURES.reactQueryDevtools);
	const shouldDisplayDevtools = isReactQueryDevtoolsEnabled && !isProd;

	return shouldDisplayDevtools && <Devtools initialIsOpen={false} />;
};
