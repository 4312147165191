import { getGenericModels } from 'Services/api/genericModels';
import { RESPONSE_STATUS } from 'Services/httpStatusChecker';
import { ERRORS } from 'Utilities/consts';
import { fetchWithExceptionLogger } from 'Utilities/error/fetchWithExceptionLogger';

const fetchModelsWithExceptionLogger = async (context) =>
	fetchWithExceptionLogger({
		callback: getGenericModels.bind(null, { context }),
		errorMessage: ERRORS.UNABLE_TO_FETCH_INITIAL_MODELS,
		extra: { context },
		ignoredResponses: [RESPONSE_STATUS.client.notFound],
	});

export default fetchModelsWithExceptionLogger;
