import getConfig from 'next/config';

import sharedConfig from '../../config/sharedConfig';

import { PRODUCTION_APP_NAME as PRODUCTION_APP_NAME_CONST } from './isProd.consts';

/*
 * This helper is used by both BE and FE.
 * It is used by BE before nextjs is initialized
 * therefore we need to check if getConfig is not empty
 */
const APP_NAME = getConfig() ? getConfig().publicRuntimeConfig.APP_REQUEST_HEADER : sharedConfig.APP_REQUEST_HEADER;

export const PRODUCTION_APP_NAME = PRODUCTION_APP_NAME_CONST;

const isProd = APP_NAME === PRODUCTION_APP_NAME;

export default isProd;
