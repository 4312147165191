import type { AxiosResponse } from 'axios';
import type { NextPageContext } from 'next';

import isomorphicApi from 'Utilities/helpers/isomorphicApi';
import { API_ROUTES } from 'Utilities/routes';

import axios from '../axios';

export const getFeatureFlags = async (ctx: NextPageContext): Promise<AxiosResponse> =>
	isomorphicApi(ctx, {
		browser: async () => axios.get(API_ROUTES.FEATURE_FLAGS),
		server: async (api) => api.getFeatureFlags(),
	});
