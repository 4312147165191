import { ENVIRONMENTS } from 'Utilities/consts';

import isProd from './isProd';
import isStaging from './isStaging';

const { DEVELOPMENT, PRODUCTION, STAGING } = ENVIRONMENTS;

const getEnvironmentName = (): string => (isProd && PRODUCTION) || (isStaging && STAGING) || DEVELOPMENT;

export default getEnvironmentName;
