import type { AxiosResponse } from 'axios';
import type { NextPageContext } from 'next';

import { ValidateNewVehicleSchema } from 'Pages/temp-vehicle-details-schema';
import vehiclesMapper from 'Server/api/mappers/vehicles';
import axios from 'Services/axios';
import { resolveParams, resolveVehicleListParams } from 'Services/services.helpers';
import { logZodValidationWithCompositeKey } from 'Services/zod.helpers';
import type { MakeModelCount } from 'Types/make';
import isomorphicApi from 'Utilities/helpers/isomorphicApi';
import { API_ROUTES } from 'Utilities/routes';

import type { ApiSchemaMake } from './schema.types';
import type {
	GetMakesRequestQuery,
	GetSimilarVehiclesResponse,
	GetSimilarVehiclesResponseWithVersion,
	GetVehicleListRequestQuery,
	GetVehicleListResponse,
	GetVehicleRequestQuery,
} from './vehicles.types';

export const getMakes = async (ctx: NextPageContext, query: GetMakesRequestQuery = {}): Promise<ApiSchemaMake[]> =>
	isomorphicApi(ctx, {
		browser: async () => axios.get(`/api/makes${resolveParams(query)}`),
		server: async (api) => api.getMakes(query),
	});

export const getVehicle = async <T>(query: GetVehicleRequestQuery, ctx?: NextPageContext): Promise<T> =>
	isomorphicApi(ctx, {
		browser: async () => axios.get(`/api/vehicle${resolveParams(query)}`),
		server: async (api) => {
			const dealerId = ctx?.req?.user?.dealerId;
			const dealerContactId = ctx?.req?.user?.id;

			const vehicle = await api.getVehicle({
				...query,
				dealerContactId,
				dealerId,
			});

			if (query.isSchemaForList) {
				return [vehiclesMapper.vehicleForList(vehicle[0])];
			}

			// @ts-ignore
			const isLogVdpSchemaDifferenceEnabled = ctx?.featureFlags?.logVdpSchemaDifference;
			if (isLogVdpSchemaDifferenceEnabled) {
				// TODO: REMOVE ONCE NEW VDP IS LIVE DEP-52

				const validateOldVDPPageData = ValidateNewVehicleSchema.safeParse(vehicle);
				if (!validateOldVDPPageData.success) {
					logZodValidationWithCompositeKey({
						error: validateOldVDPPageData.error,
						errorType: 'warn',
						functionName: 'validateOldVDPPageData',
					});
				}
			}

			return vehiclesMapper.resolveVehicleDetailsPageMapper({
				req: ctx?.req,
				vehicle,
			});
		},
	});

export const getVehicles = async (
	query: GetVehicleListRequestQuery,
	signal?: AbortSignal,
): Promise<GetVehicleListResponse> => {
	const res = await axios.get(API_ROUTES.VEHICLE_LIST + resolveVehicleListParams(query), { signal });
	return res.data;
};

export const getSimilarVehicles = async (
	query: GetVehicleListRequestQuery,
	signal?: AbortSignal,
): Promise<GetSimilarVehiclesResponse> => {
	const res = await axios.get(`/api/vehicle/similarVehicles${resolveVehicleListParams(query)}`, { signal });
	return res.data;
};

export const getSimilarVehiclesByMakeAndModel = async (
	query: GetVehicleListRequestQuery,
	signal?: AbortSignal,
): Promise<GetSimilarVehiclesResponseWithVersion> => {
	const res = await axios.get(`${API_ROUTES.SIMILAR_VEHICLES_BY_MAKE_AND_MODEL}${resolveVehicleListParams(query)}`, {
		signal,
	});
	return res.data;
};

export const getMakeModelCount = async (query: GetVehicleListRequestQuery): Promise<MakeModelCount> => {
	const res = await axios.get(`${API_ROUTES.MAKE_MODEL_COUNT}${resolveVehicleListParams(query)}`);
	return res.data;
};

export const cancelBid = async (id = ''): Promise<AxiosResponse> => axios.post(`/api/cancel_bid/${id}`);

export const generateVehicleListCsv = (filters: string): Promise<AxiosResponse> =>
	axios.post(API_ROUTES.GENERATE_VEHICLE_LIST_CSV, { filters });

export const getVehicleListCsv = (): Promise<AxiosResponse> => axios.get(API_ROUTES.GET_VEHICLE_LIST_CSV);
