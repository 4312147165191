import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;
const { CLICK } = SNOWPLOW_EVENTS;

export const triggerAdditionalSpecAccordionOpenedEvent = (): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: VEHICLE_DETAILS,
				name: `dlr_additional_spec_accordion_opened`,
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
		{
			eventPayload: {
				action: `Additional spec accordion opened`,
				category: VEHICLE_DETAILS,
				isLegacyEvent: false,
				label: '',
			},
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
