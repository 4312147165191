import { useContext, useEffect } from 'react';
import T from 'i18n-react';

import { useToast } from '@motorway/motorway-storybook-cra';

import { isUnsupportedBrowser } from 'Components/UnsupportedBrowser/UnsupportedBrowser.helpers';

import { AppContext } from '../../contexts/AppContext';
import { INTENT_TYPES } from '../../utilities/FormBase/FormBase.helpers';

import { useShortlistStore } from './ShortlistStore';

T.setTexts(require('../../components/texts.json'));

const ShortlistStoreLoader = () => {
	const appContext = useContext(AppContext);
	const loadShortlist = useShortlistStore((store) => store.loadShortlist);
	const clearError = useShortlistStore((store) => store.clearError);
	const { add } = useToast();

	const isUser = !!appContext?.state?.user;
	const isSupportedBrowser = !isUnsupportedBrowser();

	useEffect(() => {
		if (isUser && isSupportedBrowser) {
			loadShortlist();
		}
	}, [isUser, loadShortlist, isSupportedBrowser]);

	// https://github.com/pmndrs/zustand#transient-updates-for-often-occuring-state-changes
	// Using auto unsubscribe technique here :)
	useEffect(
		() =>
			useShortlistStore.subscribe(
				(store) => store.error,
				(error) => {
					if (error) {
						add(T.translate('utilities.genericError'), INTENT_TYPES.ERROR);
						clearError();
					}
				},
			),
		[add, clearError],
	);

	return null;
};

export default ShortlistStoreLoader;
