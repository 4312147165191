import { useContext } from 'react';

import { FeaturesContext } from 'Context/FeaturesContext/FeaturesContext';

const useFeatureToggle = (feature) => {
	const features = useContext(FeaturesContext);
	if (!feature) {
		return false;
	}
	return features[feature];
};

export default useFeatureToggle;

export { FEATURES } from 'Context/FeaturesContext/FeaturesContextConsts';
