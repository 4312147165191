import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import getConfig from 'next/config';

import { TIME_IN_MS } from 'Utilities/consts';
import { isSSR } from 'Utilities/helpers';

const { publicRuntimeConfig } = getConfig();

export const DEFAULT_REACT_QUERY_CACHE_TIME =
	publicRuntimeConfig?.REACT_QUERY_CACHE_TIME_IN_MS || TIME_IN_MS.THIRTEEN_HOURS;

export const queryClient = new QueryClient();

export const reactQuerySessionPersister = createSyncStoragePersister({
	storage: !isSSR() ? window?.sessionStorage : undefined,
});
