import { BRAZE_API, BRAZE_SDK_ENDPOINT } from 'Services/braze/braze.const';

import { sdkAuthenticationSubscriber } from './braze.helpers';
import { reportBrazeInitialisationFailureError } from './brazeErrorHandling';

export const onInitialisationSuccess = async (signedInUserId: string): Promise<void> => {
	const { subscribeToSdkAuthenticationFailures } = await import('Services/braze/brazeExports');

	subscribeToSdkAuthenticationFailures(
		async (error): Promise<void> => sdkAuthenticationSubscriber(error, signedInUserId),
	);
};

export const onInitialisationFailure = (signedInUserId: string): void => {
	const initialisationError = {
		brazeAPI: BRAZE_API,
		brazeSDKEndpoint: BRAZE_SDK_ENDPOINT,
		userId: signedInUserId,
	};

	reportBrazeInitialisationFailureError(initialisationError);
};
