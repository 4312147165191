/* eslint-disable no-return-await */
import type { AxiosResponse } from 'axios';
import type { NextPageContext } from 'next';

import isomorphicApi from 'Utilities/helpers/isomorphicApi';
import { API_ROUTES } from 'Utilities/routes';

import axios from '../axios';

import type { SetPasswordRequestBody, SignInRequestBody, SignUpRequestBody } from './user.types';

export const signIn = async ({ body }: { body: SignInRequestBody }): Promise<void> => {
	await axios.post(API_ROUTES.LOGIN, body);
};

export const signOut = async (): Promise<void> => {
	await axios.post(API_ROUTES.LOGOUT);
};

export const signUp = async (body: SignUpRequestBody, token?: string): Promise<AxiosResponse> => {
	const query = token ? `?token=${token}` : '';
	return await axios.post(`${API_ROUTES.SIGN_UP}${query}`, body);
};

export const requestResetPassword = async ({ email }: { email: string }): Promise<AxiosResponse> =>
	await axios.post(API_ROUTES.REQUEST_RESET_PASSWORD, { email });

export const activateDealer = async ({ email }: { email: string }): Promise<AxiosResponse> =>
	await axios.post(API_ROUTES.ACTIVATE_DEALER, { email });

export const setPassword = async ({
	email,
	password,
	passwordResetToken,
}: SetPasswordRequestBody): Promise<AxiosResponse> =>
	await axios.post(API_ROUTES.SET_PASSWORD, {
		email,
		password,
		passwordResetToken,
	});

export const auctionUnsubscribe = async (
	{ email, emailType, token }: { email: string; emailType: string | undefined; token: string },
	ctx: NextPageContext,
): Promise<void> =>
	isomorphicApi(ctx, {
		browser: async () => axios.post(API_ROUTES.AUCTION_UNSUBSCRIBE, { email, emailType, token }),
		server: async (api) => api.unsubscribeDealerContactFromAlerts(token, email, emailType),
	});
