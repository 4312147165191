import breakpoints from './BreakpointsContext.module.scss';

export const BREAKPOINT_KEYS: readonly string[] = [
	'breakpointContent',
	'breakpointDesktopAccount',
	'breakpointMobile',
	'breakpointTablet',
	'breakpointTabletMobile',
	'breakpointTinyMobile',
];

export const INITIAL_BREAKPOINTS_CONTEXT_VALUE = {
	breakpoints,
	maxWidth: {
		breakpointContent: null,
		breakpointDesktopAccount: null,
		breakpointMobile: null,
		breakpointTablet: null,
		breakpointTabletMobile: null,
		breakpointTinyMobile: null,
	},
	minWidth: {
		breakpointContent: null,
		breakpointDesktopAccount: null,
		breakpointMobile: null,
		breakpointTablet: null,
		breakpointTabletMobile: null,
		breakpointTinyMobile: null,
	},
};

export const BREAKPOINTS_CONTEXT_MOCK = {
	breakpoints,
	maxWidth: {
		breakpointContent: false,
		breakpointDesktopAccount: false,
		breakpointMobile: false,
		breakpointTablet: false,
		breakpointTabletMobile: false,
		breakpointTinyMobile: false,
	},
	minWidth: {
		breakpointContent: false,
		breakpointDesktopAccount: false,
		breakpointMobile: false,
		breakpointTablet: false,
		breakpointTabletMobile: false,
		breakpointTinyMobile: false,
	},
};

export const breakpointQueries = Object.entries(breakpoints)
	.filter(([key]) => /^breakpoint/.test(key))
	.sort((a, b) => parseInt(a[1]) - parseInt(b[1]));
