import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { initBrazeService } from 'Services/braze';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import useUser from 'Utilities/hooks/useUser';

type BrazeContextValues = {
	isBrazeSDKInitialised: boolean;
};

export const BrazeContext = createContext<BrazeContextValues>({ isBrazeSDKInitialised: false });

export const BrazeProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [isBrazeSDKInitialised, setIsBrazeSDKInitialised] = useState(false);
	const isBrazeSDKEnabled = useFeatureToggle(FEATURES.brazeSDK);
	const { id: userId } = useUser();

	useEffect(() => {
		(async () => {
			if (isBrazeSDKEnabled && userId) {
				await initBrazeService(userId);
				setIsBrazeSDKInitialised(true);
			}
		})();
	}, [userId, isBrazeSDKEnabled]);

	return <BrazeContext.Provider value={{ isBrazeSDKInitialised }}>{children}</BrazeContext.Provider>;
};

export const useBrazeProvider = (): BrazeContextValues => {
	const context = useContext(BrazeContext);
	if (!context) {
		throw new Error('No Braze context');
	}

	return context;
};
