import T from 'i18n-react';
import camelCase from 'lodash/camelCase';
import flatten from 'lodash/flatten';

import { isEmpty } from '@motorway/motorway-tools/typeHelpers';

import texts from 'Components/texts.json';
import { defaultFilters } from 'Components/VehicleList/VehicleList.helpers';
import { VEHICLE_LIST_FILTER_NAME } from 'Components/VehicleList/VehicleListFilters.consts';
import { isEmptyArray } from 'Utilities/helpers';
import { getMakeModelDictionary } from 'Utilities/makeModel/makeModel.helpers';
import { is2DArrayEmpty } from 'Utilities/utils';

import { FILTERS_DISPLAY_CONFIG } from './SavedFilters.conf';

T.setTexts(texts);

const serviceHistoriesCriteriaConfig = {
	full: 'Full service history',
	no: 'No service history',
	not_applicable: 'Service history not yet due',
	partial: 'Partial service history',
};

const getBooleanFieldsValues = (config, filters) => {
	const { fields, translations } = config;
	return fields?.reduce((acc, field, index) => {
		if (filters[field]) {
			acc.push(translations[index]);
		}
		return acc;
	}, []);
};

export const getValues = (config, filters) => {
	const { fields, isBoolean } = config;

	if (fields && !isBoolean) {
		return fields.map((field) => filters[field] || defaultFilters[field]);
	}
	if (fields && isBoolean) {
		return getBooleanFieldsValues(config, filters);
	}
	const { field } = config;

	return filters[field] || defaultFilters[field];
};

export const areValuesEmpty = (values) => {
	if (Array.isArray(values) && is2DArrayEmpty(values)) {
		return true;
	}
	return isEmpty(values) || !values;
};

export const getSingleFieldMultipleValue = (config, values) => {
	const { field, translationsPath } = config;

	return values.map((value) => {
		if (field === VEHICLE_LIST_FILTER_NAME.SERVICE_HISTORY) {
			value = serviceHistoriesCriteriaConfig[value];
		}

		const translation = T.translate(`${translationsPath}.${camelCase(value)}`);
		if (translation.indexOf('.') > -1) {
			return value;
		}
		return translation;
	});
};

const getFilterTextWithCount = ({ countWithSuffix, filter, hasMore }) =>
	`${filter}${hasMore ? ',' : ''} ${countWithSuffix}`?.trim();

export const getFirstValueAndRemainingCount = ({ suffix = '', values }) => {
	const valuesLength = values.length;
	if (!valuesLength) {
		return undefined;
	}
	const [firstValue] = values;
	const remainingFiltersCount = flatten(values).length - 1;
	const countWithSuffix = remainingFiltersCount ? `+${remainingFiltersCount} ${suffix}` : '';
	const filterValue = Array.isArray(firstValue) && firstValue.length ? firstValue[0] : firstValue;

	return getFilterTextWithCount({ countWithSuffix, filter: filterValue, hasMore: remainingFiltersCount > 0 });
};

export const getMakesValues = (values, makes) =>
	values.sort().map((value) => {
		const match = makes.find((make) => make.slug === value);
		return match ? match.name : value;
	});

export const getModelsValues = (selectedMakes, selectedModels) => {
	const makeAndModel = getMakeModelDictionary({
		modelAttribute: 'namePretty',
		selectedMakes,
		selectedModels,
	});
	const makeSlugs = selectedMakes.map((make) => make.slug);
	return flatten(
		makeSlugs.sort().map((make) => {
			const makeModels = makeAndModel.get(make);
			if (!Array.isArray(makeModels)) {
				return [];
			}
			return makeModels.sort();
		}),
	);
};

export const getFormatterValues = (config, filters) => {
	const { field, fields, formatter, max, min, suffix } = config;
	if (field) {
		return `${formatter(filters[field] || min, 'start')} ${suffix || ''}`;
	}
	const [from, to] = fields;
	return `${formatter(filters[from] || min, 'start')} - ${formatter(filters[to] || max, 'end')}`;
};

export const getTranslation = (config, value) => (value ? config.translation : undefined);

export const getFilterValueText = (config, filters) => {
	const { field, fields, formatter, isBoolean, label, makes, renderFn, showFirstAndCount, translationsPath } = config;

	const values = getValues(config, filters);
	if (areValuesEmpty(values)) {
		return undefined;
	}
	if (field && translationsPath) {
		return { label, value: getSingleFieldMultipleValue(config, values) };
	}
	if (showFirstAndCount) {
		return { label, value: getFirstValueAndRemainingCount(values) };
	}
	if (makes) {
		return { label, value: getMakesValues(values, makes) };
	}
	if (renderFn) {
		return { label, value: renderFn(values) };
	}
	if (isBoolean && fields) {
		return { label, value: values };
	}
	if (isBoolean) {
		return { label, value: getTranslation(config, values) };
	}
	if (formatter) {
		return { label, value: getFormatterValues(config, filters) };
	}
	return undefined;
};

export const getSavedFilterValueTexts = (filters, orderConfig = FILTERS_DISPLAY_CONFIG) => {
	const orderConfigValues = Array.from(orderConfig.values());
	return orderConfigValues.reduce((acc, configItem) => {
		const filterValue = getFilterValueText(configItem, filters);

		if (isEmptyArray(filterValue?.value)) {
			return acc;
		}
		if (filterValue) {
			acc.push(filterValue);
		}
		return acc;
	}, []);
};

// eslint-disable-next-line default-param-last
export const filterExists = (filters = [], id) => filters.some((filter) => filter?.id === id);
