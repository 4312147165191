import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { EXTERIOR_GRADING } = ANALYTICS_CATEGORIES;

const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

const { CLICK } = SNOWPLOW_EVENTS;

export const triggerGradingTierExpandedEvent = (gradingTier: string): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: EXTERIOR_GRADING,
				customData: { label: gradingTier },
				name: 'dlr_grading_tier_expanded',
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
		{
			eventPayload: {
				action: 'Grading tier expanded',
				category: EXTERIOR_GRADING,
				isLegacyEvent: false,
				label: gradingTier,
			},
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
