import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../analytics.const';

const {
	MARKETPLACE: { SHORTLIST },
} = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

interface TriggerVehicleShortlistedEventProps {
	id: number;
	isChecked: boolean;
	page: string;
}

export const triggerVehicleShortlistedEvent = ({ id, isChecked, page }: TriggerVehicleShortlistedEventProps): void => {
	const gaAction = isChecked ? 'shortlisted' : 'unshortlisted';
	const snowplowAction = isChecked ? 'selected' : 'unselected';

	const eventPayload = getSnowplowEventPayload({
		category: SHORTLIST,
		customData: { label: `${page} (${id})` },
		name: `dlr_shortlist_vehicle_shortlist_${snowplowAction}_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: `Vehicle ${gaAction}`,
			category: SHORTLIST,
			isLegacyEvent: false,
			label: `${page} (${id})`,
		},
		provider: GOOGLE_ANALYTICS,
	});
};
