import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';
import ROUTES from 'Utilities/routes';

const { BIDDING } = ANALYTICS_CATEGORIES;

const { CTA_CLICKED } = SNOWPLOW_EVENTS;

interface TriggerConfirmCancelBidOrOfferCTAClickedEventParams {
	errorMessage?: string;
	offerAmount?: string;
	success: boolean;
}

export const triggerSubmitOfferConfirmationCTAClickedEvent = ({
	errorMessage,
	offerAmount,
	success,
}: TriggerConfirmCancelBidOrOfferCTAClickedEventParams): void => {
	const eventPayload = getSnowplowEventPayload({
		category: BIDDING,
		customData: {
			button_label: `Send offer`,
			label: success ? `${offerAmount}` : errorMessage,
			success,
			url: ROUTES.VEHICLE_DETAILS.as(),
		},
		name: `dlr_submit_offer_button_clicked`,
		schemaName: CTA_CLICKED.SCHEMA,
		version: CTA_CLICKED.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
