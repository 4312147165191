import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

export const triggerPostSaleActionClickEvent = ({
	enquiryId,
	eventType,
}: {
	enquiryId: number;
	eventType: string;
}): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS,
		customData: {
			label: String(enquiryId),
		},
		name: `dlr_vehicle_details_${eventType}_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
