import { BIDS_ALLOWED_STATES } from 'Utilities/biddingState';
import { highestBidAvailableStates } from 'Utilities/vehicleState';

import { createNumberWithDisplayValue, NumberWithDisplayValue } from './createNumberWithDisplayValue';
import { GetVehicle } from './vehicle.schema';

const getHighestBidValueByAllowedStates = (allBids: GetVehicle['allBids']): number | null => {
	const allowedStatesBids = allBids.filter(({ state }) =>
		(BIDS_ALLOWED_STATES as ReadonlyArray<string>).includes(state),
	);

	if (allowedStatesBids.length === 0) {
		return null;
	}

	return Math.max(...allowedStatesBids.map((bid) => bid.value));
};

interface GetHighestBidParams {
	allBids: GetVehicle['allBids'];
	stateSlug: string;
}

export const getHighestValidBidOrOffer = ({
	allBids,
	stateSlug,
}: GetHighestBidParams): NumberWithDisplayValue | null => {
	const isAllowedToShowHighestBid = highestBidAvailableStates.includes(stateSlug);

	if (!isAllowedToShowHighestBid) {
		return null;
	}

	const highestBidValue = getHighestBidValueByAllowedStates(allBids);

	return highestBidValue ? createNumberWithDisplayValue(highestBidValue) : null;
};
