import { useState } from 'react';
import debounce from 'lodash/debounce';

import { VehicleGrades } from '@motorway/motorway-storybook-cra';

import { INPUT_DEBOUNCE_TIME, VEHICLE_FUEL_TYPES } from 'Utilities/consts';
import { formatText } from 'Utilities/formatters';

import { SELLER_TYPES, VEHICLE_CLASSES } from '../VehicleListFilters.consts';

const { DIESEL, ELECTRIC, GAS_BI_FUEL, HYBRID, PETROL } = VEHICLE_FUEL_TYPES;

const { BRONZE, GOLD, SILVER } = VehicleGrades;

export const formatFilterRange =
	(unit, includeZero = false, fromStart = true, max = null) =>
	(value, direction) => {
		const formattedUnit = value === 1 ? unit.slice(0, -1) : unit;
		const formattedText = fromStart
			? unit + formatText(value)
			: `${formatText(value)}${max && max === value ? '+ ' : ' '}${formattedUnit}`;
		if (direction === 'start' && max) {
			return formatText(value);
		}
		if (includeZero) {
			return value ? formattedText : value;
		}
		return formattedText;
	};

export const ALL = 'all';

export const ExteriorGradeOptions = [ALL, GOLD, SILVER, BRONZE];

export const FuelOptions = [ALL, PETROL, DIESEL, HYBRID, ELECTRIC, GAS_BI_FUEL];

export const TransmissionOptions = [ALL, 'Manual', 'Automatic', 'Semi-automatic'];

export const SellerTypesOptions = Object.values(SELLER_TYPES);

export const mapSelectOptionToFilters = (options) => options.map((option) => option.value);

export const reachValue = (value, max) => (value === max ? null : value);

export const getFieldNameParts = (fieldName) => {
	const [, nameOfField, fieldType] = fieldName.match(/(.*)([A-Z].*)/);
	return { fieldType, nameOfField };
};

export const MinMaxFilters = {
	age: {
		INCREMENT: 1,
		MAX: 20,
		MIN: 0,
	},
	distance: {
		INCREMENT: 10,
		MAX: 500,
		MIN: 0,
	},
	mileage: {
		INCREMENT: 1000,
		MAX: 100000,
		MIN: 0,
	},
	previousKeepersCount: {
		INCREMENT: 1,
		MAX: 5,
		MIN: 0,
	},
	price: {
		INCREMENT: 500,
		MAX: 100000,
		MIN: 0,
	},
};

export const mapValuesToSelect = (values, formattedMakes) =>
	values?.map((make) => ({ text: formattedMakes.find((m) => make === m.value)?.text, value: make }));

export const handleMultiSearch = (value, handler) => {
	handler({ make: mapSelectOptionToFilters(value) });
};

export const formatDistanceRange = (value) => {
	const { MAX } = MinMaxFilters.distance;
	return `${MAX === parseInt(value) ? `${MAX}+` : `Up to ${formatText(value)}`} miles`;
};

export const isSliderClean = ({ end, max, min, start }) => start === min && end === max;

export const triggerEvent =
	({ previousValue, setPreviousValue, trigger }) =>
	({ searchValue }) => {
		if (searchValue !== previousValue) {
			trigger({ searchValue });
			setPreviousValue(searchValue);
		}
	};

export const onMultiSelectChange = ({ callback, setValue }) =>
	debounce(({ searchValue, value }, id) => {
		const valuesMapped = mapSelectOptionToFilters(value);
		setValue({ id, value: valuesMapped });
		callback?.({ id, searchValue, value });
	}, INPUT_DEBOUNCE_TIME);

export const useMultiSelectInputValues = () => {
	const [prevMakeSelectInputValue, setPrevMakeSelectInputValue] = useState('');
	const [prevModelSelectInputValue, setPrevModelSelectInputValue] = useState('');

	return {
		prevMakeSelectInputValue,
		prevModelSelectInputValue,
		setPrevMakeSelectInputValue,
		setPrevModelSelectInputValue,
	};
};

export const getVehicleClassKey = (filterOption) =>
	Object.keys(VEHICLE_CLASSES).find((key) => VEHICLE_CLASSES[key] === filterOption);
