import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { BIDDING } = ANALYTICS_CATEGORIES;

const { CLICK } = SNOWPLOW_EVENTS;

export const triggerCancelOfferOrBidModalClosedEvent = (purchaseType: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: BIDDING,
		name: `dlr_cancel_${purchaseType}_modal_closed`,
		schemaName: CLICK.SCHEMA,
		version: CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
