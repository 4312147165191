import { BIDS_ALLOWED_STATES } from './biddingState';
import { isEmpty, isNumber } from './helpers';
import { convertVehicleStatusSlugToVehicleStatusName, getVehicleCategoryFromVehicleSlug } from './vehicleState';

// To extract with DEP-56 and remove allowed states check
export const resolveUserBids = (bids = [], { id } = {}) => {
	if (!id) {
		return bids || [];
	}

	return bids?.filter((bid) => bid.dealerContact.id === id && BIDS_ALLOWED_STATES.includes(bid.state)) || [];
};

// To extract with DEP-56 and remove allowed states check
// This function relies on bids being usersBids only
export const resolveHasOtherContactActiveBid = (bids = [], { id } = {}) => {
	if (!id) {
		return false;
	}

	return bids?.some((bid) => bid.dealerContact.id !== id && BIDS_ALLOWED_STATES.includes(bid.state)) || false;
};

/**
 * Realtime bid data mapper for Vehicle Details and Vehicle List.
 * Merges data from server and web sockets
 */
const realTimeBiddingVehicleDataMapper = (props) => {
	const { realTimeBidData, user, vehicle } = props;

	if (!realTimeBidData) {
		// we have nothing to merge with, so return DP response
		return vehicle;
	}

	const {
		bids,
		displayPrice,
		highestBid,
		minimumBid,
		priceHigh,
		sellerWillAcceptPrice,
		soldDate,
		soldFor,
		state,
		...vehicleRest
	} = vehicle;

	const stateSlug = realTimeBidData?.stateSlug || state.slug;
	const isRTB = !isEmpty(realTimeBidData);

	return {
		...vehicleRest,
		bids: {
			activeBidCount: isNumber(realTimeBidData?.bidCount) ? realTimeBidData?.bidCount : bids?.activeBidCount,
			usersBids: resolveUserBids(realTimeBidData?.ownBids, user) || bids?.usersBids,
		},
		displayPrice: realTimeBidData?.acceptedBid?.value || realTimeBidData?.closingBid?.value || displayPrice,
		hasOtherContactActiveBid: resolveHasOtherContactActiveBid(realTimeBidData?.ownBids || bids?.usersBids, user),
		/** LEGACY PROXY BIDDING START */
		highestBid: isRTB ? realTimeBidData?.highestBid?.value : highestBid,
		/** LEGACY PROXY BIDDING END */

		minimumBid: isRTB && !isEmpty(realTimeBidData?.minimumBid) ? realTimeBidData?.minimumBid : minimumBid,

		/** LEGACY RESERVE PRICE START */
		priceHigh: isRTB ? realTimeBidData?.priceHigh : priceHigh,
		/** LEGACY RESERVE PRICE END */

		sellerWillAcceptPrice: isRTB ? realTimeBidData?.sellerWillAcceptPrice : sellerWillAcceptPrice,

		soldDate: realTimeBidData?.soldDate || soldDate,

		soldFor: realTimeBidData?.acceptedBid?.value || realTimeBidData?.closingBid?.value || soldFor,
		state: {
			category: getVehicleCategoryFromVehicleSlug(stateSlug),
			name: convertVehicleStatusSlugToVehicleStatusName(stateSlug),
			slug: stateSlug,
		},
	};
};

export default realTimeBiddingVehicleDataMapper;
