/* eslint-disable react/jsx-filename-extension,class-methods-use-this */
import React, { Component, PropsWithChildren, useContext, useState } from 'react';
import Cookies from 'js-cookie';
import { isEqual } from 'lodash';
import getConfig from 'next/config';
import Router from 'next/router';
import { io, Socket } from 'socket.io-client';

import NotFound from 'Components/NotFound/NotFound';
import { AppContext } from 'Context/AppContext';
import {
	BIDDING_PAGES,
	createBiddingContext,
	SOCKET_EVENTS,
	VALID_BIDDING_STATES,
} from 'Context/BiddingContext.helpers';
import { logger } from 'Services/logger/logger';
import { useRefetchSaleTimes } from 'Stores/SaleTimesStore/SaleTimesStore';
import { Vehicle } from 'Types/vehicle';
import { DEFAULT_VEHICLE_LIST_ROUTE } from 'Utilities/consts';
import { isEmptyObject, isObject } from 'Utilities/helpers';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import realTimeBiddingVehicleDataMapper from 'Utilities/mappers';
import { getStateInfo, SOLD_CATEGORY, soldStates } from 'Utilities/vehicleState';

import { useSocketToastHandler } from './BiddingContext.hooks';
import {
	BiddingContext as UseBiddingContext,
	BiddingRealTimeProps,
	BiddingRealTimeState,
	ClientToServerEvents,
	DealerBiddingState,
	SaleEndArrayItem,
	SaleEventData,
	SaleStartArrayItem,
	ServerToClientEvents,
	SocketConfig,
	SubscriptionProps,
} from './BiddingContext.types';

const { publicRuntimeConfig } = getConfig();

const {
	DEBUG_SOCKET_IO,
	GATEWAY_API: GATEWAY_URL,
	MW_BIDDING_API,
	PROVIDERS_VERSIONS_HEADER: PROVIDERS_VERSIONS,
} = publicRuntimeConfig;

const BiddingContext = createBiddingContext();

const {
	AUTHENTICATE,
	AUTHENTICATED,
	CONNECT,
	CONNECT_ERROR,
	DISCONNECT,
	ERROR,
	OPEN_CONNECTION_FOR_ENQUIRY_IDS,
	RECEIVE_BIDDING_STATE,
	RECEIVE_INITIAL_BIDDING_STATES,
	RECONNECT,
	RECONNECT_ATTEMPT,
	SALE_ENDED,
	SALE_STARTED,
	UNAUTHORIZED,
} = SOCKET_EVENTS;

const socketConfig: SocketConfig = {
	path: `${MW_BIDDING_API ? '' : '/bidding'}/socket.io`,
	query: PROVIDERS_VERSIONS ? { 'x-mway-providers-versions': PROVIDERS_VERSIONS } : {},
	reconnection: true,
	transports: ['websocket'],
};

class BiddingRealTime extends Component<BiddingRealTimeProps, BiddingRealTimeState> {
	private hasReceivedInitialData: boolean;

	private socket: Socket<ServerToClientEvents, ClientToServerEvents> | undefined;

	constructor(props: BiddingRealTimeProps) {
		super(props);

		this.hasReceivedInitialData = false;

		this.state = {
			isAuthenticated: false,
			subscribedVehicleIds: props?.vehicleId ? [props?.vehicleId] : [],
			token: Cookies.get('access-token'),
			vehiclesBidData: {},
		};
	}

	componentDidMount() {
		this.socketConnect();
	}

	componentDidUpdate(_prevProps: BiddingRealTimeProps, prevState: BiddingRealTimeState) {
		const { subscribedVehicleIds } = this.state;

		if (!isEqual(subscribedVehicleIds, prevState.subscribedVehicleIds)) {
			this.socket?.emit(OPEN_CONNECTION_FOR_ENQUIRY_IDS, subscribedVehicleIds);
		}
	}

	componentWillUnmount() {
		this.socket?.emit(OPEN_CONNECTION_FOR_ENQUIRY_IDS, []);
		this.socket?.close();
	}

	onSocketUnauthorized = (msg: string) => {
		this.logSocketEvent('Unauthorized', msg);
		this.setState({ isAuthenticated: false });
	};

	onSocketDisconnect = (reason: Socket.DisconnectReason) => {
		const { isAuthenticated, subscribedVehicleIds } = this.state;

		this.hasReceivedInitialData = false;

		const reconnectSocket = () => {
			this.socketConnect();
			this.socket?.emit(OPEN_CONNECTION_FOR_ENQUIRY_IDS, subscribedVehicleIds);
		};

		this.logSocketEvent(`Disconnected!, ${reason}`);
		this.socket?.close();

		if (!isAuthenticated) {
			return;
		}

		reconnectSocket();
	};

	onSocketReconnect = (attemptNumber: number) => {
		const { subscribedVehicleIds } = this.state;

		this.logSocketEvent('Reconnected:', attemptNumber);

		this.props.onReconnected(attemptNumber);
		this.socket?.emit(OPEN_CONNECTION_FOR_ENQUIRY_IDS, subscribedVehicleIds);
	};

	onSocketReconnectAttempt = (attemptNumber: number) => {
		this.props?.onReconnectAttempt(attemptNumber);
		this.logSocketEvent('Reconnection attempt:', attemptNumber);
	};

	onSaleEndedEvent = (data: (SaleStartArrayItem | SaleEndArrayItem)[] | SaleEventData) => {
		this.onSaleEventBiddingState(data);
		this.props.onSaleEnded();
	};

	onSocketAuthenticated = () => {
		const { subscribedVehicleIds } = this.state;

		this.logSocketEvent('Authenticated');
		this.setState({ isAuthenticated: true });

		this.socket
			?.emit(OPEN_CONNECTION_FOR_ENQUIRY_IDS, subscribedVehicleIds)
			?.on(RECEIVE_BIDDING_STATE, this.onReceiveBiddingState)
			?.on(SALE_STARTED, this.onSaleEventBiddingState)
			?.on(SALE_ENDED, this.onSaleEndedEvent);

		if (!this.hasReceivedInitialData) {
			this.socket?.on(RECEIVE_INITIAL_BIDDING_STATES, this.onReceiveInitialBiddingStates);
		}
	};

	logWarning = (error: Error) => {
		logger.warn({
			error,
			message: error?.message,
			scope: 'BiddingContext logWarning',
		});
	};

	initialiseSocket = () => {
		const { token } = this.state;

		if (!token) {
			this.onSocketUnauthorized('No token: User not signed in.');
			return;
		}

		this.socket
			?.on(CONNECT, () => this.socket?.emit(AUTHENTICATE, token))
			?.on(AUTHENTICATED, this.onSocketAuthenticated)
			?.on(UNAUTHORIZED, this.onSocketUnauthorized)
			?.on(DISCONNECT, this.onSocketDisconnect)
			?.on(CONNECT_ERROR, this.logWarning);

		this.socket?.io
			?.on(RECONNECT_ATTEMPT, this.onSocketReconnectAttempt)
			?.on(RECONNECT, this.onSocketReconnect)
			?.on(ERROR, this.logWarning);
	};

	logSocketEvent = (...args: [string, string | number] | [string]) => {
		if (DEBUG_SOCKET_IO !== 'true') {
			return;
		}

		console.log(...args); // eslint-disable-line no-console
	};

	setBidData = (data: DealerBiddingState) => {
		if (!data || isEmptyObject(data)) {
			return null;
		}

		const id = data.enquiryId;

		this.setState((prevState) => ({
			vehiclesBidData: {
				...prevState.vehiclesBidData,
				[id]: {
					...(prevState.vehiclesBidData[id] || {}),
					...data,
				},
			},
		}));

		if (!this.isValidBiddingState(data)) {
			this.setState((prevState) => ({
				subscribedVehicleIds: prevState.subscribedVehicleIds.filter(
					(subscribedVehicleId) => subscribedVehicleId !== id,
				),
			}));
		}

		return null;
	};

	isValidBiddingState = (data: DealerBiddingState) => {
		if (Object.values(SOLD_CATEGORY).includes(data.stateSlug)) {
			return !data.acceptedBid?.value;
		}

		return VALID_BIDDING_STATES.includes(data.stateSlug);
	};

	onReceiveBiddingState = (data: DealerBiddingState) => this.setBidData(data);

	formatSaleEventDataToBiddingStateData = (data: SaleStartArrayItem | SaleEndArrayItem | SaleEventData) => {
		const { newState = '', ...commonData } = data;

		return {
			...commonData,
			soldDate: soldStates.includes(newState) ? new Date() : null,
			stateSlug: newState,
		};
	};

	onSaleEventBiddingState = (data: (SaleStartArrayItem | SaleEndArrayItem)[] | SaleEventData) =>
		this.setState((prevState) => {
			if (isObject(data) && !isEmptyObject(data) && !Array.isArray(data)) {
				return this.setBidData(this.formatSaleEventDataToBiddingStateData(data));
			}

			if (!Array.isArray(data)) {
				return null;
			}

			return {
				vehiclesBidData: {
					...prevState.vehiclesBidData,
					...data?.reduce((acc, d) => {
						const id = d.enquiryId;
						return {
							...acc,
							[id]: {
								...prevState.vehiclesBidData[id],
								...this.formatSaleEventDataToBiddingStateData(d),
							},
						};
					}, {}),
				},
			};
		});

	onReceiveInitialBiddingStates = (data: DealerBiddingState[]) => {
		if (!data) {
			return null;
		}

		this.hasReceivedInitialData = true;

		return this.setState((prevState) => ({
			vehiclesBidData: {
				...prevState.vehiclesBidData,
				...data.reduce((acc, d) => ({ ...acc, [d.enquiryId]: d }), {}),
			},
		}));
	};

	subscribeVehicleId = ({ id, stateSlug }: SubscriptionProps, { append } = { append: true }) => {
		const { subscribedVehicleIds } = this.state;
		const isInvalidBiddingState = !VALID_BIDDING_STATES.includes(stateSlug);
		const isVehicleAlreadySubscribed = append && subscribedVehicleIds.find((subscribedId) => subscribedId === id);
		const shouldVehicleNotBeSubscribed = !id || isInvalidBiddingState || isVehicleAlreadySubscribed;

		if (shouldVehicleNotBeSubscribed) {
			return null;
		}

		return this.setState((prevState) => ({
			subscribedVehicleIds: [...(append ? prevState.subscribedVehicleIds : []), id],
		}));
	};

	clearSubscribedVehicleIds = () => {
		if (!BIDDING_PAGES.includes(Router.pathname)) {
			this.setState({ subscribedVehicleIds: [] });
		}
	};

	socketConnect() {
		const URL = MW_BIDDING_API || GATEWAY_URL;
		this.setState({ isAuthenticated: false });
		this.socket = io(URL, { ...socketConfig });
		this.initialiseSocket();
	}

	render() {
		const { children } = this.props;
		const { subscribedVehicleIds, vehiclesBidData } = this.state;

		return (
			<BiddingContext.Provider
				value={{
					clearVehicleIds: this.clearSubscribedVehicleIds,
					subscribedVehicleIds,
					subscribeVehicleId: this.subscribeVehicleId,
					vehiclesBidData,
				}}
			>
				{children}
			</BiddingContext.Provider>
		);
	}
}

const withRealTimeBiddingData = <P extends { vehicle?: Vehicle }>(
	ChildComponent: React.ComponentType<P>,
	options = { returnNotFoundOnUnavailableVehicle: false },
): React.FC<P> => {
	const RealTimeBiddingDataWrapper: React.FC<P> = (props) => {
		const appContext = useContext(AppContext);

		return (
			<BiddingContext.Consumer>
				{({ clearVehicleIds, subscribeVehicleId, vehiclesBidData }) => {
					const { vehicle } = props;
					const { returnNotFoundOnUnavailableVehicle } = options;
					const realTimeBidData = vehicle?.id ? vehiclesBidData[vehicle.id] : undefined;
					const mergedVehicleBidData = realTimeBiddingVehicleDataMapper({
						realTimeBidData,
						user: appContext?.state?.user || {},
						vehicle,
					});

					const { isVehicleAllowed, message } = getStateInfo(mergedVehicleBidData);
					if (!isVehicleAllowed && returnNotFoundOnUnavailableVehicle) {
						return (
							<NotFound
								message={message}
								onClickButton={() => Router.push(DEFAULT_VEHICLE_LIST_ROUTE.href, DEFAULT_VEHICLE_LIST_ROUTE.as)}
							/>
						);
					}

					return (
						<ChildComponent
							{...props}
							clearSubscribedVehicleIdsFromRealtimeBidding={clearVehicleIds}
							subscribeVehicleToRealTimeBidding={subscribeVehicleId}
							vehicle={{ ...mergedVehicleBidData }}
						/>
					);
				}}
			</BiddingContext.Consumer>
		);
	};

	return RealTimeBiddingDataWrapper;
};

const BiddingRealTimeProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const refetchSaleTimes = useRefetchSaleTimes();
	const { onReconnectAttempt, onReconnected } = useSocketToastHandler();
	const [isBiddingEnabled, setIsBiddingEnabled] = useState(true);
	const isMirageEnabled = useFeatureToggle(FEATURES.mirageServer);
	const appContext = useContext(AppContext);
	const { user } = appContext?.state || {};

	if (isMirageEnabled && isBiddingEnabled) {
		// Disable bidding until full page reload, to prevent websockets initialising on mirage vehicle pages
		setIsBiddingEnabled(false);
	}

	if (!user || isEmptyObject(user) || !isBiddingEnabled) {
		return <>{children}</>;
	}

	const onSaleEnded = () => {
		refetchSaleTimes();
	};

	return (
		<BiddingRealTime onReconnectAttempt={onReconnectAttempt} onReconnected={onReconnected} onSaleEnded={onSaleEnded}>
			{children}
		</BiddingRealTime>
	);
};

const useBidding = (): UseBiddingContext => {
	const context = useContext(BiddingContext);
	if (!context) {
		throw new Error('useBidding must be used inside BiddingRealTimeProvider');
	}
	return context;
};

export { BiddingRealTimeProvider, BiddingContext, withRealTimeBiddingData, useBidding };
