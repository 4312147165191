import { useEffect, useRef } from 'react';
import { enums } from '@optimizely/react-sdk';

import { onDecision, onTrack } from 'Services/optimizely/optimizely';
import { useOptimizely } from 'Services/optimizely/useOptimizely';

const OptimizelyNotifications = (): null => {
	const optimizely = useOptimizely();
	const isOptimizelyInstanceValid = optimizely?.getOptimizelyConfig() !== null;

	const decisionListenerId = useRef<number | undefined>();
	const trackListenerId = useRef<number | undefined>();

	if (optimizely && isOptimizelyInstanceValid && optimizely.isReady()) {
		decisionListenerId.current = optimizely.notificationCenter.addNotificationListener(
			enums.NOTIFICATION_TYPES.DECISION,
			onDecision,
		);
		trackListenerId.current = optimizely.notificationCenter.addNotificationListener(
			enums.NOTIFICATION_TYPES.TRACK,
			onTrack,
		);
	}

	useEffect(() => {
		return () => {
			if (decisionListenerId.current !== undefined) {
				optimizely?.notificationCenter.removeNotificationListener(decisionListenerId.current);
			}
			if (trackListenerId.current !== undefined) {
				optimizely?.notificationCenter.removeNotificationListener(trackListenerId.current);
			}
		};
	}, [optimizely]);

	return null;
};

export default OptimizelyNotifications;
