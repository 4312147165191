import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

interface TriggerSectionScrollEventProps {
	from: string;
	to: string;
}

export const triggerSectionScrollEvent = ({ from, to }: TriggerSectionScrollEventProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS,
		customData: {
			label: `from: ${from}, to: ${to}`,
		},
		name: `dlr_page_scroll`,
		schemaName: SNOWPLOW_EVENTS.SCROLL.SCHEMA,
		version: SNOWPLOW_EVENTS.SCROLL.VERSION,
	});
	logAnalyticsEvent({ eventPayload });
};
