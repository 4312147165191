import { event } from 'Services/gtm';

export const GA_VEHICLE_DETAILS_CATEGORIES = {
	BIDDING: 'Bidding',
	GALLERY: 'Gallery',
	VEHICLE_DETAILS: 'Vehicle details',
};

// TODO: Refactor Ga Events
export const gaVehicleDetails = ({
	action = '',
	category = GA_VEHICLE_DETAILS_CATEGORIES.VEHICLE_DETAILS,
	label = '',
}) =>
	event({
		action,
		event_action: action,
		event_category: category,
		event_label: label,
	});

export const VEHICLE_DETAILS_SIDEBAR_ID = 'vehicle-details-sidebar';
