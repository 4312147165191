import qs from 'qs';

import { ALL_MODELS_BY_MAKE, ALL_MODELS_BY_MAKE_SERVER } from './makeModel.helpers';

const formatMakeModelQueryParam = ({ make, model = ALL_MODELS_BY_MAKE, prefix = '&' }) =>
	`${prefix}${qs.stringify({ make, model })}`;

const getQueryPrefix = ({ index, query = '' }) => {
	if (index === 0 && query === '') {
		return query;
	}
	return '&';
};

export const formatModelWildcardForServer = (query) => {
	const allModelsFrontEnd = `model=${ALL_MODELS_BY_MAKE}`;
	const allModelsServer = `model=${ALL_MODELS_BY_MAKE_SERVER}`;
	return query.replace(new RegExp(allModelsFrontEnd, 'g'), allModelsServer);
};

export const getQueryFromMakeModels = ({ makeModels = {}, queryPrefix = '&' }) => {
	const query = Object.entries(makeModels).reduce((acc, [make, models], index) => {
		if (!Array.isArray(models)) {
			acc += formatMakeModelQueryParam({ make, model: models, prefix: getQueryPrefix({ index, query: acc }) });
			return acc;
		}

		if (models.length > 0) {
			models.forEach((model) => {
				acc += formatMakeModelQueryParam({ make, model, prefix: getQueryPrefix({ index, query: acc }) });
			});
			return acc;
		}

		acc += formatMakeModelQueryParam({ make, prefix: getQueryPrefix({ index, query: acc }) });
		return acc;
	}, '');

	if (query === '') {
		return query;
	}

	return `${queryPrefix}${query}`;
};

export const getMakeModelQuery = (makeModels) => {
	if (!makeModels) {
		return '';
	}

	return formatModelWildcardForServer(getQueryFromMakeModels({ makeModels }));
};
