import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import getConfig from 'next/config';

import { Drill } from 'Components/Utilities';
import { logger } from 'Services/logger/logger';
import { isTrue } from 'Utilities/helpers';
import proPropTypes from 'Utilities/proPropTypes';

import { withAppContext } from '../AppContext';

import {
	getFeatureTogglesFromQueryString,
	getValidFeatureToggles,
	setFeatureToggleCookies,
} from './FeaturesContext.helpers';
import { FEATURES_ARRAY, FEATURES_ENABLED_COOKIE_NAME, PERMANENT_FEATURES } from './FeaturesContextConsts';
import { getFeatureTogglesFromEnv } from './featureToggle.helpers';

const { publicRuntimeConfig } = getConfig();
const { FEATURE_TOGGLES } = publicRuntimeConfig;

const FeaturesContext = React.createContext({});
FeaturesContext.displayName = 'FeaturesContext';
class FeaturesContextProvider extends React.Component {
	constructor(props) {
		super(props);
		const { appContext, featureFlags: featuresFromBE = {} } = this.props;
		const dealerId = appContext?.state?.user?.dealerId;

		const featuresFromEnv = FEATURE_TOGGLES ? getFeatureTogglesFromEnv({ dealerId, envString: FEATURE_TOGGLES }) : {};
		const featuresFromUserObject = this.getFeaturesFromUserObject();
		const validFeatureToggles = getValidFeatureToggles({
			availableFeatures: FEATURES_ARRAY,
			features: {
				// Order here matters
				...featuresFromBE, // First, check if feature is enabled in the BE
				...featuresFromEnv, // Next, check if feature is enabled in the environmental variables
				...featuresFromUserObject, // Next, check if feature is enabled in user object
			},
		});

		const initialState = {
			...props.cookies,
			...validFeatureToggles,
			...(props.cookies?.[PERMANENT_FEATURES.testEnv] ? props.cookies : {}),
		};

		this.state = initialState;

		setFeatureToggleCookies(initialState);
	}

	componentDidMount() {
		const { [FEATURES_ENABLED_COOKIE_NAME]: FEATURES_ENABLED } = this.state;
		const validFeatureToggles = getFeatureTogglesFromQueryString();
		Object.entries(validFeatureToggles).forEach(([feature, value]) => this.toggleFeature(feature, isTrue(value)));

		if (FEATURES_ENABLED) {
			this.loadFeaturesEditor();
		}
		window.cookieStore?.addEventListener('change', this.cookieStoreListener);
	}

	componentWillUnmount() {
		window.cookieStore?.removeEventListener('change', this.cookieStoreListener);
	}

	getFeaturesFromUserObject = () => {
		const { appContext } = this.props;
		const features = appContext?.state?.user?.features || {};

		return features;
	};

	toggleFeature = (feature, value) => {
		this.setState((prevState) => {
			const nextFeatureState = value ?? !prevState[feature];

			if (nextFeatureState) {
				Cookies.set(feature, nextFeatureState);
			} else {
				Cookies.remove(feature);
			}

			return {
				[feature]: nextFeatureState,
			};
		});
	};

	cookieStoreListener = (event) => {
		const vector = ['changed', 'deleted'].find((k) => event[k].length);
		const cookieEvent = event[vector].find(({ name }) => name === FEATURES_ENABLED_COOKIE_NAME);

		if (cookieEvent) {
			const enable = vector === 'changed' && cookieEvent.value === 'true';

			this.setState(
				{
					[FEATURES_ENABLED_COOKIE_NAME]: enable,
					FeaturesEditor: null,
				},
				() => {
					if (enable) {
						this.loadFeaturesEditor();
					}
				},
			);
		}
	};

	loadFeaturesEditor = async () => {
		const FeaturesEditor = await import('Components/FeaturesEditor/FeaturesEditor')
			.then((module) => module.default)
			.catch((error) =>
				logger.error({
					error,
					message: error?.message || 'There was an issue with loading FeaturesEditor',
					scope: 'featuresEditor',
				}),
			);

		this.setState({ FeaturesEditor });
	};

	render() {
		const { FeaturesEditor, ...state } = this.state;
		const { children } = this.props;

		const FeaturesEditorElement = FeaturesEditor || Drill;

		return (
			<FeaturesContext.Provider value={state}>
				{children}
				<FeaturesEditorElement features={state} toggleFeature={this.toggleFeature} />
			</FeaturesContext.Provider>
		);
	}
}

FeaturesContextProvider.defaultProps = {
	appContext: {},
};

FeaturesContextProvider.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	appContext: PropTypes.object,

	children: PropTypes.node.isRequired,

	cookies: proPropTypes.cookies.isRequired,
	featureFlags: PropTypes.objectOf(PropTypes.bool),
};

const FeaturesContextProviderWithHocs = withAppContext(FeaturesContextProvider);

const withFeaturesContext = (Component) => (props) => {
	const features = useContext(FeaturesContext);

	return <Component {...props} features={features} />;
};

export { FeaturesContextProviderWithHocs as FeaturesContextProvider, FeaturesContext, withFeaturesContext };
