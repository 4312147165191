// Issue importing any file with aliases when using from scripts/scheduled/syncDealerActivityToMwDealerSales.ts
// once the script is working can use imports as normal

const errorMessages = {
	abortRouteChange: 'Abort route change',
	// This is harmless, but it's being caused by the library `react-pan-pinch-zoom` and gets logged a lot.
	ignoredTouchmoveEventCancel: 'Ignored attempt to cancel a touchmove event with cancelable=false',
	// Generic websocket error messages that fire often and are not useful for debugging.
	websocketErrorGeneric: '[BiddingContext logWarning] websocket error',
	websocketErrorTimeout: '[BiddingContext logWarning] timeout',
};

const ignoredErrors = [
	{ exactMatch: false, ignoredMessage: errorMessages.ignoredTouchmoveEventCancel },
	{ exactMatch: true, ignoredMessage: errorMessages.websocketErrorGeneric },
	{ exactMatch: true, ignoredMessage: errorMessages.websocketErrorTimeout },
	{ exactMatch: true, ignoredMessage: errorMessages.abortRouteChange },
];

export const getIsIgnoredLog = (logMessage?: string): boolean =>
	ignoredErrors.some(({ exactMatch = false, ignoredMessage }) => {
		return exactMatch ? logMessage === ignoredMessage : logMessage?.includes(ignoredMessage);
	});
