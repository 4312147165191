export enum AccountCategory {
	BIDS = 'bids',
	PURCHASES = 'purchases',
}

export enum AccountDelivered {
	HIDE = 'true',
	SHOW = 'false',
}

export enum AccountSlugs {
	ACTIVE = 'active',
	CANCELLED = 'cancelled',
	COMPLETE = 'complete',
	DIDNT_WIN = 'didnt-win',
	UNDER_OFFER = 'under-offer',
}

export enum AccountDateRange {
	ALL = 'ALL',
	LAST_24_HOURS = 'LAST_24_HOURS',
	LAST_30_DAYS = 'LAST_30_DAYS',
	LAST_48_HOURS = 'LAST_48_HOURS',
	LAST_7_DAYS = 'LAST_7_DAYS',
	TODAY = 'TODAY',
	YESTERDAY = 'YESTERDAY',
}

export enum SortBy {
	DATE_BID = 'dateBid',
	DATE_WON = 'dateWon',
	MAX_BID_OFFER = 'maxBidOffer',
	PRICE = 'price',
	VEHICLES = 'vehicles',
}

export enum SortByBEOptions {
	BIDS_CREATED_AT = 'createdAt',
	BID_VALUE = 'bidValue',
	ENQUIRY_SOLD_DATE = 'enquirySoldDate',
	MAKE_MODEL = 'makeModel',
}

export interface DateRangeFilterValue {
	from?: string;
	fromSoldDate?: string;
	to?: string;
	toSoldDate?: string;
}
