import type { ApiErrorResponseForJsend } from './apiClient.types';

/**
 * ### Response factories
 */

export const response400 = (message: string): ApiErrorResponseForJsend => ({
	message,
	status: 'error',
	statusCode: 400,
});

export const response401 = (message: string): ApiErrorResponseForJsend => ({
	message,
	status: 'error',
	statusCode: 401,
});

export const response403 = (message: string): ApiErrorResponseForJsend => ({
	message,
	status: 'error',
	statusCode: 403,
});

export const response422 = (message: string): ApiErrorResponseForJsend => ({
	message,
	status: 'error',
	statusCode: 422,
});

export const response501 = (message: string): ApiErrorResponseForJsend => ({
	message,
	status: 'error',
	statusCode: 501,
});

/**
 * ### Common responses
 */

export const BAD_REQUEST_RESPONSE = response400('Bad request');

export const UNAUTHORISED_RESPONSE = response401('Unauthorized');

export const NOT_IMPLEMENTED_RESPONSE = response501('Not implemented');

export const NO_DEALER_ID_PROVIDED_RESPONSE = response400('No dealerId provided');
