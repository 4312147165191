import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

const { HOVER } = SNOWPLOW_EVENTS;

export const triggerFeaturesRetailValueTooltipHoverEvent = (): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: VEHICLE_DETAILS,
				name: `dlr_features_retail_value_tooltip_hover`,
				schemaName: HOVER.SCHEMA,
				version: HOVER.VERSION,
			}),
			provider: SNOWPLOW,
		},
		{
			eventPayload: {
				action: `Features tooltip hover`,
				category: VEHICLE_DETAILS,
				isLegacyEvent: false,
				label: '',
			},
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
