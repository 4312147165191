import getConfig from 'next/config';

import useTradePermission from 'Utilities/hooks/useTradePermission';
import ROUTES from 'Utilities/routes';
import { isDev } from 'Utilities/serverUtils';

import { abortRouteChange } from '../RouterMiddleware.helpers';

const { publicRuntimeConfig } = getConfig();
const { TRADE_WEBAPP_LOCAL_URL } = publicRuntimeConfig;

export const useRedirectTradeUsers = (): ((url: string) => void) => {
	const hasTradePermission = useTradePermission();

	return (url: string) => {
		const isTradeUrl = ROUTES.TRADE.href === url || url.startsWith(`${ROUTES.TRADE.href}/`);

		if (!isTradeUrl) {
			return;
		}

		if (!hasTradePermission) {
			abortRouteChange({ routerPushArgs: [ROUTES.VEHICLE_LIST.href, ROUTES.VEHICLE_LIST.href, { shallow: true }] });
			return;
		}

		if (isDev && TRADE_WEBAPP_LOCAL_URL) {
			// On development/local, Trade Webapp is on a different domain, so we abort the route change
			// and redirect the user to the Trade Webapp localhost URL.
			abortRouteChange({ routerPushArgs: [TRADE_WEBAPP_LOCAL_URL, TRADE_WEBAPP_LOCAL_URL, { shallow: false }] });
			return;
		}

		// We use a load balancer to redirect users to Trade Webapp if they access any `/trade` routes.
		// This only works on server side load, so we manually force a server side reload of the page here.
		window.location.reload();
	};
};
