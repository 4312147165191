import { isFeatureEnabled } from 'Context/FeaturesContext/FeaturesContext.helpers';
import { FEATURES } from 'Context/FeaturesContext/FeaturesContextConsts';
import { logger } from 'Services/logger/logger';

import {
	ANALYTICS_EVENT_LOGGER_STYLES,
	ANALYTICS_PROVIDER_EVENT_TRIGGER,
	ANALYTICS_PROVIDERS,
	SNOWPLOW_SCHEMA_BASE_URL,
} from './analytics.const';
import type {
	AnalyticsEventLoggerProps,
	AnalyticsProviders,
	GetSnowplowBackendEventPayloadProps,
	GetSnowplowEventPayloadProps,
	LogAnalyticsEventProps,
	SnowplowEvent,
	SnowplowEventPayload,
} from './analytics.types';

const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

function analyticsEventLogger<Provider extends AnalyticsProviders>({
	eventPayload,
	provider,
}: AnalyticsEventLoggerProps<Provider>) {
	if (provider === SNOWPLOW) {
		const {
			event: { data, schema },
		} = eventPayload as SnowplowEventPayload;
		// eslint-disable-next-line no-console
		console.log(`%c[${provider}]`, ANALYTICS_EVENT_LOGGER_STYLES[provider], { ...data, schema });
		return;
	}

	// eslint-disable-next-line no-console
	console.log(`%c[${provider}]`, ANALYTICS_EVENT_LOGGER_STYLES[provider], eventPayload);
}

export function logAnalyticsEvent<Provider extends AnalyticsProviders>({
	eventPayload,
	provider = SNOWPLOW as Provider,
}: LogAnalyticsEventProps<Provider>): void {
	const isAnalyticsEventLoggerEnabled = isFeatureEnabled(FEATURES.analyticsEventLogger);

	if (isAnalyticsEventLoggerEnabled) {
		analyticsEventLogger({ eventPayload, provider });
	}

	switch (provider) {
		case SNOWPLOW:
			ANALYTICS_PROVIDER_EVENT_TRIGGER[SNOWPLOW](eventPayload);
			break;
		case GOOGLE_ANALYTICS:
			ANALYTICS_PROVIDER_EVENT_TRIGGER[GOOGLE_ANALYTICS](eventPayload);
			break;
		default:
			logger.error({ message: `Unknown analytics provider: ${provider}`, scope: 'analytics' });
	}
}

export const logAnalyticsEvents = <Provider extends AnalyticsProviders>(
	events: LogAnalyticsEventProps<Provider>[],
): void => {
	events.map((event) => logAnalyticsEvent(event));
};

export function getSnowplowEventPayload<CustomData extends UnknownShapeObject>({
	context,
	customData,
	schemaName,
	version,
	...data
}: GetSnowplowBackendEventPayloadProps<CustomData> | GetSnowplowEventPayloadProps<CustomData>): SnowplowEventPayload {
	const eventPayload: SnowplowEventPayload = {
		context,
		event: {
			data: {
				...data,
				...customData,
			},
			schema: `${SNOWPLOW_SCHEMA_BASE_URL}/${schemaName}/jsonschema/${version}`,
		},
	};

	return eventPayload;
}

export const getSchemaURL = (snowplowEvent: SnowplowEvent): string =>
	`${SNOWPLOW_SCHEMA_BASE_URL}/${snowplowEvent.SCHEMA}/jsonschema/${snowplowEvent.VERSION}`;
