import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

export const triggerRecommendedVehicleCarouselSeeAllButtonClickedEvent = (url: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS,
		customData: { url: `${url}` },
		name: 'dlr_recommended_vehicle_carousel_see_all_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});
	logAnalyticsEvent({ eventPayload });
};
