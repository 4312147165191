import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../analytics.const';

interface TriggerAddSavedSearchDailyStockToggleProps {
	savedSearchName: string;
	sendEmailAlert: boolean;
}

const {
	MARKETPLACE: { ADD_SAVED_SEARCH },
} = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerAddSavedSearchButtonClickEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ADD_SAVED_SEARCH,
		name: 'dlr_saved_search_save_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Add saved search button clicked',
			category: ADD_SAVED_SEARCH,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerSaveFilterButtonClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ADD_SAVED_SEARCH,
		name: 'dlr_saved_search_save_confirmed',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Save filter button clicked',
			category: ADD_SAVED_SEARCH,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerAddSavedSearchDailyStockToggleEvent = ({
	savedSearchName,
	sendEmailAlert,
}: TriggerAddSavedSearchDailyStockToggleProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ADD_SAVED_SEARCH,
		customData: { label: savedSearchName },
		name: `dlr_add_saved_search_daily_stock_${sendEmailAlert ? 'on' : 'off'}`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	const gaEventPayload = {
		action: `Daily stock alert toggled ${sendEmailAlert ? 'on' : 'off'}`,
		category: ADD_SAVED_SEARCH,
		isLegacyEvent: false,
		label: savedSearchName,
	};

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({ eventPayload: gaEventPayload, provider: GOOGLE_ANALYTICS });
};
