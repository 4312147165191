import Axios from 'axios';
import getConfig from 'next/config';

import { apiClientAxiosOnFulfilled } from './motorway/apiClient.helpers';

const { publicRuntimeConfig } = getConfig();

const { DEALER_WEBAPP_URL } = publicRuntimeConfig;

const axiosInstance = Axios.create({ baseURL: DEALER_WEBAPP_URL });

axiosInstance.interceptors.response.use(apiClientAxiosOnFulfilled);

export default axiosInstance;
