import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import consts from '@motorway/motorway-sale-rules/lib/consts';
import fees from '@motorway/motorway-sale-rules/lib/fees';

import { Fee, Fees } from 'Types/fees';
import { ZustandDevtools } from 'Types/zustand/devtools';
import { MAXIMUM_PURCHASE_VALUE_LENGTH } from 'Utilities/consts';
import { isDev } from 'Utilities/helpers';

import { FeesStore } from './FeesStore.types';

const { PROXY_BID_INCREMENT } = consts;

const feesStore = (set: (arg0: Fees) => void) => ({
	FEES: [],
	MAXIMUM_PURCHASE_VALUE_LENGTH: undefined,
	PROXY_BID_INCREMENT,
	setFees: async (mockFees?: Fee[]) => {
		// We set the values after checking the user is authenticated
		set({
			FEES: mockFees ?? fees.getFees(),
			MAXIMUM_PURCHASE_VALUE_LENGTH,
		});
	},
});

const feesStoreWithDevtools = (devtools as ZustandDevtools)(feesStore, {
	enabled: Boolean(isDev),
	name: 'FeesStore',
});

const useFeesStore = create<FeesStore>(isDev ? feesStoreWithDevtools : feesStore);

export default useFeesStore;
