import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';

import { handleAxiosError } from 'Utilities/error/handleAxiosError';
import { isEmpty, isFunction, isNil, objectDiff } from 'Utilities/helpers';
import { castToType } from 'Utilities/utils';

const doOnChange = ({ nextState, onChange, shouldCallOnChange }) => {
	if (isFunction(onChange) && shouldCallOnChange) {
		onChange({ ...nextState.filters });
	}
};

export const valuesFromConfig = (config) =>
	Object.entries(config).reduce((acc, cur) => {
		const [id, idConfig] = cur;
		acc[id] = idConfig.value;
		return acc;
	}, {});

export const getValueCount = ({ config, filters }) => {
	const difference = objectDiff({ base: valuesFromConfig(config), object: filters }) || {};
	const countedFilters = Object.keys(difference).filter((filterType) => !config[filterType]?.excludeFromCount);
	return countedFilters.length;
};

export const unsetValue =
	(set) =>
	({ id }, shouldCallOnChange = true) => {
		set(
			(state) => {
				const currentFilters = { ...state.filters };
				const config = { ...state.config[id] };
				const { onChange } = state;
				currentFilters[id] = config.value;
				const nextState = {
					filters: { ...currentFilters },
					valueCount: getValueCount({
						config: state.config,
						filters: currentFilters,
					}),
				};

				if (isFunction(config.onChange) && shouldCallOnChange) {
					config.onChange({ id, value: config.value });
				}

				doOnChange({ nextState, onChange, shouldCallOnChange });
				return nextState;
			},
			false,
			{ id, shouldCallOnChange, type: 'unsetValue' },
		);
	};

export const setValue =
	(set, get) =>
	({ id, value }, shouldCallOnChange = true) => {
		set(
			(state) => {
				const config = { ...state.config[id] };
				const { modifier, onChange: valueOnchange, type } = config;
				const current = state.filters[id];
				const { onChange } = state;
				// exit if not in config
				if (isEmpty(config)) {
					return state;
				}

				// unset current if not new value
				if (isEmpty(value) || isNil(value) || Number.isNaN(value) || value === 0) {
					return get().unsetValue({ id }, shouldCallOnChange);
				}

				const nextValue = isFunction(modifier) ? modifier(value) : value;
				const nextFilters = { ...state.filters, [id]: castToType({ type, value: clone(nextValue) }) };
				const valueCount = getValueCount({ config: state.config, filters: nextFilters });
				const nextState = { filters: nextFilters, valueCount };
				const isFilterValueUnchanged = isEqual(value, current);
				const isValueCountUnchanged = isEqual(state.valueCount, valueCount);

				if (isFilterValueUnchanged && isValueCountUnchanged) {
					return state;
				}

				if (isFunction(valueOnchange) && shouldCallOnChange) {
					valueOnchange({ id, value: nextValue });
				}

				doOnChange({ nextState, onChange, shouldCallOnChange });
				return nextState;
			},
			false,
			{
				id,
				shouldCallOnChange,
				type: 'setValue',
				value,
			},
		);
	};

// bulk setting, ie initial page load, no value onChange executed
export const setValues =
	(set, get) =>
	({ filters }) => {
		set(
			(state) => {
				const { onChange } = state;
				for (const [key, value] of Object.entries(filters)) {
					get().setValue({ id: key, value }, false);
				}

				const nextState = { filters: get().filters };
				doOnChange({ nextState, onChange, shouldCallOnChange: true });
			},
			false,
			{ filters, type: 'setValues' },
		);
	};

export const resetValues = (set, get) => () => {
	set(
		(state) => {
			const { config, onChange } = state;
			Object.keys(config).forEach((key) => {
				get().unsetValue({ id: key }, false);
			});

			const nextState = { filters: get().filters };
			set({ hasUserSetSort: false });
			doOnChange({ nextState, onChange, shouldCallOnChange: true });
		},
		false,
		{ type: 'resetValues' },
	);
};

export const setConfig =
	(set) =>
	({ config }) => {
		set(() => ({ config, filters: valuesFromConfig(config), valueCount: 0 }), false, { config, type: 'setConfig' });
	};

export const setOnChange =
	(set) =>
	({ callback }) => {
		set(
			() => ({
				onChange: callback,
			}),
			false,
			{ callback, type: 'setOnChange' },
		);
	};

export const setError = (set) => (err) => {
	set({ error: err }, false, { err, type: 'setError' });

	if (err !== null) {
		handleAxiosError({ axiosError: err, context: { level: 'warning' } });
	}

	return true;
};

export const clearError = (set) => () => {
	set({ error: false }, false, { set, type: 'clearError' });
};
