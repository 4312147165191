import type { GetVehicle } from '../helpers/vehicle.schema';
import { ServiceRecordValues } from '../schemas/serviceHistory.schema';

export const serviceHistorySectionTransformer = (vehicle: GetVehicle) => ({
	independentServiceStampsCount: vehicle.independentServiceStampsCount,
	make: vehicle.spec.make,
	model: vehicle.spec.model,
	officialServiceStampsCount: vehicle.officialServiceStampsCount,
	serviceHistoryRecords: vehicle.serviceHistoryRecords,
	serviceRecord: vehicle.serviceRecord ?? ServiceRecordValues.NOT_PROVIDED,
	vrm: vehicle.vrm,
});
