import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

const { CLICK } = SNOWPLOW_EVENTS;

export const triggerUserZoomInVehicleLocationEvent = (eventId: number): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: VEHICLE_DETAILS,
				customData: { label: `${eventId}` },
				name: `dlr_user_zoom_in_vehicle_location_map`,
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
		{
			eventPayload: {
				action: `User zoomed in`,
				category: 'Vehicle location map',
				isLegacyEvent: false,
				label: eventId,
			},
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
