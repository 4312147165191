import { useEffect } from 'react';

import { useBrazeProvider } from 'Context/BrazeContext/BrazeContext';
import { brazeAuth } from 'Services/api/braze';
import { logger } from 'Services/logger/logger';

import type { BrazeSDKAuthError } from './braze.types';
import { reportBrazeSdkAuthError } from './brazeErrorHandling';

export const sdkAuthenticationSubscriber = async (error: BrazeSDKAuthError, signedInUserId: string): Promise<void> => {
	const { setSdkAuthenticationSignature } = await import('Services/braze/brazeExports');
	const { userId } = error;

	reportBrazeSdkAuthError(error);

	if (userId === signedInUserId) {
		const { signedBrazeSignature } = await brazeAuth();
		setSdkAuthenticationSignature(signedBrazeSignature);
	}
};

type UseBrazePayload<T> =
	| { eventFn?: (args: T) => Promise<void>; eventFnArgs: T }
	| {
			eventFn?: () => Promise<void>;
	  };

export const useBraze = <T,>({ eventFn, ...args }: UseBrazePayload<T>): void => {
	const { isBrazeSDKInitialised } = useBrazeProvider();

	// @ts-expect-error - TS does not understand the type decoupling when eventFnArgs exists or not
	const { eventFnArgs } = args || {};

	useEffect(() => {
		if (isBrazeSDKInitialised && eventFn) {
			eventFn(eventFnArgs).catch(({ message }) => logger.warn({ message, scope: eventFn.name }));
		}
	}, [isBrazeSDKInitialised, eventFn, eventFnArgs]);
};
