import { snakeCase } from 'lodash';

import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

import { GALLERY_EVENT_ACTIONS } from './galleryEvents.consts';

const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

const { CLICK } = SNOWPLOW_EVENTS;

interface TriggerGalleryEventParams {
	action: (typeof GALLERY_EVENT_ACTIONS)[keyof typeof GALLERY_EVENT_ACTIONS];
	eventsCategory?: string;
	galleryType: string;
	kind?: string;
}

const {
	MARKETPLACE: { GALLERY },
} = ANALYTICS_CATEGORIES;

export const triggerGalleryEvent = ({
	action,
	eventsCategory = GALLERY,
	galleryType,
	kind = '',
}: TriggerGalleryEventParams): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: eventsCategory,
				...(kind ? { customData: { label: kind } } : {}),
				name: `dlr_${snakeCase(galleryType)}_gallery_${snakeCase(action)}`,
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
		{
			eventPayload: {
				action: `${galleryType} gallery ${action}`,
				category: eventsCategory,
				isLegacyEvent: false,
				label: kind,
			},
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
