import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../analytics.const';

interface TriggerDeleteSavedSearchEventsProps {
	id: string;
}

const {
	MARKETPLACE: { DELETE_SAVED_SEARCH },
} = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerSavedSearchDeleteButtonClickEvent = ({ id }: TriggerDeleteSavedSearchEventsProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: DELETE_SAVED_SEARCH,
		name: 'dlr_saved_search_delete_confirmed',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Delete button clicked',
			category: DELETE_SAVED_SEARCH,
			isLegacyEvent: false,
			label: id,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerDeleteSavedSearchLinkClickedEvent = ({ id }: TriggerDeleteSavedSearchEventsProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: DELETE_SAVED_SEARCH,
		name: 'dlr_saved_search_delete_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Delete saved search link clicked',
			category: DELETE_SAVED_SEARCH,
			isLegacyEvent: false,
			label: id,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerKeepButtonClickedEvent = ({ id }: TriggerDeleteSavedSearchEventsProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: DELETE_SAVED_SEARCH,
		name: 'dlr_saved_search_delete_keep_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Keep button clicked',
			category: DELETE_SAVED_SEARCH,
			isLegacyEvent: false,
			label: id,
		},
		provider: GOOGLE_ANALYTICS,
	});
};
