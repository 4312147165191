import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { RECOMMENDED_VEHICLES } = ANALYTICS_CATEGORIES;

export const triggerRecommendedVehiclesModalClosedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: RECOMMENDED_VEHICLES,
		name: 'dlr_recommended_vehicles_modal_closed',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});
	logAnalyticsEvent({ eventPayload });
};
