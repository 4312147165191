import { useEffect } from 'react';

type TestContentWithErrorThrowingProps = {
	mockErrorMessage: string;
	shouldThrowError: boolean;
};

const TestContentWithErrorThrowing: React.FC<TestContentWithErrorThrowingProps> = ({
	mockErrorMessage,
	shouldThrowError,
}: TestContentWithErrorThrowingProps) => {
	useEffect(() => {
		if (shouldThrowError) throw new Error(mockErrorMessage);
	}, [shouldThrowError, mockErrorMessage]);

	return <div data-testid="mock-app-content">Mocking App Content</div>;
};

export default TestContentWithErrorThrowing;
