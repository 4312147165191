import pino from 'pino-http';

import 'dotenv-defaults/config';

// Cannot use aliases in this file as logger is used in syncDealerActivityToMwDealerSales.ts which can't handle aliases
// This is only temporary until we remove syncDealerActivityToMwDealerSales.ts
import { getIsIgnoredLog } from '../../utilities/error/getIsIgnoredLog';

import { consoleLogger } from './consoleLogger';
import { datadogClientLogger } from './datadogClientLogger';
import { CLIENT_LOGGING_PROVIDER_NAMES, LoggerProps, LogProps, PinoInstance } from './logger.types';
import { PINO_CONFIG } from './pinoConfig';
import { pinoLogger } from './pinoLogger';

export const pinoInstance = pino(PINO_CONFIG) as PinoInstance;

export const CLIENT_LOGGING_PROVIDERS = {
	console: consoleLogger,
	datadog: datadogClientLogger,
} as const;

const log = ({
	context,
	error,
	level = 'info',
	message,
	providers = [CLIENT_LOGGING_PROVIDER_NAMES.datadog],
	scope = '',
}: LogProps): void => {
	const scopedMessage = `[${scope}] ${message}`;
	const isServer = typeof window === 'undefined';
	const contextWithError = { ...context, ...(error ? { error } : {}) };

	if (getIsIgnoredLog(scopedMessage)) {
		return;
	}

	if (isServer) {
		pinoLogger[level](scopedMessage, contextWithError);
		return;
	}

	providers.forEach((provider) => {
		CLIENT_LOGGING_PROVIDERS[provider][level](scopedMessage, contextWithError);
	});
};

export const logger = {
	critical: (args: LoggerProps): void => log({ ...args, level: 'critical' }),
	debug: (args: LoggerProps): void => log({ ...args, level: 'debug' }),
	error: (args: LoggerProps): void => log({ ...args, level: 'error' }),
	fatal: (args: LoggerProps): void => log({ ...args, level: 'fatal' }),
	info: (args: LoggerProps): void => log({ ...args, level: 'info' }),
	trace: (args: LoggerProps): void => log({ ...args, level: 'trace' }),
	warn: (args: LoggerProps): void => log({ ...args, level: 'warn' }),
};
