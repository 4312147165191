import isString from 'lodash/isString';

import { Make } from 'Types/make';

import {
	FilterModelsByMakesParams,
	GetMakeByIdParams,
	GetMakeModelDictionaryParams,
	MakeAndModel,
} from './makeModel.types';

// front end is using `all` to display the all models in the url bar.
// back end requires `*` instead of `all` to query all models
export const ALL_MODELS_BY_MAKE = 'all';
export const ALL_MODELS_BY_MAKE_SERVER = '*';

const getNumericMakeId = (makeId: string | number): number => (isString(makeId) ? parseInt(makeId) : makeId);

export const getMakesIds = (makes: Make[] = []): number[] => makes?.map((make) => getNumericMakeId(make?.id));

export const filterModelsByMakes = ({ makeIds = [], models = [] }: FilterModelsByMakesParams): number[] =>
	models?.filter((model) => makeIds?.includes(model?.makeId)).map((model) => model?.id);

export const getDefaultMakeModel = (selectedMakes: Make[] = []): [string, typeof ALL_MODELS_BY_MAKE][] =>
	selectedMakes?.map((make) => [make.slug, ALL_MODELS_BY_MAKE]);

export const getMakeById = ({ makeId, selectedMakes = [] }: GetMakeByIdParams): Make | undefined =>
	selectedMakes?.find((make) => getNumericMakeId(make?.id) === getNumericMakeId(makeId));

export const getMakeModelDictionary = ({
	modelAttribute = 'name',
	selectedMakes = [],
	selectedModels = [],
}: GetMakeModelDictionaryParams): MakeAndModel => {
	const defaultMakeModel = getDefaultMakeModel(selectedMakes);
	return selectedModels?.reduce(
		(acc, model) => {
			const make = getMakeById({ makeId: model?.makeId, selectedMakes });

			if (!make) {
				return acc;
			}

			const makeModel = acc.get(make?.slug);
			const modelProperty = model?.[modelAttribute];

			if (!makeModel || makeModel === ALL_MODELS_BY_MAKE) {
				acc.set(make?.slug, [modelProperty]);
			} else {
				acc.set(make?.slug, [...makeModel, modelProperty]);
			}
			return acc;
		},
		new Map(defaultMakeModel) as MakeAndModel,
	);
};
