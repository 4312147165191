import { z } from 'zod';

export const ImportSchema = z.object({
	isImported: z.boolean(),
	isNonEu: z.boolean(),
});

export const MotSchema = z.object({
	expiry: z.string().optional(),
	isInvalid: z.boolean(),
});

export const AncestrySchema = z.object({
	dateFrom: z.string().catch(''),
	dateTo: z.string().catch(''),
	recordType: z.string().catch(''),
	source: z.string().catch(''),
});

export const LimitationsSchema = z.object({
	ancestry: z.array(AncestrySchema).optional(),
	fetchedAt: z.string().optional(),
	financeAgreements: z.array(z.any()).optional(),
	hasBeenStolen: z.boolean().optional(),
	hasBeenWrittenOffByDamage: z.boolean().optional(),
	hasBeenWrittenOffByTheft: z.boolean().optional(),
	hasSecurityWatch: z.boolean().optional(),
	import: ImportSchema.optional(),
	isManufactureDateBeforeFirstRegisteredDate: z.boolean().optional(),
	isMileageClocked: z.boolean().optional(),
	isPlateTransferred: z.boolean().optional(),
	mot: MotSchema.optional(),
	salvageAuctions: z.array(z.any()).optional(),
	specDiscrepancies: z.array(z.string()).optional(),
});
