/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { RefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import {
	dispatchUseDerivedActionStateDisable,
	dispatchUseDerivedActionStateEnable,
} from './emitter/useDerivedActionStateEmitter.events';
import { subscribeUseDerivedActionStateSetEvent } from './emitter/useDerivedActionStateEmitter.listeners';
import type { useDerivedActionStateProps } from './emitter/useDerivedActionStateEmitter.types';

export const useDerivedActionState = (opts?: {
	/**
	 * If true, the `dispatchUseDerivedActionStateDisable` and `dispatchUseDerivedActionStateEnable` will be called
	 * and this will notify eg. the global loading bar.
	 */
	shouldAnnounceStateChangeGlobally?: boolean;
}) => {
	const { shouldAnnounceStateChangeGlobally = true } = opts || {};

	const [state, setStateBase] = useState<useDerivedActionStateProps>({
		isDisabled: false,
		isLoading: false,
	});

	const isMountedRef = useRef<boolean>(false);
	const targetClick = useRef<HTMLElement | null>(null);

	const setState = useCallback<typeof setStateBase>((p) => {
		if (isMountedRef.current) {
			setStateBase(p);
		}
	}, []);

	const setAsTargetClick = useCallback((element: RefObject<HTMLElement>) => {
		targetClick.current = element.current;
	}, []);

	const disable = useCallback(
		(nextState?: Pick<Partial<useDerivedActionStateProps>, 'isLoading'>) => {
			const defaultNextState = { isDisabled: true, isLoading: false };
			const finalNextState = { ...defaultNextState, ...nextState };

			setState(finalNextState);

			if (shouldAnnounceStateChangeGlobally) {
				dispatchUseDerivedActionStateDisable(finalNextState);
			}
		},
		[shouldAnnounceStateChangeGlobally, setState],
	);

	const enable = useCallback(() => {
		const nextState = { isDisabled: false, isLoading: false };

		setState(nextState);

		if (shouldAnnounceStateChangeGlobally) {
			dispatchUseDerivedActionStateEnable(nextState);
		}
	}, [shouldAnnounceStateChangeGlobally, setState]);

	const withDisable = useCallback(
		(cb: () => unknown | Promise<unknown>) => async () => {
			disable();

			const result = await cb();

			enable();

			return result;
		},
		[disable, enable],
	);

	const withLoading = useCallback(
		(cb: () => unknown | Promise<unknown>) => async (e: React.MouseEvent<HTMLElement>) => {
			targetClick.current = e.currentTarget;

			disable({ isLoading: true });

			const result = await cb();

			enable();

			return result;
		},
		[disable, enable],
	);

	const withEnable = useCallback(
		(cb: () => unknown | Promise<unknown>) => async () => {
			enable();

			return cb();
		},
		[enable],
	);

	useEffect(() => {
		return subscribeUseDerivedActionStateSetEvent((nextState) => {
			if (!nextState) {
				return;
			}

			setState(nextState);
		});
	}, [setState]);

	const isTargetClick = (element: HTMLElement) => {
		return element === targetClick.current;
	};

	const isLoadingTarget = (element: RefObject<HTMLElement>) => {
		if (!element.current) {
			return false;
		}

		return state.isLoading && isTargetClick(element.current);
	};

	useEffect(() => {
		isMountedRef.current = true;

		return () => {
			isMountedRef.current = false;
		};
	}, []);

	return [state, { disable, enable, isLoadingTarget, setAsTargetClick, withDisable, withEnable, withLoading }] as const;
};

export const useDerivedActionRouteObserver = (): void => {
	const router = useRouter();

	const [, { disable, enable }] = useDerivedActionState();

	useEffect(() => {
		const onRouteChangeError = () => enable();
		const onRouteChangeStart = () => disable();
		const onRouteChangeComplete = () => enable();

		router.events.on('routeChangeError', onRouteChangeError);
		router.events.on('routeChangeStart', onRouteChangeStart);
		router.events.on('routeChangeComplete', onRouteChangeComplete);

		return () => {
			router.events.off('routeChangeError', onRouteChangeError);
			router.events.off('routeChangeStart', onRouteChangeStart);
			router.events.off('routeChangeComplete', onRouteChangeComplete);
		};
	}, [disable, enable, router.events]);
};
