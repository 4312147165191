import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import {
	breakpointQueries,
	INITIAL_BREAKPOINTS_CONTEXT_VALUE,
} from 'Context/BreakpointsContext/BreakpointsContext.const';
import { isBreakpointKey } from 'Context/BreakpointsContext/BreakpointsContext.helpers';
import type {
	Breakpoints,
	BreakpointsContextValue,
	UseBreakpointType,
} from 'Context/BreakpointsContext/BreakpointsContext.types';

const BreakpointContext = createContext<UseBreakpointType>(INITIAL_BREAKPOINTS_CONTEXT_VALUE);

const BreakpointContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [queryMatch, setQueryMatch] = useState<BreakpointsContextValue>([]);

	useEffect(() => {
		const resize = (key: keyof Breakpoints, e: MediaQueryListEvent) => {
			setQueryMatch((matches) => matches.map(([k, m]) => (k === key ? [k, e.matches] : [k, m])));
		};

		const match: Array<[keyof Breakpoints, boolean]> = [];
		let store: Record<
			keyof Partial<Breakpoints>,
			{
				listener: (this: MediaQueryList, ev: MediaQueryListEvent) => unknown;
				query: MediaQueryList;
			}
		> = {};

		breakpointQueries.forEach(([key, maxWidth]) => {
			if (!isBreakpointKey(key)) {
				return;
			}

			store = {
				...store,
				[key]: {
					listener: resize.bind(this, key),
					query: window.matchMedia(`(min-width: ${maxWidth})`),
				},
			};
			store[key].query.addListener(store[key].listener);

			match.push([key, store[key].query.matches]);
		});

		setQueryMatch(match);

		return () =>
			Object.values(store).forEach((val) => {
				val.query.removeListener(val.listener);
			});
	}, []);

	const processValue: UseBreakpointType = useMemo(() => {
		const value: UseBreakpointType = {
			breakpoints: INITIAL_BREAKPOINTS_CONTEXT_VALUE.breakpoints,
			maxWidth: {},
			minWidth: {},
		};

		queryMatch.forEach(([key, flag]) => {
			value.minWidth[key] = flag;
			value.maxWidth[key] = !flag;
		});

		return {
			...INITIAL_BREAKPOINTS_CONTEXT_VALUE,
			maxWidth: {
				...INITIAL_BREAKPOINTS_CONTEXT_VALUE.maxWidth,
				...value.maxWidth,
			},
			minWidth: {
				...INITIAL_BREAKPOINTS_CONTEXT_VALUE.minWidth,
				...value.minWidth,
			},
		};
	}, [queryMatch]);

	return <BreakpointContext.Provider value={processValue}>{children}</BreakpointContext.Provider>;
};

const useBreakpoint = (): UseBreakpointType => {
	const context = useContext(BreakpointContext);

	if (!context) {
		throw new Error('useBreakpoint must be used within BreakpointProvider');
	}

	return context;
};

export { useBreakpoint, BreakpointContextProvider, BreakpointContext };
