import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from '../analytics.const';

import { createSnowplowEventFunctions } from './helpers/event.helpers';

const { INSIGHTS } = ANALYTICS_CATEGORIES;

const { triggerInsightsClickEvent, triggerInsightsLoadEvent } = createSnowplowEventFunctions(INSIGHTS);

export const triggerInsightsResultsEmptyStateLoadedEvent = (): void => {
	triggerInsightsLoadEvent('sold_insights_results_empty_state_loaded');
};

export const triggerInsightsResultsEmptyStateCTAClickedEvent = (): void => {
	triggerInsightsClickEvent('sold_insights_results_empty_state_cta_clicked');
};

export const triggerInsightsResultsErrorStateCTAClickedEvent = (): void => {
	triggerInsightsClickEvent('sold_insights_results_error_state_cta_clicked');
};

export const triggerInsightsMakeDropdownSelectEvent = (location: 'results' | 'landing'): void => {
	triggerInsightsClickEvent(`sold_insights_${location}_make_dropdown_option_clicked`);
};

export const triggerInsightsModelDropdownSelectEvent = (location: 'results' | 'landing'): void => {
	const eventPayload = getSnowplowEventPayload({
		category: INSIGHTS,
		name: `sold_insights_${location}_model_dropdown_option_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerInsightsSearchEvent = ({
	location,
	vehicleName,
}: {
	location: 'results' | 'landing';
	vehicleName: string;
}): void => {
	const eventPayload = getSnowplowEventPayload({
		category: INSIGHTS,
		customData: {
			label: vehicleName,
		},
		name: `sold_insights_${location}_search_button_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerInsightsRecentSoldPaginationEvent = ({
	direction,
	page,
}: {
	direction: 'back' | 'forward';
	page: number;
}): void => {
	const eventPayload = getSnowplowEventPayload({
		category: INSIGHTS,
		customData: {
			label: `${page}`,
		},
		name: `sold_insights_vehicles_data_${direction}_button_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerInsightsPopularVehicleEvent = ({ vehicleName }: { vehicleName: string }): void => {
	const eventPayload = getSnowplowEventPayload({
		category: INSIGHTS,
		customData: {
			label: vehicleName,
		},
		name: 'sold_insights_popular_vehicle_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerInsightsHomeButtonEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: INSIGHTS,
		name: 'sold_insights_home_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerInsightsSortingEvent = (sortingFieldAndDirection: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: INSIGHTS,
		customData: {
			label: sortingFieldAndDirection,
		},
		name: 'sold_insights_sorting_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerInsightsRecentSoldVehicleClickedEvent = ({ enquiryId }: { enquiryId: number }): void => {
	const eventPayload = getSnowplowEventPayload({
		category: INSIGHTS,
		customData: {
			label: `${enquiryId}`,
		},
		name: 'sold_insights_vehicle_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
