import Router from 'next/router';

import { AUTHENTICATED_PAGES_SLUGS, ERRORS, PAGE_SLUGS } from '../../utilities/consts'; // No alias due to BE

export const abortRouteChange = ({ routerPushArgs }) => {
	Router.events.emit('routeChangeError');

	if (routerPushArgs) {
		Router.push(...routerPushArgs);
	}

	throw ERRORS.ABORT_ROUTE_CHANGE;
};

export const isAuthPage = (url = '') => !!Object.values(AUTHENTICATED_PAGES_SLUGS).find((slug) => url.includes(slug));

export const isSignInUrl = (url = '') => url.includes(PAGE_SLUGS.SIGN_IN);

export const getRedirectQuery = ({ redirectUrl }) =>
	isSignInUrl(redirectUrl) ? '' : `?unauthorised=true&redirect=${encodeURIComponent(redirectUrl)}`;

export const getRedirectUrl = (url = '') => `/${PAGE_SLUGS.SIGN_IN}${getRedirectQuery({ redirectUrl: url })}`;
