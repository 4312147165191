import { logger } from 'Services/logger/logger';

import { initBrazeSDK } from './initBrazeSDK';
import { startBrazeSession } from './startBrazeSession';

const unhandledErrorMessage = 'initBrazeService unhandled error';

export const initBrazeService = async (userId: string): Promise<void> => {
	try {
		const isBrazeSDKInitialised = await initBrazeSDK(userId);

		if (isBrazeSDKInitialised) {
			await startBrazeSession(userId);
		}
	} catch (e) {
		const error = e instanceof Error ? e : new Error(unhandledErrorMessage);
		logger.warn({ error, message: error.message, scope: 'initBrazeService' });
	}
};
