import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { handleUnsupportedBrowser } from 'Components/UnsupportedBrowser/UnsupportedBrowser.helpers';
import useSignInRedirect from 'Utilities/hooks/useSignInRedirect';

import { useRedirectTradeUsers } from './hooks/useRedirectTradeUsers';

const useRouterMiddleware = ({ routeChangeStart }) => {
	const router = useRouter();

	const handleRouteChangeStart = (url) => {
		routeChangeStart?.({ url });
	};

	useEffect(() => {
		router.events.on('routeChangeStart', handleRouteChangeStart);

		return () => {
			router.events.off('routeChangeStart', handleRouteChangeStart);
		};
	});
};

const RouterMiddleware = ({ children }) => {
	const { redirectIfNotSignedIn } = useSignInRedirect();
	const redirectTradeUsers = useRedirectTradeUsers();

	const routeChangeStart = ({ url }) => {
		redirectIfNotSignedIn(url);
		handleUnsupportedBrowser(url);
		redirectTradeUsers(url);
	};

	useRouterMiddleware({ routeChangeStart });

	return children;
};

export default RouterMiddleware;
