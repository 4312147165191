import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const {
	MARKETPLACE: { SORTING },
} = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

interface TriggerSortOrderSelectedEventProps {
	sortOrderValue: string;
}

export const triggerSortOrderSelectedEvent = ({ sortOrderValue }: TriggerSortOrderSelectedEventProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: SORTING,
		customData: { label: sortOrderValue },
		name: 'dlr_mrktplace_sort_order_selected_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	const gaEventPayload = {
		action: 'Sort order selected',
		category: SORTING,
		isLegacyEvent: false,
		label: sortOrderValue,
	};

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({ eventPayload: gaEventPayload, provider: GOOGLE_ANALYTICS });
};
