import { snakeCase } from 'lodash';

import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { BIDDING } = ANALYTICS_CATEGORIES;

const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

const { CLICK } = SNOWPLOW_EVENTS;

export const triggerOfferSubmittedEvent = (purchaseType: string): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: BIDDING,
				name: `dlr_${snakeCase(purchaseType)}_submitted`,
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
		{
			eventPayload: {
				action: `${purchaseType} submitted`,
				category: BIDDING,
				isLegacyEvent: false,
				label: '',
			},
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
