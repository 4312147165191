import { useContext } from 'react';

import { AppContext } from 'Context/AppContext';
import { isEmpty } from 'Utilities/helpers';

const useIsAuth = () => {
	const { state } = useContext(AppContext);
	return !isEmpty(state?.user);
};

export default useIsAuth;
