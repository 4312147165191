import isomorphicApi from '../../utilities/helpers/isomorphicApi';
import { API_ROUTES } from '../../utilities/routes';
import axios from '../axios';

// eslint-disable-next-line import/prefer-default-export
export const getGenericModels = async (context) =>
	isomorphicApi(context, {
		browser: async () => axios.get(API_ROUTES.MODELS),
		server: async (api) => api.getGenericModels(),
	});
