import { isFunction } from '.';

/**
 * This helper fix problems with SSR data fetching from DWS.
 * It uses `isSSR` variable to determinate if call is created in browser or server.
 *
 * Due to huge dependencies which are used in `dataProvider.js` this helpers
 * import `dataProvider.js` dynamically to do not bundle it.
 * @typedef {Object} IsomorphicAPI
 * @property {() => Promise} browser Api call executed on browser
 * @property {(Object) => Promise} server Api call executed on server
 *
 * @param {object} ctx Next.js context from getInitialProps
 * @param {IsomorphicAPI} apis Api call executed on server
 * */
// eslint-disable-next-line default-param-last
const isomorphicApi = async (ctx = {}, { browser, server }) => {
	if (!isFunction(server) || !isFunction(browser)) {
		throw new Error('isomorphicApi expects both apis to be defined as a function');
	}

	const { isSSR } = ctx;

	if (!isSSR) {
		const res = await browser();
		return res.data;
	}

	// eslint-disable-next-line import/no-cycle, import/extensions
	const apiFromDP = await import(/* webpackChunkName: "dataProvider" */ '../../services/dataProvider.js');
	const res = await server(apiFromDP);
	return res;
};

export default isomorphicApi;
