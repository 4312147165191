import get from 'lodash/get';
import set from 'lodash/set';
import { DateTime } from 'luxon';
import qs from 'qs';

import { IMAGE_KIND } from 'Components/pages/vehicleDetails/VehiclePhotos/VehiclePhotos.const';
import { SELLER_TYPES } from 'Components/VehicleList/VehicleListFilters.consts';
import { logger } from 'Services/logger/logger';
import { Order } from 'Types/generic';
import { ListType } from 'Types/listType';
import { SellerType } from 'Types/sellerType';
import { LIST_TYPES } from 'Utilities/consts';
import { VEHICLE_SORT_CONDITIONAL_OPTIONS } from 'Utilities/hooks/vehicleList/useSortOrder/useSortOrder.const';
import { getMakeModelQuery } from 'Utilities/makeModel/makeModelQuery.helpers';

import { GetVehicleListRequestQuery } from './api/vehicles.types';
import {
	ALLOWED_STATES_FOR_MAKE_MODEL_COUNT,
	INVERSE_FEATURE_TOGGLES,
	QUERY_STRING_OPTIONS,
	RESULTS_PER_PAGE,
	TEST_VEHICLE_ID,
} from './dataProvider.const';
import { BEToFEFeatureFlagsMapParam, FeatureFlags, ScopedFeatureFlags } from './dataProvider.types';
import { resolveStatesToFetch } from './services.helpers';

export const isTestVehicle = (vehicleId: number): boolean => {
	if (vehicleId !== TEST_VEHICLE_ID) {
		return false;
	}
	// We want to see if this test vehicle still exists in the results.
	// if it does not appear in logs, we can likely remove all related code for filtering it out.
	// Original PR for the test vehicle: https://github.com/motorway/motorway-dealer-webapp/pull/37#discussion_r263738382
	logger.warn({ message: 'Test vehicle removed from results', scope: 'isTestVehicle' });

	return true;
};

// NOTE: This is a legacy param that is used for testing on prod and not to be confused with Motorway Trade.
// It allows us to have vehicles that only specific test users can see on prod.
// Original PR: https://github.com/motorway/motorway-dealer-webapp/pull/460
const resolveExclusiveToDealer = (trade?: string | boolean) => (trade ? 'Trade' : 'None');

// Filter by Trade vehicles (listed by Motorway Trade users)
const resolveIsTrade = (isSellerTypeFilterEnabled: boolean, sellerType?: SellerType): boolean =>
	isSellerTypeFilterEnabled && sellerType === SELLER_TYPES.TRADE;

// We want to limit how old the sold vehicle entries we're fetching are
export const generateSoldDateTimestamp = (): Nullable<string> => DateTime.now().minus({ years: 1 }).toISODate();

export const getMakeModelStateFilter = (listType?: ListType): string[] => {
	if (!listType || !ALLOWED_STATES_FOR_MAKE_MODEL_COUNT[listType]) {
		return [];
	}
	return ALLOWED_STATES_FOR_MAKE_MODEL_COUNT[listType];
};

export const getMakeModelCountQuery = (params: GetVehicleListRequestQuery): string => {
	const {
		ageFrom,
		ageTo,
		bodyCategory,
		displayPriceFrom,
		displayPriceTo,
		distanceFromDealerId,
		excludeVehiclesOnFinance,
		exteriorGrade,
		fuel,
		isAvailableForCollection,
		listType,
		maxDistance,
		mileageFrom,
		mileageTo,
		previousKeepersCountFrom,
		previousKeepersCountTo,
		serviceHistory,
		transmission,
		vehicleClass,
	} = params;

	const isShortlist = listType === LIST_TYPES.shortlist;

	const state = getMakeModelStateFilter(listType);

	if (!state) {
		return '';
	}

	const apiParams = {
		ageFrom,
		ageTo,
		bodyCategory,
		countBy: ['makes', 'models'],
		displayPriceFrom,
		displayPriceTo,
		distanceFromDealerId,
		excludeVehiclesOnFinance,
		exclusiveToDealer: resolveExclusiveToDealer(params?.trade),
		fuel,
		...(isShortlist ? { forSale: true } : {}),
		grade: exteriorGrade,
		isAvailableForCollection,
		maxDistance,
		mileageFrom,
		mileageTo,
		previousKeepersCountFrom,
		previousKeepersCountTo,
		serviceHistory,
		shortlisted: isShortlist,
		state,
		transmission,
		vehicleClass,
	};

	return `${qs.stringify(apiParams, QUERY_STRING_OPTIONS)}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const redact = (obj: Record<any, any>, ...keys: string[]): Record<any, any> => {
	const logs = { ...obj };

	keys.forEach((key) => {
		if (get(logs, key)) {
			set(logs, key, '[REDACTED]');
		}
	});

	return logs;
};

export const extractFEFeatureFlags = (
	featureFlags: ScopedFeatureFlags,
	BEToFEFeatureFlagsMap: BEToFEFeatureFlagsMapParam,
): FeatureFlags => {
	const mappingEntries = Object.entries(BEToFEFeatureFlagsMap);
	try {
		return mappingEntries.reduce((acc, [beToggleName, { feToggleName, scope }]) => {
			// Scope determines whether we take dealership toggle value or user toggle value
			const scopedFlagValue = featureFlags[scope]?.[beToggleName];
			// Some toggles fetched from LD as true when we want to set them to false on the FE and vice versa
			const shouldInverseBEValue = INVERSE_FEATURE_TOGGLES.includes(feToggleName);

			acc[feToggleName] = shouldInverseBEValue ? !scopedFlagValue : Boolean(scopedFlagValue);
			return acc;
		}, {} as FeatureFlags);
	} catch (e) {
		const error = e instanceof Error ? e : new Error('There was an error extracting feature flags');
		logger.error({ error, message: error.message, scope: 'dataProvider.helpers extractFEFeatureFlags' });
		return {};
	}
};
// =====================================================================================================================
// TODO: Use this in the make/model count query function.
// This uses a lot of similar logic, but just picks some of the values from the params object.
// Therefore it makes sense to combine them.
// =====================================================================================================================

export const getVehicleListQuery = (params: GetVehicleListRequestQuery, isSellerTypeFilterEnabled: boolean): string => {
	const {
		ageFrom,
		ageTo,
		displayPriceFrom,
		displayPriceTo,
		distanceFromDealerId,
		excludeVehiclesOnFinance,
		exteriorGrade,
		fuel,
		includeSold,
		isAvailableForCollection,
		listType,
		make: makeModels,
		maxDistance,
		mileageFrom,
		mileageTo,
		order,
		page,
		previousKeepersCountFrom,
		previousKeepersCountTo,
		recommendedSortOrderVersion,
		sellerType,
		serviceHistory,
		sort,
		timeId,
		transmission,
		vehicleClass,
	} = params;

	const startAt = page === undefined ? undefined : Number(page) * RESULTS_PER_PAGE;
	const endAt = page === undefined ? undefined : (startAt ?? 0) + RESULTS_PER_PAGE;
	const state = resolveStatesToFetch(params);
	const isTrade = resolveIsTrade(isSellerTypeFilterEnabled, sellerType);
	const isShortlist = listType === LIST_TYPES.shortlist;
	const isAuction = listType === LIST_TYPES.auction;

	const apiParams = {
		_end: endAt,
		_include: ['images', 'bids', 'model'],
		_order: order ?? Order.Descending,
		_sort: sort,
		_start: startAt,
		ageFrom,
		ageTo,
		displayPriceFrom,
		displayPriceTo,
		distanceFromDealerId,
		excludeVehiclesOnFinance,
		exclusiveToDealer: resolveExclusiveToDealer(params?.trade),
		grade: exteriorGrade,
		imageKind: IMAGE_KIND.exteriorFrontDriver, // Only fetch main vehicle image as others not required on vehicle list
		isAvailableForCollection,
		maxDistance,
		mileageFrom,
		mileageTo,
		previousKeepersCountFrom,
		previousKeepersCountTo,
		serviceHistory,
		shortlisted: isShortlist,
		spec: {
			fuel,
			transmission,
			vehicleClass,
		},
		state,
		...(isShortlist ? { forSale: true } : {}),
		...(isAuction && timeId ? { timeId } : {}),
		...(isTrade ? { isTrade } : {}),
		...(sort === VEHICLE_SORT_CONDITIONAL_OPTIONS.recommended ? { recommendedSortOrderVersion } : {}),
		...(includeSold ? { soldDateFrom: generateSoldDateTimestamp(), useSoldDateFromOrNull: true } : {}),
	};

	const makeModelQuery = getMakeModelQuery(makeModels);

	return state ? `${qs.stringify(apiParams, QUERY_STRING_OPTIONS)}${makeModelQuery}` : '';
};
