import { LIST_TYPES } from 'Utilities/consts';

export const VEHICLE_SORT_OPTIONS = [
	{
		name: 'mostRecentlyAdd',
		order: 'DESC',
		sort: 'state.name,forSaleDate',
		value: 'state.name,forSaleDate-DESC',
	},
	{
		name: 'nearestTo',
		order: 'ASC',
		sort: 'distance',
		value: 'distance-ASC',
	},
	{
		name: 'displayPriceL',
		order: 'ASC',
		sort: 'displayPrice',
		value: 'displayPrice-ASC',
	},
	{
		name: 'displayPriceH',
		order: 'DESC',
		sort: 'displayPrice',
		value: 'displayPrice-DESC',
	},
	{
		name: 'mileagePriceL',
		order: 'ASC',
		sort: 'mileage',
		value: 'mileage-ASC',
	},
	{
		name: 'mileagePriceH',
		order: 'DESC',
		sort: 'mileage',
		value: 'mileage-DESC',
	},
	{
		name: 'yearN',
		order: 'DESC',
		sort: 'spec.firstRegistered',
		value: 'spec.firstRegistered-DESC',
	},
	{
		name: 'yearO',
		order: 'ASC',
		sort: 'spec.firstRegistered',
		value: 'spec.firstRegistered-ASC',
	},
] as const;

export const VEHICLE_SORT_OPTIONS_WITH_CONDITIONAL = [
	{
		name: 'recommended',
		order: 'DESC',
		sort: 'recommended',
		value: 'recommended-DESC',
	},
	...VEHICLE_SORT_OPTIONS,
	{
		name: 'bidCountsL',
		order: 'ASC',
		sort: 'bidCounts',
		value: 'bidCounts-ASC',
	},
	{
		name: 'bidCountsH',
		order: 'DESC',
		sort: 'bidCounts',
		value: 'bidCounts-DESC',
	},
] as const;

export const VEHICLE_SORT_OPTIONS_SORT_ONLY = [
	'recommended',
	'state.name,forSaleDate',
	'distance',
	'displayPrice',
	'mileage',
	'spec.firstRegistered',
	'bidCounts',
] as const;

export const VEHICLE_SORT_CONDITIONAL_OPTIONS = { bidCounts: 'bidCounts', recommended: 'recommended' };

export const VALID_RECOMMENDED_LIST_TYPES = [LIST_TYPES.auction];
export const VALID_BID_COUNTS_LIST_TYPES = [LIST_TYPES.auction];
