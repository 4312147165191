import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const {
	MARKETPLACE: { SAVED_SEARCHES },
} = ANALYTICS_CATEGORIES;

const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

export const triggerSavedSearchActivatedEvent = (isActivatedFilter: boolean): void => {
	const activeText = isActivatedFilter ? 'activated' : 'deselected';

	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: SAVED_SEARCHES,
				name: `dlr_saved_search_${activeText}`,
				schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
				version: SNOWPLOW_EVENTS.CLICK.VERSION,
				...(isActivatedFilter && { customData: { label: window.location.href } }),
			}),
			provider: SNOWPLOW,
		},
		{
			eventPayload: {
				action: `Saved search ${activeText}`,
				category: SAVED_SEARCHES,
				isLegacyEvent: false,
				label: isActivatedFilter ? window.location.href : '',
			},
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
