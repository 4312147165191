import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { BIDDING, VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

const { CLICK } = SNOWPLOW_EVENTS;

export const triggerMaxFeeTooltipClickedEvent = (isPurchaseModal: boolean): void => {
	const eventPayload = getSnowplowEventPayload({
		category: isPurchaseModal ? BIDDING : VEHICLE_DETAILS,
		name: 'dlr_max_fee_tool_tip_clicked',
		schemaName: CLICK.SCHEMA,
		version: CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
