import { newTracker, setUserId, trackPageView, trackSelfDescribingEvent } from '@snowplow/browser-tracker';
import getConfig from 'next/config';

import type { User } from 'Types/user';
import { isProd } from 'Utilities/helpers';

import { SNOWPLOW_SCHEMA_BASE_URL } from '../analytics/analytics.const';

import { PAGE_VIEW_EVENT_EXCLUDED_PATHS } from './snowplow.const';

const { publicRuntimeConfig } = getConfig();

export const initSnowplow = ({
	isSnowplowMiniRedirect,
	user,
}: {
	isSnowplowMiniRedirect: string;
	user: User;
}): void => {
	const { SNOWPLOW_COLLECTOR_URL, SNOWPLOW_MINI_URL } = publicRuntimeConfig;

	const COLLECTOR_URL = isSnowplowMiniRedirect ? SNOWPLOW_MINI_URL : SNOWPLOW_COLLECTOR_URL;

	newTracker('sp1', COLLECTOR_URL, {
		appId: `dealer-website-${isProd ? 'prod' : 'test'}`,
		cookieDomain: 'pro.motorway.co.uk',
		cookieSameSite: 'Lax',
		discoverRootDomain: false,
		onSessionUpdateCallback() {
			setTimeout(() => {
				trackSelfDescribingEvent({
					event: {
						data: {},
						schema: `${SNOWPLOW_SCHEMA_BASE_URL}/new_session_start/jsonschema/1-0-0`,
					},
				});
			}, 0);
		},
		plugins: [],
	});

	if (user?.id) {
		setUserId(`${user.id}`);
	}
};

export const trackSnowplowPageView = (): void => {
	const { pathname, search } = window.location;
	const windowUrl = `${pathname}${search}`;

	if (PAGE_VIEW_EVENT_EXCLUDED_PATHS.includes(windowUrl)) {
		return;
	}

	trackPageView();
};
