require('dotenv-defaults').config();

const isProd = process.env.APPLICATION_ENVIRONMENT === 'production';
const isStaging = process.env.APPLICATION_ENVIRONMENT === 'staging';
const isDev = process.env.NODE_ENV === 'development';

module.exports = {
	isDev,
	isProd,
	isStaging,
};
