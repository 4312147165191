export const GA_SELLER_DETAILS_CATEGORY = 'Seller details';

export const SELLER_BANK_DETAILS_DISCLAIMERS = Object.freeze({
	ADDITIONAL_DOC_MATCH: 'ADDITIONAL_DOC_MATCH',
	COP_NOT_ENROLLED: 'COP_NOT_ENROLLED',
	COP_NOT_ENROLLED_BANK_STATEMENT: 'COP_NOT_ENROLLED_BANK_STATEMENT',
	UNABLE_TO_PROVIDE_UK_BANK_ACCOUNT: 'UNABLE_TO_PROVIDE_UK_BANK_ACCOUNT',
	V5_NOT_MATCH: 'V5_NOT_MATCH',
});

export const SELLER_DISCLAIMERS_MESSAGE = Object.freeze({
	DEALER_ACCEPT_BANKDETAILS: 'Dealer will accept bank details anyway',
	PERMISSION_LETTER: "Keeper's permission letter provided",
	PROBATE_DOCUMENT: 'Probate documents provided',
	UNABLE_TO_PROVIDE_UK_BANK_ACCOUNT: 'Unable to provide UK bank account',
});

export const REVIEW_DOCUMENTS_STATE = Object.freeze({
	FAILED: 'FAILED',
	NOT_SUBMITTED: 'NOT_SUBMITTED',
	PASSED: 'PASSED',
	PENDING: 'PENDING',
});

export const DOCUMENT_TAG = Object.freeze({
	NOT_UPLOADED: 'NOT_UPLOADED',
	PASSED: 'PASSED',
	READY: 'READY',
});

export const COP_ENROLLED_STATES = Object.freeze({
	MATCHED: 'MATCHED',
	NOT_ENROLLED: 'NOT_ENROLLED',
});

export const DOCUMENT_KINDS = Object.freeze({
	BANK_STATEMENT: 'bank_statement',
	DRIVING_LICENCE: 'driving_licence',
	FINANCE_SETTLEMENT_LETTER: 'finance_settlement_letter',
	PROOF_OF_PURCHASE: 'proof_of_purchase',
	V5: 'v5',
});

export const DOCUMENT_DICTIONARY = Object.freeze({
	additional_documents: 'Additional documents',
	bank_details: 'Bank details',
	bank_statement: 'Bank statement',
	death_certificate: 'Death certificate',
	driving_licence: 'Driving licence',
	finance_settlement_letter: 'Finance settlement',
	letter_of_consent: 'Letter of authorisation',
	passport: 'Passport',
	proof_of_purchase: 'Proof of purchase',
	solicitors_letter: "Solicitor's letter",
	utility_bill: 'Proof of address',
	v5: 'V5C',
	will: 'Will',
});
