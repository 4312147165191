import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../analytics.const';

const {
	MARKETPLACE: { VEHICLE_LIST },
} = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

interface TriggerStatusTooltipHoverEventProps {
	vehicleStatus: string;
}

interface TriggerVehicleGradeHoverEventProps {
	id: number;
}

export const triggerBackToTopClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_LIST,
		name: 'dlr_mrktplace_vehicle_list_back_to_top_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Back to top button clicked',
			category: VEHICLE_LIST,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerStatusTooltipHoverEvent = ({ vehicleStatus }: TriggerStatusTooltipHoverEventProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_LIST,
		name: 'dlr_mrktplace_vehicle_list_status_tooltip_hovered',
		schemaName: SNOWPLOW_EVENTS.HOVER.SCHEMA,
		version: SNOWPLOW_EVENTS.HOVER.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Status tooltip hover',
			category: VEHICLE_LIST,
			isLegacyEvent: false,
			label: vehicleStatus,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerVehicleGradeHoverEvent = ({ id }: TriggerVehicleGradeHoverEventProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_LIST,
		name: 'dlr_mrktplace_vehicle_list_vehicle_grade_hovered',
		schemaName: SNOWPLOW_EVENTS.HOVER.SCHEMA,
		version: SNOWPLOW_EVENTS.HOVER.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: 'Vehicle grade hover',
			category: VEHICLE_LIST,
			isLegacyEvent: false,
			label: id,
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerVehicleCardClickEvent = (enquiryId: number): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_LIST,
		customData: { label: `${enquiryId}` },
		name: 'vehicle_card_click',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	const gaEventPayload = {
		action: 'Vehicle card click',
		category: VEHICLE_LIST,
		isLegacyEvent: false,
		label: enquiryId,
	};

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({ eventPayload: gaEventPayload, provider: GOOGLE_ANALYTICS });
};

export const triggerVehicleListFetchedEvent = (paginatedListOfIds: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_LIST,
		customData: { label: paginatedListOfIds },
		name: 'vehicles_loaded',
		schemaName: SNOWPLOW_EVENTS.LOAD.SCHEMA,
		version: SNOWPLOW_EVENTS.LOAD.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
