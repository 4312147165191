import { isProd } from 'Utilities/helpers';

import { BRAZE_API, BRAZE_SDK_ENDPOINT, SESSION_TIMEOUT_IN_SECONDS } from './braze.const';
import { onInitialisationFailure, onInitialisationSuccess } from './brazeInitialisationHandlers';

export const initBrazeSDK = async (signedInUserId: string): Promise<boolean> => {
	const { initialize } = await import('Services/braze/brazeExports');

	const isInitialised = initialize(BRAZE_API, {
		allowUserSuppliedJavascript: true,
		baseUrl: BRAZE_SDK_ENDPOINT,
		enableLogging: !isProd,
		enableSdkAuthentication: true,
		sessionTimeoutInSeconds: SESSION_TIMEOUT_IN_SECONDS,
	});

	if (!isInitialised) {
		onInitialisationFailure(signedInUserId);
		return false;
	}

	await onInitialisationSuccess(signedInUserId);
	return true;
};
