import { isEmpty } from 'lodash';
import getConfig from 'next/config';

import { getIsomorphicCookie, isString, stringBooleanToBoolean } from '../../utilities/helpers';

import { getValidFeatureToggles } from './FeaturesContext.helpers';
import {
	COMMA_SEPARATED_IDS_EXPRESSION,
	COMMA_SEPARATED_NEGATIVE_IDS_EXPRESSION,
	FEATURES_ARRAY,
} from './FeaturesContextConsts';
import alertUnrecognisedToggle from './featureToggleAlert';

const FEATURE_TOGGLES = getConfig()?.publicRuntimeConfig?.FEATURE_TOGGLES;

export const isValidToggleName = ({ key }) => FEATURES_ARRAY.includes(key);

export const commaSeparatedIdsList = ({ expression, value }) => {
	const match = isString(value) && value.match(expression);
	return match ? String(match) : null;
};

export const getToggleTypes = ({ value }) => ({
	COMMA_SEPARATED_IDS: commaSeparatedIdsList({ expression: COMMA_SEPARATED_IDS_EXPRESSION, value }),
	COMMA_SEPARATED_NEGATIVE_IDS: commaSeparatedIdsList({ expression: COMMA_SEPARATED_NEGATIVE_IDS_EXPRESSION, value }),
	FALSE: 'false',
	TRUE: 'true',
});

export const isIdIncludedInList = ({ id, list }) =>
	list
		.split(',')
		.map((identifier) => parseInt(identifier?.replace('!', '')))
		?.includes(id);

export const getToggleValue = ({ dealerId, feature }) => {
	const TOGGLE_TYPES = getToggleTypes(feature);
	switch (feature.value) {
		case TOGGLE_TYPES.TRUE:
			return TOGGLE_TYPES.TRUE;
		case TOGGLE_TYPES.FALSE:
			return TOGGLE_TYPES.FALSE;
		case TOGGLE_TYPES.COMMA_SEPARATED_IDS:
			return String(isIdIncludedInList({ id: dealerId, list: feature.value }));
		case TOGGLE_TYPES.COMMA_SEPARATED_NEGATIVE_IDS:
			return String(!isIdIncludedInList({ id: dealerId, list: feature.value }));
		default:
			return null;
	}
};

export const validateFeatureToggle = ({ dealerId, feature }) => {
	if (!isValidToggleName(feature)) {
		alertUnrecognisedToggle(feature, `Toggle is not recognised: ${feature.key}`);
		return false;
	}

	const toggleValue = getToggleValue({ dealerId, feature });

	if (!toggleValue) {
		alertUnrecognisedToggle(feature, 'Toggle value is not valid');
		return false;
	}

	return toggleValue;
};

export const getFeatureKeyValue = (feature) => {
	const [key, value] = feature.split('=');
	return { key, value };
};

export const getFeaturesArrayFromEnv = (envString) => envString?.split(';') || [];

export const getFeatureToggleValueFromEnv = ({ dealerId, envString, feature }) => {
	const featureFromEnv = getFeaturesArrayFromEnv(envString).find((env) => env.includes(feature));
	if (!featureFromEnv) {
		return '';
	}
	const featureKeyValue = getFeatureKeyValue(featureFromEnv);
	return validateFeatureToggle({ dealerId, feature: featureKeyValue });
};

export const getFeatureTogglesFromEnv = ({ dealerId, envString }) => {
	const featureStringsArray = getFeaturesArrayFromEnv(envString);

	const validFeatures = featureStringsArray.reduce((features, featureString) => {
		const feature = getFeatureKeyValue(featureString);

		const validFeature = validateFeatureToggle({ dealerId, feature });

		if (!validFeature) {
			return features;
		}

		return { ...features, [feature.key]: validFeature };
	}, {});

	return validFeatures;
};

export const getFeatureToggleValueFromQueryString = ({ availableFeatures = FEATURES_ARRAY, feature, query }) => {
	if (isEmpty(query)) {
		return false;
	}
	const validFeatureToggles = getValidFeatureToggles({ availableFeatures, features: query });
	return Boolean(validFeatureToggles?.[feature]);
};

export const getServerSideFeatureToggle = (feature, request) => {
	const cookieFeatureToggle = stringBooleanToBoolean(getIsomorphicCookie(feature, request));
	const envFeatureToggle = stringBooleanToBoolean(
		getFeatureToggleValueFromEnv({
			dealerId: request?.user?.dealerId,
			envString: FEATURE_TOGGLES,
			feature,
		}),
	);
	const queryStringToggle = getFeatureToggleValueFromQueryString({
		feature,
		query: request?.query,
	});

	return queryStringToggle || envFeatureToggle || cookieFeatureToggle;
};
