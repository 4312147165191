import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { SELLER_DETAILS } = ANALYTICS_CATEGORIES;

export const formattedLabel = (label: string): string => label.replace(/ /g, '_').toLowerCase();

export const triggerBankDetailsEvent = (label: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: SELLER_DETAILS,
		customData: { label },
		name: 'bank_details_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerViewDeliveryOptionsEvent = (enquiryId: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: SELLER_DETAILS,
		customData: { label: enquiryId },
		name: 'view_delivery_options_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerDownloadButtonEvent = (kind: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: SELLER_DETAILS,
		customData: { label: kind },
		name: 'download_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerSellerDocumentsEvents = (name: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: SELLER_DETAILS,
		name,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
