import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const {
	MARKETPLACE: { GALLERY },
} = ANALYTICS_CATEGORIES;

export const triggerHeroCarouselNextButtonClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: GALLERY,
		name: 'hero_carousel_next_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
