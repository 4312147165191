import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import {
	ANALYTICS_CATEGORIES,
	SNOWPLOW_BACKEND_ENTITIES,
	SNOWPLOW_BACKEND_SERVICES,
	SNOWPLOW_EVENTS,
} from '../analytics.const';
import { SnowplowBackendTriggerEventSchema } from '../analytics.types';

const {
	MARKETPLACE: { DOWNLOADABLE_VEHICLE_LIST },
} = ANALYTICS_CATEGORIES;

// TODO: remove the following 2 events when the new download CSV button feature is enabled.
export const triggerDownloadCSVButtonClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: DOWNLOADABLE_VEHICLE_LIST,
		name: 'dlr_mrktplace_download_vehicle_list_CSV_Button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerDownloadCSVButtonHoveredEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: DOWNLOADABLE_VEHICLE_LIST,
		name: 'dlr_mrktplace_download_vehicle_list_CSV_Button_hovered',
		schemaName: SNOWPLOW_EVENTS.HOVER.SCHEMA,
		version: SNOWPLOW_EVENTS.HOVER.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerMarketplaceDownloadCSVMenuOpenButtonClickedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: DOWNLOADABLE_VEHICLE_LIST,
		name: 'dlr_mrktplace_vehicle_list_csv_menu_open_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerMarketplaceDownloadCSVMenuOpenButtonHoveredEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: DOWNLOADABLE_VEHICLE_LIST,
		name: 'dlr_mrktplace_vehicle_list_csv_menu_open_button_hovered',
		schemaName: SNOWPLOW_EVENTS.HOVER.SCHEMA,
		version: SNOWPLOW_EVENTS.HOVER.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerMarketplaceCSVDownloadButtonClickedEvent = (selected?: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: DOWNLOADABLE_VEHICLE_LIST,
		customData: { label: selected },
		name: 'dlr_mrktplace_vehicle_list_csv_download_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerMarketplaceDownloadCSVAbortedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload<SnowplowBackendTriggerEventSchema>({
		action: 'dlr_mrktplace_vehicle_list_csv_download_aborted',
		backend_entity: SNOWPLOW_BACKEND_ENTITIES.DOWNLOAD,
		backend_service: SNOWPLOW_BACKEND_SERVICES.MARKETPLACE,
		schemaName: SNOWPLOW_EVENTS.BACKEND_TRIGGER.SCHEMA,
		success: true,
		version: SNOWPLOW_EVENTS.BACKEND_TRIGGER.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerMarketplaceDownloadCSVFailedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload<SnowplowBackendTriggerEventSchema>({
		action: 'dlr_mrktplace_vehicle_list_csv_download_failed',
		backend_entity: SNOWPLOW_BACKEND_ENTITIES.DOWNLOAD,
		backend_service: SNOWPLOW_BACKEND_SERVICES.MARKETPLACE,
		schemaName: SNOWPLOW_EVENTS.BACKEND_TRIGGER.SCHEMA,
		success: true,
		version: SNOWPLOW_EVENTS.BACKEND_TRIGGER.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerMarketplaceDownloadCSVSuccessEvent = (): void => {
	const eventPayload = getSnowplowEventPayload<SnowplowBackendTriggerEventSchema>({
		action: 'dlr_mrktplace_vehicle_list_csv_download_success',
		backend_entity: SNOWPLOW_BACKEND_ENTITIES.DOWNLOAD,
		backend_service: SNOWPLOW_BACKEND_SERVICES.MARKETPLACE,
		schemaName: SNOWPLOW_EVENTS.BACKEND_TRIGGER.SCHEMA,
		success: true,
		version: SNOWPLOW_EVENTS.BACKEND_TRIGGER.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
