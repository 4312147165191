import { z } from 'zod';

import { FALLBACK } from './schema.const';

export const TransmissionSchema = z.union([
	z.literal('Automatic'),
	z.literal('CVT'),
	z.literal('Manual'),
	z.literal('Semi-automatic'),
	z.literal('Other'),
	z.literal(FALLBACK),
]);

export const FuelSchema = z.union([
	z.literal('Diesel'),
	z.literal('Electric'),
	z.literal('Fuel cell'),
	z.literal('Gas'),
	z.literal('Gas bi-fuel'),
	z.literal('Hybrid'),
	z.literal('Petrol'),
	z.literal('Other'),
	z.literal(FALLBACK),
]);

const DealerContactSchema = z.object({ id: z.number() });

/* Reused */
export const SellerWillAcceptPriceSchema = z.number();
export const BidSchema = z.object({
	dealerContact: DealerContactSchema,
	id: z.number(),
	state: z.string(),
	value: z.number(),
});
export const UserBidSchema = z.array(BidSchema);
export const MinimumBidSchema = z.object({
	type: z.string(),
	value: z.number(),
});
export const SpecSchema = z.object({
	body: z.string().nullable(), // nullable in stage
	bodyCategory: z.string().nullable(), // nullable in stage
	colour: z.string().nullable(),
	engineSize: z.number().optional().nullable(),
	firstRegistered: z.string().nullable(),
	fuel: FuelSchema,
	height: z.number().nullable(),
	make: z.string(),
	makeId: z.number(),
	model: z.string(),
	modelId: z.number().nullable(),
	transmission: TransmissionSchema.nullable(), // nullable in stage
	vin: z.string().nullable(),
});
export const SoldDateSchema = z.string().datetime().optional().nullish();
/* End of reused */

export const GetVehicleBiddingSchema = z.object({
	activeBidCount: z.number().nullish(),
	allBids: z.array(BidSchema),
	bids: z.object({ activeBidCount: z.number(), usersBids: UserBidSchema }),
	displayPrice: z.number(),
	// hasOtherContactActiveBid is added in dataProvider
	hasOtherContactActiveBid: z.boolean(),
	isOwnedByUserDealership: z.boolean().nullish(),
	isVatQualifying: z.boolean().optional().nullable().catch(false),
	minimumBid: MinimumBidSchema,
	priceAverageCondition: z.number().nullish(),
	priceGoodCondition: z.number().nullish(),
	priceRetail: z.number().nullish(),
	priceSource: z.string().nullish(),
	priceUpdatedAt: z.string().datetime().nullish(),
	sellerWillAcceptPrice: SellerWillAcceptPriceSchema,
	soldDate: z.string().datetime().optional().nullish(),
	soldFor: z.number().nullish(),
	spec: SpecSchema,
});
