import T, { MDText } from 'i18n-react';

import texts from 'Components/texts.json';
import { formatDistanceRange, formatFilterRange, MinMaxFilters } from 'Components/VehicleList/filters/Filters.helpers';
import { VEHICLE_LIST_FILTER_NAME } from 'Components/VehicleList/VehicleListFilters.consts';

import LocalTexts from './SavedFilters.texts.json';

T.setTexts(texts);

const LocalT = new MDText(LocalTexts);

export const COULD_NOT_LOAD_SAVED_SEARCHES = LocalT.translate('getSavedFiltersError');
export const COULD_NOT_CREATE_SAVED_SEARCH = LocalT.translate('createSavedFilterError');
export const COULD_NOT_UPDATE_SAVED_SEARCH = LocalT.translate('updateSavedFilterError');
export const COULD_NOT_DELETE_SAVED_SEARCH = LocalT.translate('deleteSavedFilterError');
export const MAX_NUMBER_OF_ALLOWED_SEARCHES_REACHED = LocalT.translate('createSavedFilterMaxError');

export const ADDITIONAL_FILTERS = 'additonalFilters';

const {
	AGE_FROM,
	AGE_TO,
	DISPLAY_PRICE_FROM,
	DISPLAY_PRICE_TO,
	EXCLUDE_VEHICLES_ON_FINANCE,
	EXTERIOR_GRADE,
	FUEL,
	INCLUDE_SOLD,
	INCLUDE_UNDER_OFFER,
	IS_AVAILABLE_FOR_COLLECTION,
	MAKE,
	MAX_DISTANCE,
	MILEAGE_FROM,
	MILEAGE_TO,
	MODEL,
	ORDER,
	PREVIOUS_KEEPERS_COUNT_FROM,
	PREVIOUS_KEEPERS_COUNT_TO,
	SELECTED_VEHICLE,
	SERVICE_HISTORY,
	SORT,
	TRANSMISSION,
	VEHICLE_CLASS,
} = VEHICLE_LIST_FILTER_NAME;

export const OMITTED_FIELDS = [SORT, ORDER, SELECTED_VEHICLE];

export const FILTERS_DISPLAY_CONFIG = new Map([
	[
		VEHICLE_CLASS,
		{
			field: VEHICLE_CLASS,
			label: T.translate('vehicleList.filters.vehicleClass.label'),
			translationsPath: 'vehicleList.filters.vehicleClass',
		},
	],
	[
		MAKE,
		{
			field: MAKE,
			label: T.translate('vehicleList.filters.make'),
			makes: undefined,
		},
	],
	[
		MODEL,
		{
			fields: [MAKE, MODEL],
			label: T.translate('vehicleList.filters.model'),
			renderFn: undefined,
		},
	],
	[
		DISPLAY_PRICE_FROM,
		{
			fields: [DISPLAY_PRICE_FROM, DISPLAY_PRICE_TO],
			formatter: formatFilterRange('£'),
			label: T.translate('vehicleList.filters.reservePrice'),
			max: MinMaxFilters.price.MAX,
			min: MinMaxFilters.price.MIN,
		},
	],
	[
		MILEAGE_FROM,
		{
			fields: [MILEAGE_FROM, MILEAGE_TO],
			formatter: formatFilterRange('miles', true, false, MinMaxFilters.mileage.MAX),
			label: T.translate('vehicleList.filters.mileage'),
			max: MinMaxFilters.mileage.MAX,
			min: MinMaxFilters.mileage.MIN,
		},
	],
	[
		AGE_FROM,
		{
			fields: [AGE_FROM, AGE_TO],
			formatter: formatFilterRange('years', true, false, MinMaxFilters.age.MAX),
			label: T.translate('vehicleList.filters.age'),
			max: MinMaxFilters.age.MAX,
			min: MinMaxFilters.age.MIN,
		},
	],
	[
		PREVIOUS_KEEPERS_COUNT_FROM,
		{
			fields: [PREVIOUS_KEEPERS_COUNT_FROM, PREVIOUS_KEEPERS_COUNT_TO],
			formatter: formatFilterRange('owners', true, false, MinMaxFilters.previousKeepersCount.MAX),
			label: T.translate('vehicleList.filters.previousKeepers'),
			max: MinMaxFilters.previousKeepersCount.MAX,
			min: MinMaxFilters.previousKeepersCount.MIN,
		},
	],
	[
		MAX_DISTANCE,
		{
			field: MAX_DISTANCE,
			formatter: formatDistanceRange,
			label: T.translate('vehicleList.filters.distance'),
			labelHasPostCode: true,
			max: MinMaxFilters.distance.MAX,
			min: MinMaxFilters.distance.MIN,
			suffix: T.translate('vehicleList.filters.away'),
		},
	],
	[
		SERVICE_HISTORY,
		{
			field: SERVICE_HISTORY,
			label: T.translate('vehicleList.filters.serviceHistory'),
			translationsPath: 'vehicleList.filters.serviceHistories',
		},
	],
	[
		EXTERIOR_GRADE,
		{
			field: EXTERIOR_GRADE,
			label: T.translate('vehicleList.filters.exteriorGrade'),
			translationsPath: 'vehicleList.filters.exteriorGrades',
		},
	],
	[
		FUEL,
		{
			field: FUEL,
			label: T.translate('vehicleList.filters.fuel'),
			translationsPath: 'vehicleList.filters.fuels',
		},
	],
	[
		TRANSMISSION,
		{
			field: TRANSMISSION,
			label: T.translate('vehicleList.filters.transmission'),
			translationsPath: 'vehicleList.filters.transmissions',
		},
	],
	[
		IS_AVAILABLE_FOR_COLLECTION,
		{
			field: IS_AVAILABLE_FOR_COLLECTION,
			isBoolean: true,
			label: T.translate('vehicleList.filters.collection.label'),
			translation: T.translate('vehicleList.filters.collection.availableNow'),
		},
	],
	[
		ADDITIONAL_FILTERS,
		{
			fields: [INCLUDE_UNDER_OFFER, INCLUDE_SOLD, EXCLUDE_VEHICLES_ON_FINANCE],
			isBoolean: true,
			label: T.translate('vehicleList.filters.additionalFilters.title'),
			translations: [
				T.translate('vehicleList.includeUnderOffer'),
				T.translate('vehicleList.includeSold'),
				T.translate('vehicleList.filters.additionalFilters.excludeVehiclesOnFinance'),
			],
		},
	],
]);
