import { useContext } from 'react';
import { StoreApi, useStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { clearError, setError } from 'Stores/FilterStore/FilterStoreHelpers';
import { createGenericStoreWithDevtools } from 'Stores/store';
import { MakesContext } from 'Stores/StoresContext/StoresContext';
import { Makes } from 'Types/make';
import { isDev } from 'Utilities/helpers';
import { isNotNullOrUndefined } from 'Utilities/helpers/isNotNullOrUndefined';
import { getMakesDictionaries, getTotalCounts } from 'Utilities/makeModel/makeModelStore.helpers';

import { InitialMakesStoreValues, MakesStore } from './MakesStore.types';

const createMakesStore = (initialValues: InitialMakesStoreValues) =>
	devtools<MakesStore>(
		(set, get) => ({
			clearError: clearError(set),
			error: false,
			getMakeById: (id) => {
				const { makesById } = get();
				return makesById.get(parseInt(id?.toString?.()));
			},
			getMakeBySlug: (slug) => {
				const { makesBySlug } = get();
				return makesBySlug.get(slug);
			},
			getMakesBySlugs: (slugs = []) => {
				const { makesBySlug } = get();
				return slugs.map((slug) => makesBySlug.get(slug)).filter(isNotNullOrUndefined);
			},
			makes: initialValues?.makes,
			makesById: initialValues?.makesById,
			makesBySlug: initialValues?.makesBySlug,
			setCounts: (counts) => {
				if (!counts) {
					return;
				}
				const { makes: storeMakes } = get();
				const makes = getTotalCounts({ counts, entities: storeMakes });
				set({ makes }, false, { makes, type: 'setCounts' });
			},
			setError: setError(set),
			setMakes: (makes: Makes) => {
				set({ makes, ...getMakesDictionaries(makes) }, false, { makes, type: 'setMakes' });
			},
		}),
		{ enabled: isDev, name: 'MakesStore' },
	);

export const createMakeStoreWithInitial = (makes: Makes = []): StoreApi<MakesStore> => {
	const { makesById, makesBySlug } = getMakesDictionaries(makes);
	return createGenericStoreWithDevtools(createMakesStore({ makes, makesById, makesBySlug }));
};

export const useMakesStore = <T>(selector: (state: MakesStore) => T, equalityFn?: () => boolean): T => {
	const store = useContext(MakesContext);
	if (!store) {
		throw new Error('Missing MakesStoreContext.Provider in the tree');
	}

	return useStore(store, selector, equalityFn);
};

export const useMakes = (): MakesStore['makes'] => useMakesStore((store) => store.makes);
export const useSetMakes = (): MakesStore['setMakes'] => useMakesStore((store) => store.setMakes);
export const useSetMakesCount = (): MakesStore['setCounts'] => useMakesStore((store) => store.setCounts);
export const useGetMakeById = (): MakesStore['getMakeById'] => useMakesStore((store) => store.getMakeById);
export const useGetMakeBySlug = (): MakesStore['getMakeBySlug'] => useMakesStore((store) => store.getMakeBySlug);
export const useGetMakesBySlugs = (): MakesStore['getMakesBySlugs'] => useMakesStore((store) => store.getMakesBySlugs);

export default useMakesStore;
