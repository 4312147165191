import snakeCase from 'lodash/snakeCase';

import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DELIVERY } = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerBackToPreviousButtonEvent = (action: string, category: string, enquiryId: string): void => {
	const gaEventPayload = {
		action,
		category,
		isLegacyEvent: false,
		label: enquiryId,
	};

	const eventPayload = getSnowplowEventPayload({
		category,
		customData: { label: enquiryId },
		name: `dlr_${snakeCase(category)}_${snakeCase(action)}`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({ eventPayload: gaEventPayload, provider: GOOGLE_ANALYTICS });
};

export const triggerSelectedDeliveryTypeEvent = (action: string, enquiryId: string): void => {
	const gaEventPayload = {
		action,
		category: VEHICLE_DELIVERY,
		isLegacyEvent: false,
		label: enquiryId,
	};

	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DELIVERY,
		customData: { label: enquiryId },
		name: `dlr_vehicle_delivery_${snakeCase(action)}`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({ eventPayload: gaEventPayload, provider: GOOGLE_ANALYTICS });
};

export const triggerBookDeliveryButtonEvent = (action: string, enquiryId: string): void => {
	const gaEventPayload = {
		action,
		category: VEHICLE_DELIVERY,
		isLegacyEvent: false,
		label: enquiryId,
	};

	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DELIVERY,
		customData: { label: enquiryId },
		name: 'dlr_vehicle_delivery_book_delivery_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({ eventPayload: gaEventPayload, provider: GOOGLE_ANALYTICS });
};

export const triggerTransportFaqClickedEvent = (action: string, enquiryId: string): void => {
	const gaEventPayload = {
		action,
		category: VEHICLE_DELIVERY,
		isLegacyEvent: false,
		label: enquiryId,
	};

	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DELIVERY,
		customData: { label: enquiryId },
		name: 'dlr_vehicle_delivery_transport_faq_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({ eventPayload: gaEventPayload, provider: GOOGLE_ANALYTICS });
};

export const triggerTransportTermsAndConditionsClickedEvent = (action: string, enquiryId: string): void => {
	const gaEventPayload = {
		action,
		category: VEHICLE_DELIVERY,
		isLegacyEvent: false,
		label: enquiryId,
	};

	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DELIVERY,
		customData: { label: enquiryId },
		name: 'dlr_vehicle_delivery_transport_terms_and_conditions_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({ eventPayload: gaEventPayload, provider: GOOGLE_ANALYTICS });
};

export const triggerPurchasesButtonEvent = (enquiryId: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DELIVERY,
		customData: { label: enquiryId },
		name: 'dlr_vehicle_delivery_purchases_button_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
