import { valuesFromConfig } from 'Stores/FilterStore/FilterStoreHelpers';
import vehicleListFilterConfig from 'Stores/FilterStore/VehicleListFilter/VehicleListFilterConfig';
import { Filter, OptionalFilters } from 'Types/filter';

import { MapQueryFiltersToFilterStoreInitialValuesProps } from './StoresContext.types';

export const applyFilterStoreDefaults = (filters: OptionalFilters = {}): Filter => {
	const pristineFilters = valuesFromConfig(vehicleListFilterConfig) as Filter;
	return { ...pristineFilters, ...filters };
};

export const mapQueryFiltersToFilterStoreInitialValues = ({
	filters,
	getMakesBySlugs,
	getModelIds,
}: MapQueryFiltersToFilterStoreInitialValuesProps): Filter => {
	// NOTE: Models use strings (model name) in the query, but we want to set them as IDs in the filter store
	// So we map the model names to the makes (as model names are not necessarily unique) and use their IDs
	const selectedMakes = getMakesBySlugs(filters?.make || []);
	const makeIds = selectedMakes?.map((make) => make.id);
	const selectedModels = getModelIds({ makeIds, modelNames: filters?.model || [] });

	return applyFilterStoreDefaults({ ...filters, model: selectedModels });
};
