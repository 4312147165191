import { PROTECTED_ROUTES } from 'Utilities/routes';

import { SNOWPLOW_EVENTS } from '../analytics.const';
import { SnowplowVehicleListGlobalContextProps } from '../analytics.types';

import { createConditionalGlobalContext, getGlobalContext } from './globalContext.helpers';

const { PRO_VEHICLE_LIST } = SNOWPLOW_EVENTS;

export const snowplowVehicleListGlobalContext = ({
	currentFilter,
	filters,
	listType,
	pathname,
}: SnowplowVehicleListGlobalContextProps): void => {
	const isVehicleListPage = () => pathname === PROTECTED_ROUTES.VEHICLE_LIST;

	const {
		ageFrom,
		ageTo,
		displayPriceFrom,
		displayPriceTo,
		excludeVehiclesOnFinance,
		exteriorGrade,
		fuel,
		isAvailableForCollection,
		make,
		maxDistance,
		mileageFrom,
		mileageTo,
		model,
		previousKeepersCountFrom,
		previousKeepersCountTo,
		sellerType,
		serviceHistory,
		sort,
		transmission,
		vehicleClass,
	} = filters;

	const vehicleListGlobalContext = getGlobalContext({
		data: {
			filter_id: currentFilter ? `${currentFilter}` : null,
			list_type: listType,
			selected_age_max: ageTo,
			selected_age_min: ageFrom,
			selected_collection: isAvailableForCollection,
			selected_distance_max: maxDistance,
			selected_exterior_grade: exteriorGrade,
			selected_finance: excludeVehiclesOnFinance,
			selected_fuel: fuel,
			selected_makes: make,
			selected_mileage_max: mileageTo,
			selected_mileage_min: mileageFrom,
			selected_models: model,
			selected_previous_owners_max: previousKeepersCountTo,
			selected_previous_owners_min: previousKeepersCountFrom,
			selected_price_max: displayPriceTo,
			selected_price_min: displayPriceFrom,
			selected_seller_type: sellerType,
			selected_service_history: serviceHistory,
			selected_transmission: transmission,
			selected_vehicle_type: vehicleClass,
			sort_order: sort,
		},
		schemaEvent: PRO_VEHICLE_LIST,
	});

	createConditionalGlobalContext({
		conditionalFilter: isVehicleListPage,
		previousGlobalContextDataKey: 'vehicleList',
		updatedGlobalContextData: vehicleListGlobalContext,
	});
};
