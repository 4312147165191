import type { Options } from 'pino-http';

import type { User } from 'Types/user';

export type LogContext = Record<string, unknown> & { error?: Error };
export type LoggerMethod = (message: string, context?: LogContext) => void;

export type LogLevel = 'critical' | 'debug' | 'error' | 'fatal' | 'info' | 'trace' | 'warn';

export type DatadogLogLevel = 'debug' | 'info' | 'warn' | 'error';

export const CLIENT_LOGGING_PROVIDER_NAMES = { console: 'console', datadog: 'datadog' } as const;

export type LoggerProps = {
	context?: LogContext;
	error?: Error;
	message: string;
	providers?: (keyof typeof CLIENT_LOGGING_PROVIDER_NAMES)[];
	scope: string;
};

export type LogProps = LoggerProps & { level: LogLevel };

export type Logger = {
	critical: LoggerMethod;
	debug: LoggerMethod;
	error: LoggerMethod;
	fatal: LoggerMethod;
	info: LoggerMethod;
	trace: LoggerMethod;
	warn: LoggerMethod;
};

export type PinoInstance = Options & {
	logger: { critical?: (context?: Record<string, unknown>, message?: string) => void };
};

export type GetShouldReportClientLogProps = { statusCode?: number; user?: User };

export type BeforeSendClientLogProps = { statusCode?: number; user?: User };
