import { z } from 'zod';

import { getBodyType } from 'Pages/vehicles/helpers/images.helpers';
import { getDistance, getIsTrade } from 'Server/api/mappers/vehicles.helpers';
import {
	CanceledCategory,
	ForSaleCategory,
	ProfiledCategory,
	RejectedCategory,
	SoldCategory,
	StateCategory,
	StateName,
	UnsoldCategory,
} from 'Types/enquiryStates';
import { getYearFromDate } from 'Utilities/formatters';
import { transformSellerStats } from 'Utilities/helpers/transformSellerStats';

import { GetVehicleBiddingSchema } from '../schemas/bidding.schema';
import { LimitationsSchema } from '../schemas/limitations.schema';
import { GetVehicleMotSchema } from '../schemas/mot.schema';
import { FALLBACK } from '../schemas/schema.const';
import { ServiceHistoryRecordItem, ServiceHistorySchema, ServiceRecordValues } from '../schemas/serviceHistory.schema';
import { GetWheelsAndTyresSchema } from '../schemas/wheelsAndTyres.schema';
import { biddingSectionTransformer } from '../transformers/biddingSection.transformer';
import { detailsSectionTransformer } from '../transformers/detailsSection.transformer';
import { motSectionTransformer } from '../transformers/motSection.transformer';
import { serviceHistorySectionTransformer } from '../transformers/serviceHistorySection.transformer';
import { specificationSectionTransformer } from '../transformers/specificationSection.transformer';
import { wheelsAndTyresTransformer } from '../transformers/wheelsAndTyresSection.transformer';

import { mapImages } from './transformers';
import { getYesNoDashValue } from './vehicle.schema.helpers';

export const forSaleCategorySchema = z.nativeEnum(ForSaleCategory);
export const soldCategorySchema = z.nativeEnum(SoldCategory);
export const profiledCategorySchema = z.nativeEnum(ProfiledCategory);
export const unsoldCategorySchema = z.nativeEnum(UnsoldCategory);
export const canceledCategorySchema = z.nativeEnum(CanceledCategory);
export const rejectedCategorySchema = z.nativeEnum(RejectedCategory);

export const StateSlugSchema = z.union([
	forSaleCategorySchema,
	canceledCategorySchema,
	rejectedCategorySchema,
	unsoldCategorySchema,
	soldCategorySchema,
	profiledCategorySchema,
]);

export const GRADES = {
	BRONZE: 'bronze',
	GOLD: 'gold',
	SILVER: 'silver',
} as const;

export type GradingValues = (typeof GRADES)[keyof typeof GRADES];

export const GRADE_VALUES: GradingValues[] = Object.values(GRADES);

const YesNoDashSchema = z.union([z.literal('Yes'), z.literal('No'), z.literal(FALLBACK)]);

const GradeEnumSchema = z.nativeEnum(GRADES);

export const GradeWithFallbackSchema = z.union([GradeEnumSchema, z.literal(FALLBACK)]);

const VehicleImageSchema = z.object({
	createdAt: z.string().nullable(),
	damageMeta: z
		.object({
			overlayUrl: z.string(),
			panelType: z.string().optional(),
			side: z.string(),
			size: z.string(),
			x: z.union([z.number(), z.string()]),
			y: z.union([z.number(), z.string()]),
		})
		.nullable(),
	enquiryId: z.number(),
	id: z.number(),
	isDamaged: z.boolean().nullable(),
	kind: z.string().nullable(),
	lastUpdated: z.string().nullable(),
	presets: z.tuple([
		z.object({
			name: z.literal('thumbnail'),
			url: z.string(),
		}),
		z.object({
			name: z.literal('desktop'),
			url: z.string(),
		}),
		z.object({
			name: z.literal('mobile'),
			url: z.string(),
		}),
		z.object({
			name: z.literal('thumbnailTiny'),
			url: z.string(),
		}),
	]),
	status: z.string(),
	url: z.string(),
});

export const SellerSchema = z.object({
	area: z.string().nullable(),
	country: z.string().nullable(),
	distance: z.number().nullable().optional(),
	latitude: z.number().nullable(),
	longitude: z.number().nullable(),
	postcode: z.string().optional(),
	sellerStats: z.union([
		z.boolean(),
		z.object({
			dealerBusinessCreatedOn: z.string(),
			dealerCreatedOn: z.string(),
			numberOfDealerTransactions: z.number(),
			updatedOn: z.string(),
		}),
	]),
	town: z.string().nullable(),
});

export const StateSchema = z.object({
	category: z.nativeEnum(StateCategory),
	name: z.nativeEnum(StateName),
	slug: StateSlugSchema,
});

export const ServiceHistoryRecordSchema = z.object({
	count: z.number(),
	items: z.array(ServiceHistoryRecordItem),
});

export const AdditionalSpecV2Schema = z.object({
	items: z.array(
		z.object({
			name: z.string(),
			price: z.union([z.string(), z.number()]),
		}),
	),
	totalCost: z.number().optional(),
});

export const GetVehicleSchema = z
	.object({
		additionalDetails: z.string().nullable(),
		additionalSpec: z.string().nullable(),
		additionalSpecV2: AdditionalSpecV2Schema.optional(),
		areBatteriesInLease: z.boolean().optional().nullable(),
		availableFrom: z.string().nullable(),
		availableUntil: z.string().nullable(),
		chargingCables: z.array(z.enum(['3PinDomestic', 'fast'])).nullable(),
		dentsDesc: z.string().nullable(),
		distance: z.number().nullable().optional(),
		electricsProblemsDesc: z.string().optional().nullable(),
		equipment: z.string().optional(),
		exclusiveToDealer: z.string().catch(''),
		grade: GradeWithFallbackSchema.nullable(),
		hasBeenSmokedIn: z.boolean().nullable(),
		hasDents: z.boolean().nullable(),
		hasElectricsProblems: z.boolean().optional().nullable(),
		hasMissingParts: z.boolean().nullable(),
		hasPaintProblems: z.boolean().nullable(),
		hasPrivatePlate: z.boolean().nullable(),
		hasScratches: z.boolean().nullable(),
		hasServiceRecords: z.boolean().nullable(),
		hasWarningLights: z.boolean().nullable(),
		hasWindscreenProblems: z.boolean().nullable(),
		id: z.number(),
		identifierIssues: z.array(z.string()).nullable(),
		independentServiceStampsCount: z.number().nullable(),
		interiorType: z.string().nullable(),
		isTrade: z.boolean(),
		isVehicleOnFinance: z.boolean().nullable().catch(false),
		keepersList: z
			.array(
				z.object({
					startDate: z.string(),
				}),
			)
			.catch([]),
		keeperStartDate: z.string().catch(''),
		keepPrivatePlate: z.boolean().optional().nullable().catch(null),
		keysCount: z.number().nullable().catch(null),
		limitations: LimitationsSchema.nullable(),
		mileage: z.number(),
		mileageRecords: z
			.array(
				z.object({
					isSuspicious: z.boolean(),
					mileage: z.number(),
					recordTaken: z.string(),
					source: z.string(),
				}),
			)
			.catch([]),
		missingPartsDesc: z.string().nullable(),
		officialServiceStampsCount: z.number().nullable(),
		paintProblemsDesc: z.string().nullable(),
		prettyVrm: z.string(),
		previousKeepersCount: z.number().nullable(),
		scratchesDesc: z.string().nullable(),
		seller: SellerSchema,
		serviceHistory: ServiceHistorySchema.nullable(),
		serviceHistoryRecords: ServiceHistoryRecordSchema.optional().nullable().default(null),
		serviceRecord: ServiceHistorySchema.nullable(),
		state: StateSchema,
		vehicleImages: z.array(VehicleImageSchema),
		vrm: z.string(),
		warningLightsDesc: z.string().nullable(),
		windscreenProblemsDesc: z.string().nullable(),
	})
	.merge(GetVehicleBiddingSchema)
	.merge(GetVehicleMotSchema)
	.merge(GetWheelsAndTyresSchema);

export const VehicleSchema = GetVehicleSchema.transform((vehicle) => ({
	bidding: {
		...biddingSectionTransformer(vehicle),
	},
	conditionAndDamage: {
		additionalDetails: vehicle.additionalDetails,
		dentsDesc: vehicle.dentsDesc,
		hasBeenSmokedIn: getYesNoDashValue(vehicle.hasBeenSmokedIn),
		hasDents: getYesNoDashValue(vehicle.hasDents),
		hasMissingParts: getYesNoDashValue(vehicle.hasMissingParts),
		hasPaintProblems: getYesNoDashValue(vehicle.hasPaintProblems),
		hasScratches: getYesNoDashValue(vehicle.hasScratches),
		hasWarningLights: getYesNoDashValue(vehicle.hasWarningLights),
		hasWindscreenProblems: getYesNoDashValue(vehicle.hasWindscreenProblems),
		missingPartsDesc: vehicle.missingPartsDesc,
		paintProblemsDesc: vehicle.paintProblemsDesc,
		scratchesDesc: vehicle.scratchesDesc,
		warningLightsDesc: vehicle.warningLightsDesc,
		windscreenProblemsDesc: vehicle.windscreenProblemsDesc,
	},
	details: {
		...detailsSectionTransformer(vehicle),
	},
	electricalAndMechanicalIssues: {
		electricsProblemsDesc: vehicle.electricsProblemsDesc ?? null,
		hasElectricsProblems: vehicle.hasElectricsProblems ?? null,
	},
	features: {
		equipment: vehicle.equipment ?? '',
		highValueCarFeatures: {
			items:
				vehicle.additionalSpecV2?.items.map((item) => ({
					name: item.name,
					price: Number(item.price),
				})) || [],
			totalCost: Number(vehicle.additionalSpecV2?.totalCost || 0),
		},
		manuallyAddedAdditionalFeatures: vehicle.additionalSpec,
	},
	gallery: {
		bodyType: getBodyType({ body: vehicle.spec.bodyCategory, height: vehicle.spec.height }),
		...mapImages(vehicle.vehicleImages),
	},
	id: vehicle.id,
	location: {
		seller: {
			area: vehicle.seller.area,
			country: vehicle.seller.country ?? '',
			distance: getDistance(vehicle.distance),
			latitude: vehicle.seller.latitude,
			longitude: vehicle.seller.longitude,
			postcode: vehicle.seller.postcode ?? null,
			town: vehicle.seller.town,
		},
	},
	mainInfo: {
		fuel: vehicle.spec.fuel,
		make: vehicle.spec.make,
		makeId: vehicle.spec.makeId,
		mileage: vehicle.mileage,
		model: vehicle.spec.model,
		modelId: vehicle.spec.modelId,
		prettyVrm: vehicle.prettyVrm,
		transmission: vehicle.spec.transmission ?? FALLBACK,
		year: getYearFromDate(vehicle.spec.firstRegistered),
	},
	mot: {
		...motSectionTransformer(vehicle),
	},
	ratings: {
		grade: vehicle.grade ?? FALLBACK,
		previousOwners: vehicle.previousKeepersCount,
		serviceHistory: vehicle.serviceHistory ?? ServiceRecordValues.NOT_PROVIDED,
	},
	seller: {
		isTrade: getIsTrade(vehicle),
		isTradeSeller: vehicle.isTrade,
		sellerStats: transformSellerStats(vehicle.seller.sellerStats),
	},
	serviceHistory: serviceHistorySectionTransformer(vehicle),
	similarVehicles: {
		id: vehicle.id,
		make: vehicle.spec.make,
		modelId: vehicle.spec.modelId,
		stateSlug: vehicle.state.slug,
	},
	specification: specificationSectionTransformer(vehicle),
	state: vehicle.state,
	transport: {
		availableFrom: vehicle.availableFrom,
		availableUntil: vehicle.availableUntil,
	},
	wheelsAndTyres: {
		...wheelsAndTyresTransformer(vehicle),
	},
}));

export type VehicleData = z.infer<typeof VehicleSchema>;
export type VehicleImage = z.infer<typeof VehicleImageSchema>;
export type VehicleState = z.infer<typeof VehicleSchema>['state'];
export type VehicleRating = z.infer<typeof VehicleSchema>['ratings'];
export type VehicleMainInfo = z.infer<typeof VehicleSchema>['mainInfo'];
export type VehicleDetails = z.infer<typeof VehicleSchema>['details'];
export type VehicleElectricalAndMechanicalIssues = z.infer<typeof VehicleSchema>['electricalAndMechanicalIssues'];
export type VehicleFeatures = z.infer<typeof VehicleSchema>['features'];
export type VehicleSpecification = z.infer<typeof VehicleSchema>['specification'];
export type VehicleBidding = z.infer<typeof VehicleSchema>['bidding'];
export type VehicleMOT = z.infer<typeof VehicleSchema>['mot'];
export type VehicleConditionAndDamage = z.infer<typeof VehicleSchema>['conditionAndDamage'];
export type GalleryImage = VehicleData['gallery']['images'][number];
export type Vehicle = z.infer<typeof VehicleSchema>;
export type VehicleTransport = z.infer<typeof VehicleSchema>['transport'];
export type VehicleLocation = VehicleData['location'];
export type VehicleEnquiryId = VehicleData['id'];
export type YesNoDash = z.infer<typeof YesNoDashSchema>;
export type Grading = z.infer<typeof GradeEnumSchema> & string;
export type GetVehicle = z.infer<typeof GetVehicleSchema>;
export type WheelAndTyres = z.infer<typeof VehicleSchema>['wheelsAndTyres'];
