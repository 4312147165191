/* eslint-disable no-console */
import { Logger } from './logger.types';

// NOTE: This should not be used directly. Use the logger instead.

export const consoleLogger: Logger = {
	critical: (message, context) => console.error(message, context, context?.error),
	debug: (message, context) => console.debug(message, context, context?.error),
	error: (message, context) => console.error(message, context, context?.error),
	fatal: (message, context) => console.error(message, context, context?.error),
	info: (message, context) => console.info(message, context),
	trace: (message, context) => console.trace(message, context),
	warn: (message, context) => console.warn(message, context, context?.error),
};
