import useUser from './useUser';

export const checkForPermission = (user, permission) => {
	const hasPermissions = user?.dealerPermissions?.length;

	if (!hasPermissions) {
		return false;
	}

	return user.dealerPermissions.includes(permission);
};

const usePermission = (permission) => {
	const user = useUser();

	return checkForPermission(user, permission);
};

export default usePermission;
