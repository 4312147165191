import { AxiosError } from 'axios';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist, subscribeWithSelector } from 'zustand/middleware';

import { ApiSchemaUnshortlistEnquiries } from 'Services/api/schema.types';
import * as ShortlistApi from 'Services/api/shortlist';
import { handleAxiosError } from 'Utilities/error/handleAxiosError';
import { isDev } from 'Utilities/helpers';

import { Shortlist, ShortlistStore, ToggleUnshortlistCacheParams } from './ShortlistStore.d';

const shortlistStore = devtools<ShortlistStore>(
	(set, get) => ({
		addToShortlist: async (id) => {
			const { setError } = get();

			try {
				const shortlisted = await ShortlistApi.addToShortlist(id);
				const addToShortlistHandler = (state: ShortlistStore) => ({
					shortlist: { ...state.shortlist, [shortlisted.enquiryId]: shortlisted },
				});
				const action = { id, type: 'addToShortlist' };
				set(addToShortlistHandler, false, action);
			} catch (err) {
				return setError(err);
			}

			return null;
		},
		addToUnshortlistCache: (id: number) => {
			const { unshortlistCache } = get();
			if (!unshortlistCache.includes(id)) {
				unshortlistCache.push(id);
				set(
					{
						unshortlistCache,
					},
					false,
					{ id, type: 'addToUnshortlistCache' },
				);
			}
		},
		clearError: () => {
			set({ error: false }, false, { type: 'clearError' });
		},
		clearUnshortlistCache: () => {
			set({ unshortlistCache: [] }, false, { type: 'clearUnshortlistCache' });
		},
		error: false,
		isWaitingOnUnshortlistResponse: false,
		loadShortlist: async () => {
			const { setError } = get();

			try {
				const shortlisted = await ShortlistApi.loadShortlist();
				const loadShortlistHandler = {
					shortlist: shortlisted.reduce((acc, shortlist) => ({ ...acc, [shortlist.enquiryId]: shortlist }), {}),
				};
				const action = { type: 'loadShortlist' };
				set(loadShortlistHandler, false, action);
			} catch (err) {
				return setError(err);
			}

			return null;
		},
		removeEnquiriesFromShortlist: (enquiries: ApiSchemaUnshortlistEnquiries['data']) => {
			const removeEnquiriesFromShortlistHandler = (state: ShortlistStore) => {
				const shortlistedEnquiries = Object.keys(state.shortlist);
				const nextShortlist = shortlistedEnquiries.reduce((newShortlist: Shortlist, key) => {
					const isShortlistEnquiriesMatchedWithUnshortlistEnquiries = enquiries.some(
						(id) => id.enquiryId === state.shortlist[key].enquiryId,
					);
					if (!isShortlistEnquiriesMatchedWithUnshortlistEnquiries) {
						newShortlist[key] = state.shortlist[key];
					}
					return newShortlist;
				}, {});

				return {
					shortlist: nextShortlist,
				};
			};

			const action = {
				ids: enquiries,
				type: 'removeEnquiriesFromShortlist',
			};

			set(removeEnquiriesFromShortlistHandler, false, action);
		},
		removeFromShortlist: async (id) => {
			const { setError } = get();
			const removeFromShortlistHandler = (state: ShortlistStore) => {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				const { [id]: omit, ...nextShortlist } = state.shortlist;

				return {
					shortlist: nextShortlist,
				};
			};

			const action = {
				id,
				type: 'removeFromShortlist',
			};

			try {
				await ShortlistApi.removeFromShortlist(id);
				set(removeFromShortlistHandler, false, action);
			} catch (err) {
				return setError(err);
			}

			return null;
		},
		removeFromUnshortlistCache: (id: number) => {
			const { unshortlistCache } = get();
			const newCache = unshortlistCache.filter((cache) => cache !== id);
			set(
				{
					unshortlistCache: newCache,
				},
				false,
				{ id, type: 'removeFromUnshortlistCache' },
			);
		},
		setError: (err) => {
			set({ error: true }, false, { err, type: 'setError' });
			handleAxiosError({ axiosError: err as AxiosError });

			return true;
		},
		setIsWaitOnUnshortlistResponse: (isWaitingOnUnshortlistResponse: boolean) => {
			const action = {
				isWaitingOnUnshortlistResponse,
				type: 'setIsWaitOnUnshortlistResponse',
			};
			set({ isWaitingOnUnshortlistResponse }, false, action);
		},
		shortlist: {},
		toggleShortlisting: async ({ checked, id }) => {
			const { addToShortlist, removeFromShortlist } = get();

			if (checked) {
				return addToShortlist(id);
			}

			return removeFromShortlist(id);
		},
		toggleUnshortlistCacheById: ({ checked, id }: ToggleUnshortlistCacheParams) => {
			const { addToUnshortlistCache, removeFromUnshortlistCache } = get();

			if (checked) {
				addToUnshortlistCache(id);
			} else {
				removeFromUnshortlistCache(id);
			}
		},
		unshortlistCache: [],
		unshortlistFromCache: async () => {
			const { unshortlistCache } = get();
			if (unshortlistCache.length === 0) return null;
			const res = await ShortlistApi.removeEnquiriesFromShortlist(unshortlistCache);
			return res;
		},
	}),
	{
		enabled: isDev,
		name: 'ShortlistStore',
	},
);

export const useShortlistStore = create(
	subscribeWithSelector(
		persist(shortlistStore, {
			name: 'shortlist-storage',
			storage: createJSONStorage(() => sessionStorage),
		}),
	),
);
export const useRemoveEnquiriesFromShortlist = (): ShortlistStore['removeEnquiriesFromShortlist'] =>
	useShortlistStore((store) => store.removeEnquiriesFromShortlist);
export const useToggleUnshortlistCacheById = (): ShortlistStore['toggleUnshortlistCacheById'] =>
	useShortlistStore((store) => store.toggleUnshortlistCacheById);
export const useUnshortlistCache = (): ShortlistStore['unshortlistCache'] =>
	useShortlistStore((store) => store.unshortlistCache);
export const useClearUnshortlistCache = (): ShortlistStore['clearUnshortlistCache'] =>
	useShortlistStore((store) => store.clearUnshortlistCache);
export const useUnshortlistFromCache = (): ShortlistStore['unshortlistFromCache'] =>
	useShortlistStore((store) => store.unshortlistFromCache);
export const useIsWaitingOnUnshortlistResponse = (): ShortlistStore['isWaitingOnUnshortlistResponse'] =>
	useShortlistStore((store) => store.isWaitingOnUnshortlistResponse);
export const useSetIsWaitOnUnshortlistResponse = (): ShortlistStore['setIsWaitOnUnshortlistResponse'] =>
	useShortlistStore((store) => store.setIsWaitOnUnshortlistResponse);
export const useAddToShortlist = (): ShortlistStore['addToShortlist'] =>
	useShortlistStore((store) => store.addToShortlist);
export const useIsUnshortlisted = (id: number): boolean =>
	useShortlistStore((store) => store.unshortlistCache.includes(id));
