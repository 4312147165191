import React from 'react';

import { FOR_SALE_CATEGORY, UNSOLD_CATEGORY } from 'Utilities/vehicleState';

import { BiddingContext } from './BiddingContext.types';

export const BIDDING_PAGES = ['/vehicle_list', '/vehicle_details', '/vehicles/[enquiryId]'];

export const VALID_BIDDING_STATES = [...Object.values(FOR_SALE_CATEGORY), UNSOLD_CATEGORY.UNSOLD_BELOW_RESERVE_BID];

export const createBiddingContext = (): React.Context<BiddingContext> => {
	const biddingContext = React.createContext<BiddingContext>({
		clearVehicleIds: () => undefined,
		subscribedVehicleIds: [],
		subscribeVehicleId: () => undefined,
		vehiclesBidData: {},
	});

	biddingContext.displayName = 'BiddingContext';

	return biddingContext;
};

export const SOCKET_EVENTS = Object.freeze({
	AUTHENTICATE: 'authenticate',
	AUTHENTICATED: 'authenticated',
	CONNECT: 'connect',
	CONNECT_ERROR: 'connect_error',
	CONNECT_FAILED: 'connect_failed',
	DISCONNECT: 'disconnect',
	ERROR: 'error',
	OPEN_CONNECTION_FOR_ENQUIRY_IDS: 'openConnectionForEnquiryIds',
	RECEIVE_BIDDING_STATE: 'receiveBiddingState',
	RECEIVE_INITIAL_BIDDING_STATES: 'receiveInitialBiddingStates',
	RECONNECT: 'reconnect',
	RECONNECT_ATTEMPT: 'reconnect_attempt',
	SALE_ENDED: 'saleEnded',
	SALE_STARTED: 'saleStarted',
	UNAUTHORIZED: 'unauthorized',
});
