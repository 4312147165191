import { NextPageContext } from 'next';

import { getSaleTime } from 'Services/api/sale';
import { RESPONSE_STATUS } from 'Services/httpStatusChecker';
import { SaleTimes } from 'Types/saleTimes';
import { ERRORS } from 'Utilities/consts';
import { fetchWithExceptionLogger } from 'Utilities/error/fetchWithExceptionLogger';

const fetchSaleTimeWithExceptionLogger = async (context: NextPageContext): Promise<SaleTimes> =>
	fetchWithExceptionLogger({
		callback: getSaleTime.bind(null),
		errorMessage: ERRORS.UNABLE_TO_FETCH_SALE_TIME,
		extra: { context },
		ignoredResponses: [RESPONSE_STATUS.client.forbidden],
		isWarning: true,
	});

export default fetchSaleTimeWithExceptionLogger;
