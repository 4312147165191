import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { HISTORY_CHECK } = ANALYTICS_CATEGORIES;

export const triggerHistoryCheckModalCloseEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: HISTORY_CHECK,
		name: 'dlr_history_check_modal_closed',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
