import { snakeCase } from 'lodash';

import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { BIDDING } = ANALYTICS_CATEGORIES;

const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

const { CLICK } = SNOWPLOW_EVENTS;

interface TriggerOfferSubmissionErrorEventParams {
	message: string;
	purchaseType: string;
}

export const triggerOfferSubmissionErrorEvent = ({
	message,
	purchaseType,
}: TriggerOfferSubmissionErrorEventParams): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: BIDDING,
				customData: { label: message },
				name: `dlr_${snakeCase(purchaseType)}_submission_error`,
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
		{
			eventPayload: {
				action: `${purchaseType} submission error`,
				category: BIDDING,
				isLegacyEvent: false,
				label: message,
			},
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
