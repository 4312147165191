import React from 'react';

import api from 'Services/api';
import type { User } from 'Types/user';
import type { Nullable } from 'Types/utils';
import { useFullState } from 'Utilities/hooks/hooks';

interface AppContextState {
	user: Nullable<User>;
}

type AppContextUpdate = (nextState: AppContextState, callback?: () => void) => void;

export interface AppContextValues {
	api: typeof api;
	state: AppContextState;
	update: AppContextUpdate;
}

const AppContext = React.createContext<AppContextValues | undefined>(undefined);

AppContext.displayName = 'AppContext';

interface AppContextProviderProps {
	children: React.ReactNode;
	user?: Nullable<User>;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({ children, user = null }) => {
	// This context is now only responsible for the user state, so we should rename/move it to UserContext
	const [state, setState] = useFullState({ user }) as [AppContextState, AppContextUpdate];

	return <AppContext.Provider value={{ api, state, update: setState }}>{children}</AppContext.Provider>;
};

const withAppContext =
	<P extends Record<string, unknown>>(
		Component: React.ComponentType<P & { appContext?: AppContextValues }>,
	): React.FC<P> =>
	(props) => <AppContext.Consumer>{(state) => <Component {...props} appContext={state} />}</AppContext.Consumer>;

export { AppContextProvider, AppContext, withAppContext };
