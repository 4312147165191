import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerValuationsToggleEvent = (expanded: boolean): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS,
		name: `dlr_valuations_${expanded ? 'expanded' : 'collapsed'}`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: `Valuations ${expanded ? 'expanded' : 'collapsed'}`,
			category: VEHICLE_DETAILS,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};
