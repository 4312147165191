import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';
import ROUTES from 'Utilities/routes';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

const { CTA_CLICKED } = SNOWPLOW_EVENTS;

interface TriggerConfirmCancelBidOrOfferCTAClickedEventParams {
	bidAmount?: string;
	errorMessage?: string;
	success: boolean;
}

export const triggerSubmitBidCTAClickedEvent = ({
	bidAmount,
	errorMessage,
	success,
}: TriggerConfirmCancelBidOrOfferCTAClickedEventParams): void => {
	const shouldShowLabel = Boolean(bidAmount || errorMessage);

	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS,
		customData: {
			button_label: `Submit bid`,
			...(shouldShowLabel && { label: success ? `${bidAmount}` : errorMessage }),
			success,
			url: ROUTES.VEHICLE_DETAILS.as(),
		},
		name: `dlr_submit_bid_button_clicked`,
		schemaName: CTA_CLICKED.SCHEMA,
		version: CTA_CLICKED.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
