import type { SellerStats, SellerStatsFromApi } from 'Types/seller';
import dayjs from 'Utilities/dayjs';

export const DEFAULT_TRADE_DEALER_STATS = {
	dealerActiveYears: 2,
	dealerCompanyActiveYears: 3,
	numberOfDealerTransactions: 50,
};

const {
	dealerActiveYears: defaultDealerActiveYears,
	dealerCompanyActiveYears: defaultDealerCompanyActiveYears,
	numberOfDealerTransactions: defaultNumberOfDealerTransactions,
} = DEFAULT_TRADE_DEALER_STATS;

export const getYearDiffForTradeSeller = (date: string): number => {
	const diffInYears = dayjs().diff(date, 'year');
	return diffInYears < 1 ? 0 : diffInYears;
};

const getSellerStats = (
	sellerStats: SellerStatsFromApi | boolean,
): NullableProps<Omit<SellerStatsFromApi, 'updatedOn'>> => {
	if (typeof sellerStats === 'boolean') {
		return {
			dealerBusinessCreatedOn: null,
			dealerCreatedOn: null,
			numberOfDealerTransactions: null,
		};
	}

	return sellerStats;
};

export const transformSellerStats = (sellerStats: SellerStatsFromApi | boolean): SellerStats => {
	const {
		dealerBusinessCreatedOn,
		dealerCreatedOn,
		numberOfDealerTransactions: dealerTransactionsCount,
	} = getSellerStats(sellerStats);

	const dealerActiveYears = dealerCreatedOn ? getYearDiffForTradeSeller(dealerCreatedOn) : defaultDealerActiveYears;
	const dealerCompanyActiveYears = dealerBusinessCreatedOn
		? getYearDiffForTradeSeller(dealerBusinessCreatedOn)
		: defaultDealerCompanyActiveYears;

	const numberOfTransactions = dealerTransactionsCount ?? defaultNumberOfDealerTransactions;

	const numberOfDealerTransactions = Math.floor(numberOfTransactions / 5) * 5;

	return {
		dealerActiveYears,
		dealerCompanyActiveYears,
		numberOfDealerTransactions,
	};
};
