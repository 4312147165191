import { useContext } from 'react';

import { AppContext } from 'Context/AppContext';

const useUser = () => {
	const {
		state: { user },
	} = useContext(AppContext);
	return user || { isLoggedOut: true };
};

export default useUser;
