export const SUV_MIN_HEIGHT = 1529;

enum BODY_TYPE {
	HATCHBACK_TYPE = 'hatchback',
	SALOON_TYPE = 'saloon',
	SUV_TYPE = 'suv',
}

export const BODY_TYPES_MAPPING: Record<string, string> = {
	'commercial vehicle': BODY_TYPE.SALOON_TYPE,
	convertible: BODY_TYPE.SALOON_TYPE,
	coupe: BODY_TYPE.SALOON_TYPE,
	estate: BODY_TYPE.SALOON_TYPE,
	hatchback: BODY_TYPE.HATCHBACK_TYPE,
	motorcycle: BODY_TYPE.HATCHBACK_TYPE,
	motorhome: BODY_TYPE.SUV_TYPE,
	mpv: BODY_TYPE.SUV_TYPE,
	saloon: BODY_TYPE.SALOON_TYPE,
	van: BODY_TYPE.SUV_TYPE,
};

/**
 * Return mapped/default body type, to be used with the damage location
 */
export const getBodyType = ({ body, height }: { body?: Nullable<string>; height: Nullable<number> }): string => {
	const bodyType = (body ?? '').toLowerCase();

	if (bodyType === BODY_TYPE.SUV_TYPE) {
		return (height || 0) >= SUV_MIN_HEIGHT ? BODY_TYPE.SUV_TYPE : BODY_TYPE.HATCHBACK_TYPE;
	}

	return BODY_TYPES_MAPPING[bodyType] || BODY_TYPE.HATCHBACK_TYPE;
};
