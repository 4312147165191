import { ErrorInfo } from 'react';

import { logger } from 'Services/logger/logger';

export const handleReactErrorBoundaryError = (error: Error, info?: ErrorInfo | string): void => {
	const scope = 'handleReactErrorBoundaryError';
	const { message } = error;
	logger.error({
		context: {
			extra: {
				info,
			},
		},
		error,
		message,
		scope,
	});
};
