export enum KycStatus {
	completeLiveOnPro = 'Complete - Live on Pro',
	kycCompleted = 'KYC completed',
	kycCompletedChangesRequested = 'KYC completed - Changes requested',
	kycStarted = 'KYC started',
	modulrApproved = 'Modulr approved',
	notStarted = 'Not started',
	requestOtpOnPro = 'OTP in Pro enabled',
	requestedToGoLive = 'Requested to go live',
	sentToModulr = 'Sent to Modulr',
	sentToModulrActionRequired = 'Sent to Modulr - Action required',
}

export enum AssociateType {
	accountManager = 'accountManager',
	director = 'director',
	shareholder = 'shareholder',
}
