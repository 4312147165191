import { formatNumberToGBPString } from './formatNumberToGBPString';

export interface NumberWithDisplayValue {
	displayValue: string;
	value: number;
}

export const createNumberWithDisplayValue = (number: number): NumberWithDisplayValue => ({
	displayValue: formatNumberToGBPString(number),
	value: number,
});
