import type { LogsEvent } from '@datadog/browser-logs';
import { datadogLogs } from '@datadog/browser-logs';
import getConfig from 'next/config';

import { beforeSendClientLog } from 'Services/logger/logger.helpers';
import type { User } from 'Types/user';
import { stringBooleanToBoolean } from 'Utilities/helpers';
import getEnvironmentName from 'Utilities/helpers/getEnvironmentName';

import { getDatadogUserFromSessionUser } from './datadog.helpers';
import type { DatadogLogsProps } from './datadog.types';

const { publicRuntimeConfig } = getConfig();

const { ENABLE_DATADOG_BROWSER_LOGS } = publicRuntimeConfig;

type BeforeSendDatadogClientLogProps = { event: LogsEvent; user?: User };

export const beforeSendDatadogClientLog = ({ event, user }: BeforeSendDatadogClientLogProps): boolean => {
	const { http } = event;
	const { status_code: statusCode } = http || {};
	return beforeSendClientLog({ statusCode, user });
};

export const initDatadogBrowserLogs = ({ release, user }: DatadogLogsProps): void => {
	const userIdentity = getDatadogUserFromSessionUser(user);
	const shouldSendToDatadog = stringBooleanToBoolean(ENABLE_DATADOG_BROWSER_LOGS);

	datadogLogs.init({
		beforeSend: (event) => beforeSendDatadogClientLog({ event, user }),
		clientToken: 'pub609c17f452c699fef6620c6368b10f0a',
		env: getEnvironmentName(),
		forwardErrorsToLogs: true,
		service: 'dealer-webapp',
		sessionSampleRate: 100,
		site: 'datadoghq.eu',
		version: release?.toLowerCase(),
	});

	datadogLogs.setUser(userIdentity);

	datadogLogs.logger.setHandler(shouldSendToDatadog ? ['http', 'console'] : ['console']);
};
