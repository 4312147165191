// eslint-disable-next-line max-classes-per-file
import router from 'next/router';

import { ACCOUNT_CATEGORIES, ACCOUNT_SLUGS } from 'Components/pages/AccountPage/AccountPage.consts';
import { ACCOUNT_DATE_RANGE_FILTER_ALLOWED_PAGES } from 'Context/AccountContext/AccountContext.consts';
import { BID_TYPES } from 'Utilities/biddingState';
import { isObject } from 'Utilities/helpers';
import {
	FOR_SALE_CATEGORY,
	highestBidAvailableStates,
	rejectedStates,
	SOLD_CATEGORY,
	TRANSPORT_ALLOWED_STATES,
	UNSOLD_CATEGORY,
} from 'Utilities/vehicleState';

import { TransportRequestedValues } from '../../types/enquiryStates';
import { VehiclePermitsCheckerBase } from '../VehiclePermitsChecker/VehiclePermitsChecker';

class AccountPermitsCheckerBase extends VehiclePermitsCheckerBase {
	constructor(props = {}) {
		super(isObject(props.vehicle) ? props.vehicle : {});

		this.slug = props.context?.slug;
		this.category = props.context?.category;
	}

	isSlug = (slug) => this.slug === slug;

	isCategory = (category) => this.category === category;

	isBidsCategory = () => this.isCategory(ACCOUNT_CATEGORIES.BIDS);

	isPurchasesCategory = () => this.isCategory(ACCOUNT_CATEGORIES.PURCHASES);

	isBidsActivePage = () => this.isBidsCategory() && this.isSlug(ACCOUNT_SLUGS.ACTIVE);

	isBidsDidntWinPage = () => this.isBidsCategory() && this.isSlug(ACCOUNT_SLUGS.DIDNT_WIN);

	isBidsUnderOfferPage = () => this.isBidsCategory() && this.isSlug(ACCOUNT_SLUGS.UNDER_OFFER);

	isPurchaseCancelledPage = () => this.isPurchasesCategory() && this.isSlug(ACCOUNT_SLUGS.CANCELLED);

	isPurchaseCompletePage = () => this.isPurchasesCategory() && this.isSlug(ACCOUNT_SLUGS.COMPLETE);
}

class AccountPermitsChecker extends AccountPermitsCheckerBase {
	get page() {
		return `${this.category}/${this.slug}`;
	}

	getMaxBidPrice = () => {
		const showVAT = this.vehicle.isVatQualifying;
		const userBid = this.vehicle.userBid.value;
		const userMaximumBid = this.vehicle.userBid.maximumBid;

		const defaultLabel = this.isPurchaseCompletePage() ? 'maxBid' : 'yourMaxBid';
		const label = this.isPurchaseCancelledPage() ? 'price' : defaultLabel;

		const variableValue = userMaximumBid || userBid;
		const value = this.isPurchaseCancelledPage() ? userBid : variableValue;

		return {
			label,
			showVAT,
			value,
		};
	};

	getSoldForPrice = () => ({ label: 'soldFor', value: this.vehicle.soldFor });

	getWinningBid = () => ({ label: 'winningBid', value: this.vehicle.winningBid });

	getBidType = () => this.vehicle.userBid.type;

	getOfferDetails = () => {
		const bidType = this.vehicle.userBid.type;
		const showVAT = this.vehicle.isVatQualifying;
		const userBid = this.vehicle.userBid.value;

		const offerLabel = this.isPurchaseCancelledPage() ? 'price' : 'yourOffer';

		const offer = { label: offerLabel, showVAT, value: userBid };
		const purchase = { label: 'boughtFor', showVAT, value: userBid };

		if (this.isPurchaseCompletePage()) {
			return purchase;
		}

		switch (bidType) {
			case BID_TYPES.PROXY: {
				return this.getMaxBidPrice();
			}
			case BID_TYPES.OFFER: {
				return offer;
			}
			default: {
				break;
			}
		}

		return offer;
	};

	displayNotYetForSale = () => this.hasVehicleState([UNSOLD_CATEGORY.UNSOLD_NO_BIDS]);

	displayNoLongerAvailable = () => this.hasVehicleState(rejectedStates);

	displayBiddingCountdown = () => {
		const hasVehicleSaleActiveState = this.hasVehicleState(FOR_SALE_CATEGORY.SALE_ACTIVE);
		const isCorrectPage = this.isBidsActivePage();

		return isCorrectPage && hasVehicleSaleActiveState;
	};

	displaySoldFor = () => this.isBidsDidntWinPage();

	displayWinningBid = () => this.isBidsDidntWinPage() && Boolean(this.vehicle.winningBid);

	displayStateTag = () =>
		[
			SOLD_CATEGORY.SOLD_CONFIRMED,
			SOLD_CATEGORY.SOLD_DOCUMENTS_REVIEWED,
			SOLD_CATEGORY.SOLD_WAIT,
			// The following categories are legacy. We want to group them under the Checks complete label
			SOLD_CATEGORY.SOLD_INVOICE_PAID,
			SOLD_CATEGORY.SOLD,
			SOLD_CATEGORY.SOLD_INVOICE_RAISED,
		].includes(this.vehicle?.state?.slug);

	displayTransportRequestedTag = () => {
		const isTransportAllowedState = TRANSPORT_ALLOWED_STATES.includes(this.vehicle?.state?.slug);
		const isTransportRequested = this.vehicle.state.transportRequested === TransportRequestedValues.REQUESTED;

		return isTransportAllowedState && isTransportRequested;
	};

	displayMaxBid = () => {
		const bidType = this.vehicle.userBid.type;
		const isProxyBid = bidType === BID_TYPES.PROXY;

		return isProxyBid && this.isPurchaseCompletePage();
	};

	displayHighestBid = () => {
		const hasVehicleBiddingAllowedState = this.hasVehicleState(highestBidAvailableStates);

		return hasVehicleBiddingAllowedState && !!this.vehicle.highestBid;
	};

	displayCancelledLabel = () => this.isPurchaseCancelledPage();

	displayDataRangeFilter = () => ACCOUNT_DATE_RANGE_FILTER_ALLOWED_PAGES[this.category].includes(this.slug);

	isAccountPage = () => {
		if (this.category && this.slug) {
			return true;
		}

		return typeof window === 'object' && !!router?.query?.slug;
	};

	getInitialCategoryAndSlug = () => {
		if (this.category && this.slug) {
			return { category: this.category, slug: this.slug };
		}

		if (this.isAccountPage()) {
			const {
				query: { category, slug },
			} = router;

			if (category && slug) {
				return { category, slug };
			}
		}

		return {
			category: null,
			slug: null,
		};
	};

	displayMoreMenu = () => {
		const isSoldAwaiting = this.hasVehicleState(SOLD_CATEGORY.SOLD_WAIT);

		return this.isPurchaseCompletePage() && !isSoldAwaiting;
	};
}

export default AccountPermitsChecker;
