export const BIDDING_STATES = Object.freeze({
	ACCEPTED: 'Accepted',
	CANCELLED: 'Cancelled',
	EXPIRED: 'Expired',
	PENDING: 'Pending',
	REJECTED: 'Rejected',
});

export const BID_TYPES = Object.freeze({
	OFFER: 'offer',
	PROXY: 'proxy',
});

// Bids with states which are included in active bids count calculation
export const BIDS_COUNT_ALLOWED_STATES = Object.freeze([
	BIDDING_STATES.ACCEPTED,
	BIDDING_STATES.REJECTED,
	BIDDING_STATES.PENDING,
]);

// Bids with states which are allowed to be shown in PRO
export const BIDS_ALLOWED_STATES = Object.freeze([BIDDING_STATES.ACCEPTED, BIDDING_STATES.PENDING]);
