import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const {
	MARKETPLACE: { GALLERY },
} = ANALYTICS_CATEGORIES;

export const triggerHeroCarouselImageViewedEvent = (imageKind: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: GALLERY,
		customData: { label: imageKind },
		name: 'dlr_hero_carousel_image_viewed',
		schemaName: SNOWPLOW_EVENTS.LOAD.SCHEMA,
		version: SNOWPLOW_EVENTS.LOAD.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
