import { useCallback, useEffect, useRef, useState } from 'react';

import { isFunction } from '../helpers';

// eslint-disable-next-line max-len
export const useFullState = (initialState) => {
	// https://reactjs.org/docs/hooks-reference.html#functional-updates <-- yellow note
	const [masterState, setMasterState] = useState({
		callback: null,
		state: initialState,
	});

	const setState = useCallback((nextState, callback) => {
		setMasterState(({ state: prevState }) => ({
			callback,
			state: {
				...prevState,
				...(isFunction(nextState) ? nextState(prevState) : nextState),
			},
		}));
	}, []);

	useEffect(() => {
		if (isFunction(masterState.callback)) {
			masterState.callback(masterState.state);
		}
	}, [masterState]);

	return [masterState.state, setState];
};

export const useInterval = (updater, frequency, { runInterval } = {}) => {
	useEffect(() => {
		let id = null;

		if (runInterval) {
			id = setInterval(updater, frequency);
		}

		return () => clearInterval(id);
	}, [updater, frequency, runInterval]);
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const useNamedRef = ([_name]) => useRef;
