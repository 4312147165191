import React from 'react';

import { handleReactErrorBoundaryError } from 'Utilities/error/handleReactErrorBoundaryError';

type ErrorFallbackContentProps = {
	windowError: Error;
};

const ErrorFallbackContent: React.FC<ErrorFallbackContentProps> = ({ windowError }: ErrorFallbackContentProps) => {
	const handleReportError = () => {
		const defaultError = new Error('Error from handleReportError');
		const error = windowError ?? defaultError;
		const info = windowError?.stack ?? defaultError?.stack;
		handleReactErrorBoundaryError(error, info);
	};
	return (
		<section>
			<h1>There was an error!</h1>
			<p>
				<button onClick={handleReportError} type="button">
					<span aria-label="Megaphone emoji">📣</span>
					Report this error
				</button>
			</p>
			<p>
				<button
					onClick={() => {
						window.location.reload();
					}}
					type="button"
				>
					Or, try reloading the page
				</button>
			</p>
		</section>
	);
};

export default ErrorFallbackContent;
