import { logger } from 'Services/logger/logger';

import { isProd } from '../../utilities/helpers';

const alertUnrecognisedToggle = ({ key, value }, message) => {
	logger.warn({
		context: {
			extra: {
				toggleName: key,
				toggleValue: value,
			},
		},
		message,
		scope: 'alertUnrecognisedToggle',
	});

	if (!isProd) {
		// eslint-disable-next-line no-console
		console.error(message);
	}
};

export default alertUnrecognisedToggle;
