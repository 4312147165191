import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const {
	MARKETPLACE: { SORTING },
} = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

export const triggerSortDropdownExpandedEvent = (): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: SORTING,
				name: 'dlr_sort_dropdown_expanded',
				schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
				version: SNOWPLOW_EVENTS.CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
		{
			eventPayload: {
				action: 'Sort dropdown expanded',
				category: SORTING,
				isLegacyEvent: false,
				label: '',
			},
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
