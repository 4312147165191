import isomorphicApi from '../../utilities/helpers/isomorphicApi';
import axios from '../axios';

export const getSavedFilters = async (ctx) =>
	isomorphicApi(ctx, {
		browser: async () => axios.get('/api/saved-filters'),
		server: async (api) => {
			const savedSearches = await api.getSavedFilters(ctx.req.user);
			return savedSearches;
		},
	});

export const createSavedFilter = async (params, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const { payload } = params;
			return axios.post('/api/saved-filters/create', payload);
		},
		server: async (api) => {
			const { payload } = params;
			const savedSearch = await api.createSavedFilter(payload);

			return savedSearch;
		},
	});

export const updateSavedFilter = async (params, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const { payload } = params;
			return axios.post(`/api/saved-filters/${payload?.id}/update`, payload);
		},
		server: async (api) => {
			const { payload } = params;
			const savedSearch = await api.updateSavedFilter(payload);

			return savedSearch;
		},
	});

export const deleteSavedFilter = async (params, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const { id } = params;
			return axios.post(`/api/saved-filters/${id}/delete`);
		},
		server: async (api) => {
			const { id } = params;
			const savedSearch = await api.deleteSavedFilter(id);

			return savedSearch;
		},
	});
