export enum Order {
	Ascending = 'ASC',
	Descending = 'DESC',
}

export enum HorizontalAlignment {
	Left = 'left',
	Right = 'right',
}

export enum RouterEvents {
	BEFORE_HISTORY_CHANGE = 'beforeHistoryChange',
	HASH_CHANGE_COMPLETE = 'hashChangeComplete',
	HASH_CHANGE_START = 'hashChangeStart',
	ROUTE_CHANGE_COMPLETE = 'routeChangeComplete',
	ROUTE_CHANGE_ERROR = 'routeChangeError',
	ROUTE_CHANGE_START = 'routeChangeStart',
}
