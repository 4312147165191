import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { detect } from 'detect-browser';
import { Request } from 'express';
import { IncomingMessage } from 'http';
import Cookies from 'js-cookie';
import camelCase from 'lodash/camelCase';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import transform from 'lodash/transform';
import Router from 'next/router';
import { NextRequest } from 'next/server';

import typeHelpers from '@motorway/motorway-tools/typeHelpers';
import { DataAttributes } from '@motorway/mw-highway-code/src/types';

import { SaleTimes } from 'Types/saleTimes';

import { USER_SESSION_BASED_FEATURES } from '../../contexts/FeaturesContext/FeaturesContextConsts';
import { DEFAULT_LANGUAGE } from '../consts';
import { LIST_SESSIONSTORAGE_POSITION_KEY, LIST_SESSIONSTORAGE_STATE_KEY } from '../listPosition';

import { ObjectDiffProps, ObjectUtilsProps } from './index.types';
import isProd from './isProd';
import stringBooleanToBoolean from './stringBooleanToBoolean';

export { default as isProd } from './isProd';
export { default as stringBooleanToBoolean } from './stringBooleanToBoolean';

export const {
	isArray,
	isBoolean,
	isEmpty,
	isEmptyArray,
	isEmptyObject,
	isEmptyString,
	isFunction,
	isNil,
	isNull,
	isNumber,
	isObject,
	isStrictEmptyObject,
	isStrictObject,
	isString,
	isSymbol,
	isUndefined,
} = typeHelpers;

export const isDev = process.env.NODE_ENV === 'development';

export const isTestEnv = process.env.NODE_ENV === 'test';

export const isE2ETestEnv = (): boolean => typeof window !== 'undefined' && !!window.Cypress;

export const isIterable = <T>(object: AsyncIterable<T> & Iterable<T>): boolean =>
	isObject(object) && (isFunction(object[Symbol.iterator]) || isFunction(object[Symbol.asyncIterator]));

/**
 * Function to determinate is value is falsy for FE, so when value is `null`, `undefined`, `""` or `false`.
 * `0` is valid value, this is why we need this check.
 */
export const isFalseButNotZero = (v: unknown): boolean => !v && v !== 0;

export const isFalse = (v: boolean): boolean => v === false;

export const getSlug = (asPath: string): string => asPath.replace('/', '').split(/[?#]/)[0];

export const getWindowScrollY = (): number => {
	// scrollY is not available on IE.11, use pageYOffset instead
	if (window !== undefined) {
		return window.scrollY || window.pageYOffset;
	}
	return 0;
};

export const scrollIntoView = <T extends Element | HTMLElement>(element: T, options?: ScrollIntoViewOptions): void => {
	if (!element) {
		return undefined;
	}

	// @ts-expect-error mute error
	const withOptions = 'scrollBehavior' in document.documentElement.style || window['smoothscroll-polyfill'];

	if (element.getBoundingClientRect().top > 0) {
		return undefined;
	}

	/**
	 * Use the optional chaining operator here as it fails the tests with
	 * TypeError: element.scrollIntoView is not a function in test environment
	 */
	if (withOptions) {
		return element.scrollIntoView?.(options);
	}

	return element.scrollIntoView?.();
};

export const getObjectValue = (object = {}, path = ''): string | undefined => {
	const [currentProp, ...restProps] = (Array.isArray(path) ? path : path.split('.')).filter((v) => !isEmpty(v));

	if (!isObject(object)) {
		return undefined;
	}

	// @ts-expect-error mute error
	const resolvedObject = object[currentProp];

	if (isEmptyArray(restProps)) {
		return resolvedObject;
	}

	// @ts-expect-error mute error
	return getObjectValue(resolvedObject, restProps);
};

export const postcodeValidate = (value: string): boolean => {
	// eslint-disable-next-line prefer-regex-literals
	const re = new RegExp(
		// eslint-disable-next-line regexp/no-unused-capturing-group
		/^(GIR 0A{2})|((([A-Z]\d{1,2})|(([A-Z][A-HJ-Y]\d{1,2})|(([a-z]\d[A-Z])|([A-Z][A-HJ-Y]\d?[A-Z]))))\s?\d[A-Z]{2})$/i,
	);
	const match = value.match(re);
	if (!match || value !== match[0]) {
		return false;
	}
	return true;
};

export const websiteValidate = (value: string): boolean => {
	// eslint-disable-next-line regexp/no-unused-capturing-group
	const re = /^(https?:\/\/(www\.)?)?[a-z\d]+([-.][a-z\d]+)*\.[a-z]{2,16}(:\d{1,5})?(\/.*)?$/i;
	return re.test(value);
};

export const isValidEmail = (value: string): boolean =>
	// eslint-disable-next-line no-control-regex
	/^(?:[a-z\d!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z\d!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\v\f\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\v\f\x0e-\x7f])*")@(?:(?:[a-z\d](?:[a-z\d-]*[a-z\d])?\.)+[a-z\d](?:[a-z\d-]*[a-z\d])?|\[(?:(?:25[0-5]|2[0-4]\d|[01]?\d{1,2})\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d{1,2}|[a-z\d-]*[a-z\d]:(?:[\x01-\x08\v\f\x0e-\x1f\x21-\x7f]|\\[\x01-\x09\v\f\x0e-\x7f])+)\])$/.test(
		value,
	);

export const numberWithCommas = (value: string | number): string | number => {
	const number = isNumber(value) ? value : parseFloat(value?.toString().replace(/,/g, ''));
	return value && !Number.isNaN(number) ? number.toLocaleString() : value;
};

export const getIsomorphicCookie = (cookieName: string, req?: Request | NextRequest | IncomingMessage): string =>
	req?.cookies[cookieName] || Cookies.get(cookieName);

export const getMockedDateTimeFromCookie = (mockDateTimeCookieName: string): Date =>
	dayjs(Cookies.get(mockDateTimeCookieName)).toDate();

export const getCachedState = (key: string): Nullable<string> => {
	try {
		return JSON.parse(sessionStorage.getItem(key) as string);
	} catch (e) {
		return null;
	}
};

export const setCachedState = (key: string, payload: unknown): Nullable<void> => {
	try {
		return sessionStorage.setItem(key, JSON.stringify(payload));
	} catch (e) {
		return null;
	}
};

export const removeCache = (key: string): void => sessionStorage.removeItem(key);

export const clearCachedState = (): Nullable<void> => {
	try {
		USER_SESSION_BASED_FEATURES.forEach((feature) => Cookies.remove(feature));

		return sessionStorage.clear();
	} catch (e) {
		return null;
	}
};

export const clearCachedListState = (): Nullable<void> => {
	try {
		removeCache(LIST_SESSIONSTORAGE_POSITION_KEY);
		removeCache(LIST_SESSIONSTORAGE_STATE_KEY);
	} catch (e) {} // eslint-disable-line no-empty

	return null;
};

export const intersection = (arr1: unknown[], arr2: unknown[]): unknown[] => arr1.filter((x) => arr2.includes(x));
export const difference = (arr1: unknown[], arr2: unknown[]): unknown[] => arr1.filter((x) => !arr2.includes(x));
export const filterValue = (values: unknown[], value: unknown): unknown[] => values.filter((x) => x !== value);

export const resolveServerTime = (
	countdownEnd: Nullable<string>,
	millisecondsRemaining: SaleTimes['millisecondsRemaining'],
): Nullable<Dayjs> => {
	const time = dayjs(countdownEnd).subtract(Math.floor(millisecondsRemaining / 1000), 'seconds');
	return time.isValid() ? time : null;
};

export const isSSR = (): boolean => typeof window === 'undefined';

export const isIE = (userAgent = ''): boolean =>
	(!isSSR() && /* @cc_on!@ */ false) ||
	// @ts-expect-error mute documentMode does not exist on document
	(!isSSR() && !!document.documentMode) ||
	(isSSR() && /Trident\/|MSIE/.test(userAgent));

export const getScrollbarWidth = (): number => (!isSSR() ? window.innerWidth - document.body.clientWidth : 0);

export const valuesAreDefined = <T>(obj: T, values: (keyof T)[]): boolean =>
	values.every((value) => obj[value] !== undefined);

export const useCoarsePointer = (): boolean => !isSSR() && window.matchMedia('(pointer: coarse)').matches;

// IE11 safe implementation of URLSearchParams
export const getUrlParameterByName = (name: string, url = window.location.href): Nullable<string> => {
	const results = new RegExp(`[?&]${name.replace(/[[\]]/g, '\\$&')}(=([^&#]*)|&|#|$)`).exec(url);
	if (!results) {
		return null;
	}
	return !results[2] ? '' : decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const objectDiff = ({ base, ignoreArrayOrder, object }: ObjectDiffProps): Record<string, unknown> =>
	transform(object, (result, value, key) => {
		const handleArraySorting = (val: unknown[]) => (isArray(val) && ignoreArrayOrder ? val.slice().sort() : val);
		const nextValue = handleArraySorting(value);
		const nextComparison = handleArraySorting(base[key]);

		if (!isEqual(nextValue, nextComparison)) {
			// @ts-expect-error mute error on objectDiff function requiring single argument
			result[key] = isObject(value) && isObject(base[key]) ? objectDiff(value, base[key]) : value;
		}
	});

export const splitCamelCase = (string: string): string => string.replace(/([a-z])([A-Z])/g, '$1 $2');

export const isTrue = (val: string): boolean => stringBooleanToBoolean(String(val));

export const pascalCase = (str: string): string => startCase(camelCase(str)).replace(/ /g, '');

export const capitaliseSentence = (word = ' '): string => word[0].toUpperCase() + word.slice(1);

export const hasDateRangeTypeInPath = (path = ''): boolean => Boolean(path.includes('dateRangeType'));

export const isFirefox = (): boolean => detect()?.name === 'firefox';

export const applyTestData = <T extends UnknownShapeObject>(data: T): DataAttributes => {
	if (isProd || !data) {
		return {};
	}

	return Object.keys(data).reduce((acc, key) => ({ ...acc, [`data-${key}`]: data[key] }), {});
};

export const applyCypressData = (name?: string): Record<string, string | undefined> =>
	isProd ? {} : { 'data-cy': name };

export const getRandomItem = <T>(items: T[]): T => items[Math.floor(Math.random() * items.length)];

export const getObjectIndex = ({ ignoreArrayOrder, ignoreFields, object, otherObjects }: ObjectUtilsProps): number =>
	otherObjects.findIndex((obj) => {
		const base = omit(object, ignoreFields);
		const other = omit(obj, ignoreFields);
		const diff = objectDiff({ base, ignoreArrayOrder, object: other });
		return isEmpty(diff);
	});

export const isObjectUnique = ({ ignoreArrayOrder, ignoreFields, object, otherObjects }: ObjectUtilsProps): boolean => {
	const objectIndex = getObjectIndex({
		ignoreArrayOrder,
		ignoreFields,
		object,
		otherObjects,
	});
	return objectIndex === -1;
};

// @ts-expect-error mute error
export const getAxiosResponseStatus = (error: AxiosError): number => parseInt(error?.response?.status);

export const getCookieAsBoolean = (feature: string, req: Request): boolean =>
	stringBooleanToBoolean(getIsomorphicCookie(feature, req));

export const sortByDate =
	<T extends Record<string, string>>(property: keyof T) =>
	(a: T, b: T): number => {
		const dateA = dayjs(a[property]);
		const dateB = dayjs(b[property]);
		if (dateA.isBefore(dateB)) {
			return -1;
		}
		if (dateA.isAfter(dateB)) {
			return 1;
		}
		return 0;
	};

export const getBrowserLang = (): string => {
	if (isSSR()) {
		return DEFAULT_LANGUAGE;
	}

	return navigator.languages?.[0] || navigator.language;
};

export const upperSnakeCase = (str: string): string => snakeCase(str).toUpperCase();

export const isPopupBlocked = (newWindow: Nullable<Window>): boolean =>
	!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined';

export const openInNewTabOrForward = (link: string): void => {
	const newWindow = window?.open(link, '_blank');
	if (isPopupBlocked(newWindow)) {
		void Router.push(link);
	}
};
