import type { GetVehicle } from '../helpers/vehicle.schema';
import { getYesNoDashValue } from '../helpers/vehicle.schema.helpers';

export const wheelsAndTyresTransformer = (vehicle: GetVehicle) => ({
	hasLockingWheelNut: getYesNoDashValue(vehicle.hasLockingWheelNut),
	hasScuffedAlloy: getYesNoDashValue(vehicle.hasScuffedAlloy),
	hasToolsInBoot: getYesNoDashValue(vehicle.hasToolsInBoot),
	hasTyreProblems: getYesNoDashValue(vehicle.hasTyreProblems),
	scuffedAlloyDesc: vehicle.scuffedAlloyDesc,
	tyreProblemsDesc: vehicle.tyreProblemsDesc,
});
