const fromPairs = require('lodash/fromPairs');
const { VEHICLE_BATTERY_OWNERSHIP, VEHICLE_CHARGING_CABLES } = require('../../../utilities/consts');

const { isArray, isNil, isNumber } = require('../../../utilities/helpers');
const { formatDate, formatText } = require('../../../utilities/formatters');
const { BIDDING_STATES } = require('../../../utilities/biddingState');
const { SOLD_CATEGORY } = require('../../../utilities/vehicleState');
const { VehiclePermitsCheckerBase } = require('../../../controllers/VehiclePermitsChecker/VehiclePermitsChecker');

const texts = require('../../../components/texts.json');

const EQUIPMENT_CONST = {
	NO_VAT: 'no_vat',
	PARKING_CAM: 'parking_cam',
	SOUND_SYSTEM: 'sound_system',
	VAT_QUALIFYING: 'vat_qualifying',
};

export const NO_VALUE = '-';
export const HIDDEN_VALUE = 'Hidden';
export const EXCLUSIVE_TO_TRADE = 'Trade';

export const hide = (keys) => fromPairs(keys.map((key) => [key, HIDDEN_VALUE]));

// Get rounded distance
export const getDistance = (distance) => {
	if (isNumber(distance)) {
		return Math.round(distance);
	}
	return null;
};

/** LEGACY PROXY BIDDING START */
export const getVehicleSoldForPrice = (vehicle, bids) => {
	const permits = new VehiclePermitsCheckerBase(vehicle);
	const isVehicleSold = permits.hasVehicleCategory(SOLD_CATEGORY.name);

	if (!isVehicleSold) {
		return null;
	}

	return (bids?.find((b) => b.state === BIDDING_STATES.ACCEPTED) || {}).value || null;
};
/** LEGACY PROXY BIDDING END */

export const getVehicleEquipment = ({ equipment = '' }) => {
	const specialCasesEq = Object.values(EQUIPMENT_CONST);

	return equipment
		.split(',')
		.filter((v, i, a) => a.indexOf(v) === i) // filter out dupes
		.map((eq) => {
			if (specialCasesEq.includes(eq)) {
				return texts.equipment[eq];
			}

			return formatText(eq);
		})
		.filter((v) => v);
};

export const getIsTrade = (vehicle) => vehicle.exclusiveToDealer === EXCLUSIVE_TO_TRADE;

export const getVehiclePreviewImages = (images, kindPrefix) =>
	!isArray(images) ? [] : images.filter((image) => image.kind.startsWith(kindPrefix));

export const getVehicleChargingCable = (value, consts = VEHICLE_CHARGING_CABLES) => {
	const chargingCableTypesConfig = { '3PinDomestic': consts.THREE_PIN_DOMESTIC, fast: consts.FAST_CHARGING };
	return value
		? value
				.map((item) => chargingCableTypesConfig[item])
				.filter((item) => item)
				.sort()
				.join(', ')
		: null;
};

export const getVehicleBatteryOwnership = (value, consts = VEHICLE_BATTERY_OWNERSHIP) => {
	const batteryOwnership = value ? consts.LEASED : consts.OWNED;
	return isNil(value) ? null : batteryOwnership;
};

export const formatAsYesNoOrEmpty = (value) => (isNil(value) ? null : formatText(Boolean(value)));

export const getVehicleHistoryChecks = (vehicle = {}) => {
	const { identifierIssues = [] } = vehicle;
	const {
		ancestry = [],
		fetchedAt,
		financeAgreements = [],
		hasBeenStolen = false,
		hasBeenWrittenOffByDamage = false,
		hasBeenWrittenOffByTheft = false,
		hasSecurityWatch = false,
		import: imported = {},
		isMileageClocked = false,
		isPlateTransferred = false,
		mot = {},
		salvageAuctions = [],
	} = vehicle?.limitations || {};

	const taxiRecords = ancestry?.find(({ recordType }) => recordType?.toLowerCase() === 'taxi');
	const { dateFrom, dateTo, source } = taxiRecords || {};

	const taxiUse = taxiRecords ? { dateFrom, dateTo, source } : {};
	const financeAgreementsCount = financeAgreements?.length || 0;
	const motExpiryDate = mot?.isInvalid ? mot?.expiry : '';
	const hasBeenImported = Boolean(imported?.isImported);
	const hasBeenSalvaged = Boolean(salvageAuctions?.length);
	const hasIdentifierIssues = Array.isArray(identifierIssues) && identifierIssues?.length;
	const isVehicleValid = !hasIdentifierIssues;
	const completedOnDate = formatDate(fetchedAt);

	return {
		completedOnDate,
		financeAgreementsCount,
		hasBeenImported,
		hasBeenSalvaged,
		hasBeenStolen,
		hasBeenWrittenOffByDamage,
		hasBeenWrittenOffByTheft,
		hasSecurityWatch,
		isMileageClocked,
		isPlateTransferred,
		isVehicleValid,
		motExpiryDate,
		taxiUse,
	};
};
