import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { EXTERIOR_GRADING } = ANALYTICS_CATEGORIES;

const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

const { CLICK } = SNOWPLOW_EVENTS;

export const triggerGradingModalOpenedEvent = (vehicleId: number): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: EXTERIOR_GRADING,
				customData: { label: `${vehicleId}` },
				name: 'dlr_grading_modal_opened',
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
		{
			eventPayload: {
				action: 'Grading modal opened',
				category: EXTERIOR_GRADING,
				isLegacyEvent: false,
				label: vehicleId,
			},
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
