import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { ADVISORY } = ANALYTICS_CATEGORIES;

export const triggerAdvisoryDetailsModalClosedEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ADVISORY,
		name: 'dlr_advisory_details_modal_closed',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
