import { z } from 'zod';

export const GetWheelsAndTyresSchema = z.object({
	hasLockingWheelNut: z.boolean().nullable(),
	hasScuffedAlloy: z.boolean().nullable(),
	hasToolsInBoot: z.boolean().nullable(),
	hasTyreProblems: z.boolean().nullable(),
	scuffedAlloyDesc: z.string().nullable(),
	tyreProblemsDesc: z.string().nullable(),
});
