import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const {
	MARKETPLACE: { SORTING },
} = ANALYTICS_CATEGORIES;

export const triggerVehicleListSortLoadedEvent = (sort: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: SORTING,
		customData: { label: sort },
		name: 'dlr_vehicles_loaded_sort',
		schemaName: SNOWPLOW_EVENTS.LOAD.SCHEMA,
		version: SNOWPLOW_EVENTS.LOAD.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
