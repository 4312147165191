import { AxiosError } from 'axios';

import { logger } from 'Services/logger/logger';

export type FetchWithExceptionLoggerProps<T> = {
	callback: () => Promise<T>;
	defaultReturn?: T;
	errorMessage: string;
	extra?: Record<string, unknown>;
	ignoredResponses?: number[];
	isWarning?: boolean;
};

export const fetchWithExceptionLogger = async <T>({
	callback,
	defaultReturn = [] as T,
	errorMessage,
	extra = {},
	ignoredResponses = [],
	isWarning = false,
}: FetchWithExceptionLoggerProps<T>): Promise<T> => {
	try {
		return await callback();
	} catch (error: unknown) {
		const status = (error as AxiosError)?.response?.status;
		const logLevel = isWarning ? 'warn' : 'error';

		if (status && ignoredResponses.includes(status)) {
			return defaultReturn;
		}

		logger[logLevel]({
			context: { extra: { error, ...extra } },
			error: new Error(errorMessage),
			message: errorMessage,
			scope: 'fetchWithExceptionLogger',
		});
	}
	return defaultReturn;
};
