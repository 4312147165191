import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { BIDDING } = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

interface TriggerBidTypeCancelErrorEventParams {
	bidType: string;
	errorMessage: string;
}

export const triggerBidTypeCancelErrorEvent = ({
	bidType,
	errorMessage,
}: TriggerBidTypeCancelErrorEventParams): void => {
	const eventPayload = getSnowplowEventPayload({
		category: BIDDING,
		customData: { label: errorMessage },
		name: `dlr_cancel_${bidType}_error`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: `Cancel ${bidType} error`,
			category: BIDDING,
			isLegacyEvent: false,
			label: errorMessage,
		},
		provider: GOOGLE_ANALYTICS,
	});
};
