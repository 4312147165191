const pick = require('lodash/pick');
const get = require('lodash/get');
const has = require('lodash/has');

const { transformSellerStats } = require('../../../utilities/helpers/transformSellerStats');
const { getYearFromDate } = require('../../../utilities/formatters');
const texts = require('../../../components/texts.json');
const { getHighestBid } = require('./bids');
const { BIDS_ALLOWED_STATES } = require('../../../utilities/biddingState');
const {
	formatAsYesNoOrEmpty,
	getDistance,
	getIsTrade,
	getVehicleBatteryOwnership,
	getVehicleChargingCable,
	getVehicleEquipment,
	getVehicleHistoryChecks,
	getVehiclePreviewImages,
	getVehicleSoldForPrice,
	HIDDEN_VALUE,
	hide,
	NO_VALUE,
} = require('./vehicles.helpers');

const vehicleForList = (vehicle) => {
	const state = pick(vehicle.state, ['name', 'id', 'slug', 'category']);
	const distance = getDistance(vehicle.distance);

	return {
		distance,
		exteriorGrade: vehicle.grade,
		fuel: vehicle.spec.fuel || NO_VALUE,
		isTradeSeller: vehicle.isTrade,
		make: vehicle.spec.make,
		model: vehicle.spec.model,
		state,
		transmission: vehicle.spec.transmission || NO_VALUE,
		year: getYearFromDate(vehicle.spec.firstRegistered),
		...pick(vehicle, [
			'displayPrice',
			'id',
			'mileage',
			'leadPhoto',
			'prettyVrm',
			'isVatQualifying',
			'sellerWillAcceptPrice',
			'isTrade',
		]),

		highestBid: getHighestBid(vehicle, vehicle.bids),

		/** LEGACY RESERVE PRICE START */
		priceHigh: vehicle.priceHigh,
		/** LEGACY RESERVE PRICE END */
		/* -------------------------- */
		/** LEGACY PROXY BIDDING START */
		soldFor: getVehicleSoldForPrice(vehicle, vehicle.bids),
		/** LEGACY PROXY BIDDING END */
	};
};

const vehicleDetails = (vehicle, { dealerId, id } = {}) => {
	const additionalSpecTotalCost = vehicle?.additionalSpecV2?.totalCost;

	return {
		additionalSpecV2: {
			items:
				vehicle?.additionalSpecV2?.items.map((item) => ({
					name: item.name,
					price: Number(item.price),
				})) ?? [],
			totalCost: Number(additionalSpecTotalCost) || additionalSpecTotalCost,
		},
		bids: {
			activeBidCount: vehicle?.bids?.activeBidCount,
			usersBids: vehicle?.bids?.usersBids.map((bid) => pick(bid, 'id', 'value', 'dealerContact.id')),
		},
		equipment: vehicle?.equipment || '',
		equipmentV2: getVehicleEquipment(vehicle),
		hasOtherContactActiveBid: vehicle.allBids?.some(
			(bid) => bid.dealer.id === dealerId && bid.dealerContact.id !== id && BIDS_ALLOWED_STATES.includes(bid.state),
		),
		historyChecks: getVehicleHistoryChecks(vehicle),
		isTrade: getIsTrade(vehicle), // NOTE: `isTrade` is a legacy value that is used for pen testing etc.
		isTradeSeller: vehicle.isTrade, // NOTE: This is the value for `isTrade` from the API response.
		seller: {
			...pick(vehicle.seller, ['town', 'area', 'country', 'latitude', 'longitude', 'postcode']),
			distance: getDistance(vehicle.distance, vehicle.state.slug),
			sellerStats: vehicle.seller?.sellerStats ? transformSellerStats(vehicle.seller?.sellerStats) : false,
		},
		spec: {
			...vehicle.spec,
			batteryOwnership: getVehicleBatteryOwnership(vehicle.areBatteriesInLease),
			chargingCable: getVehicleChargingCable(vehicle.chargingCables),
			fuel: vehicle.spec.fuel || NO_VALUE,
			transmission: vehicle.spec.transmission || NO_VALUE,
		},
		state: pick(vehicle.state, ['name', 'id', 'slug', 'category']),
		year: getYearFromDate(vehicle.spec.firstRegistered),
		...pick(vehicle, [
			'additionalDetails',
			'additionalSpec',
			'availableFrom',
			'availableUntil',
			'dentsDesc',
			'displayPrice',
			'financeFigure',
			'hasDents',
			'hasLockingWheelNut',
			'hasMissingParts',
			'hasNeverBeenInappropriatelyUsed',
			'hasPaintProblems',
			'hasPrivatePlate',
			'hasScratches',
			'hasScuffedAlloy',
			'hasServiceRecords',
			'hasToolsInBoot',
			'hasTyreProblems',
			'hasWarningLights',
			'hasWindscreenProblems',
			'initialValuation',
			'id',
			'independentServiceStampsCount',
			'identifierIssues',
			'isOwnedByUserDealership',
			'isVatQualifying',
			'isVehicleOnFinance',
			'keepPrivatePlate',
			'keeperStartDate',
			'keepersList',
			'keysCount',
			'lastMOTDate',
			'lastMOTExpiry',
			'mileage',
			'mileageRecords',
			'minimumBid',
			'missingPartsDesc',
			'motData',
			'officialServiceStampsCount',
			'paintProblemsDesc',
			'prettyVrm',
			'previousKeepersCount',
			'priceAverageCondition',
			'priceGoodCondition',
			'priceRetail',
			'priceSource',
			'priceUpdatedAt',
			'scratchesDesc',
			'scuffedAlloyDesc',
			'sellerWillAcceptPrice',
			'serviceHistory',
			'serviceRecord',
			'soldDate',
			'tyreProblemsDesc',
			'vehicleImages',
			'vrm',
			'warningLightsDesc',
			'windscreenProblemsDesc',
			'prepCosts',
			'isOwnedByUserDealership',
			'serviceHistoryRecords',
		]),
		exteriorGrade: vehicle.grade,

		highestBid: getHighestBid(vehicle, vehicle.allBids),

		/** LEGACY RESERVE PRICE START */
		priceHigh: vehicle.priceHigh,
		/** LEGACY RESERVE PRICE END */
		/* -------------------------- */
		/** LEGACY PROXY BIDDING START */
		soldFor: getVehicleSoldForPrice(vehicle, vehicle.allBids),
		/** LEGACY PROXY BIDDING END */
		// LEGACY DAMAGE LOCATION START
		...pick(vehicle, [
			'hasACProblems',
			'ACProblemsDesc',
			'hasElectricsProblems',
			'electricsProblemsDesc',
			'interiorType',
		]),
		// LEGACY DAMAGE LOCATION END
		hasAdditionalDamage: Boolean(vehicle.hasAdditionalDamage),
		hasBeenSmokedIn: formatAsYesNoOrEmpty(vehicle.hasBeenSmokedIn),
	};
};

const vehicleDetailsSneakPeek = (vehicle) => ({
	additionalSpec: has(vehicle, 'additionalSpec') ? texts.preview.additionalSpec : '',
	availableFrom: '1900-01-01T00:00:00.000Z',
	availableUntil: '1900-01-01T00:00:00.000Z',
	equipment: get(vehicle, 'equipment', '')
		.split(',')
		.map((_, i) => `hidden_eq_${i + 1}`)
		.join(','),
	equipmentV2: get(vehicle, 'equipment', '')
		.split(',')
		.filter((v) => v)
		.map((_, i) => `${HIDDEN_VALUE}_${i + 1}`),
	hasOtherContactActiveBid: false,
	isTrade: getIsTrade(vehicle),
	isTradeSeller: vehicle.isTrade,
	keeperStartDate: '1900-01-01T00:00:00.000Z',
	lastMOTDate: '1900-01-01T00:00:00.000Z',
	prettyVrm: 'MW PRO',
	previewDamageImages: getVehiclePreviewImages(vehicle.vehicleImages, 'damage'),
	priceUpdatedAt: '1900-01-01T00:00:00.000Z',
	spec: {
		firstRegistered: '1900-01-01T00:00:00.000Z',
		...hide(['colour', 'body', 'engineSize']),
		...pick(vehicle.spec, ['make', 'model', 'fuel', 'transmission']),
	},
	state: pick(vehicle.state, ['name', 'id', 'slug', 'category']),
	vehicleImages: vehicle.vehicleImages.slice(0, 1),
	vrm: 'MWPRO',
	wheelsAndTyresDamageImages: [
		...getVehiclePreviewImages(vehicle.vehicleImages, 'tyre_tread'),
		...getVehiclePreviewImages(vehicle.vehicleImages, 'wheels'),
	],
	year: getYearFromDate(vehicle.spec.firstRegistered),
	...pick(vehicle, ['id', 'soldDate', 'mileage']),
	...hide([
		'additionalDetails',
		'dentsDesc',
		'hasDents',
		'hasLockingWheelNut',
		'hasMissingParts',
		'hasPaintProblems',
		'hasPrivatePlate',
		'hasScratches',
		'hasScuffedAlloy',
		'hasServiceRecords',
		'hasToolsInBoot',
		'hasWarningLights',
		'hasWindscreenProblems',
		'independentServiceStampsCount',
		'interiorType',
		'isVATQualifying',
		'isVehicleOnFinance',
		'keysCount',
		'missingPartsDesc',
		'officialServiceStampsCount',
		'paintProblemsDesc',
		'previousKeepersCount',
		'priceAverageCondition',
		'priceGoodCondition',
		'priceRetail',
		'priceSource',
		'scratchesDesc',
		'scuffedAlloyDesc',
		'sellerWillAcceptPrice',
		'serviceHistory',
		'soldFor',
		'warningLightsDesc',
		'windscreenProblemsDesc',
	]),
	/** LEGACY RESERVE PRICE START */
	priceHigh: vehicle.priceHigh,
	/** LEGACY RESERVE PRICE END */
	/* -------------------------- */
	// LEGACY DAMAGE LOCATION START
	...hide(['hasACProblems', 'ACProblemsDesc', 'hasElectricsProblems', 'electricsProblemsDesc']),
	// LEGACY DAMAGE LOCATION END
});

const resolveVehicleDetailsPageMapper = ({ req = {}, vehicle = {} }) =>
	req.user ? vehicleDetails(vehicle, req.user) : vehicleDetailsSneakPeek(vehicle);

module.exports = {
	resolveVehicleDetailsPageMapper,
	vehicleDetails,
	vehicleDetailsSneakPeek,
	vehicleForList,
};
