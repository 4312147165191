import { logger } from 'Services/logger/logger';

import { EXPIRED_AUTH_ERROR_CODE } from './braze.const';
import { BrazeSDKAuthError, BrazeSDKInitialisationError } from './braze.types';

export const reportBrazeInitialisationFailureError = (brazeError: BrazeSDKInitialisationError): void => {
	const error = { message: 'Braze SDK failed to initialise', name: 'Error', ...brazeError };
	const { userId } = brazeError;
	const message = `Braze SDK failed to initialise for user: ${userId}`;

	logger.warn({ error, message, scope: 'brazeInitialisationFailure' });
};

export const reportBrazeSdkAuthError = (brazeError: BrazeSDKAuthError): void => {
	const error = { message: 'Braze SDK auth error', name: 'Error', ...brazeError };
	const { errorCode, userId } = error;
	const tokenExpired = errorCode === EXPIRED_AUTH_ERROR_CODE;
	const message = `Braze SDK ${tokenExpired ? '' : 'non-'}expired token error for user: ${userId}`;

	logger.warn({ error, message, scope: 'brazeSdkAuthError' });
};
