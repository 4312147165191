export const VEHICLE_DETAILS_IMAGE_EVENTS = {
	SINGLE_CLICK: {
		ga: 'User single clicked while zoomed out',
		snowplow: 'dlr_single_click_while_zoomed_out',
	},
	ZOOM_CURSOR_IN: {
		ga: 'Zoom in cursor clicked',
		snowplow: 'dlr_zoom_in_cursor_clicked',
	},
	ZOOM_CURSOR_OUT: {
		ga: 'Zoom out cursor clicked',
		snowplow: 'dlr_zoom_out_cursor_clicked',
	},
	ZOOM_EXIT: {
		ga: 'Zoom exit button clicked',
		snowplow: 'dlr_zoom_exit_button_clicked',
	},
	ZOOM_IN: {
		ga: 'Zoom in button clicked',
		snowplow: 'dlr_zoom_in_button_clicked',
	},
	ZOOM_OUT: {
		ga: 'Zoom out button clicked',
		snowplow: 'dlr_zoom_out_button_clicked',
	},
} as const;

export const VEHICLE_DETAILS_IMAGE_ZOOM_LEVELS = {
	ANOTHER_LEVEL: 'Another level',
	LEVEL_1: 'Level 1',
	LEVEL_2: 'Level 2',
	LEVEL_3: 'Level 3',
} as const;

export const VEHICLE_DETAILS_IMAGE_SECTIONS = {
	ALLOY_SCUFFS: 'Alloy scuffs image zoom',
	DENTS: 'Dents image zoom',
	HERO_GALLERY: 'Hero image zoom',
	MISSING_LIGHTS_MIRRORS_OR_TRIM: 'Missing lights, mirrors or trim image zoom',
	PAINTWORK_PROBLEMS: 'Paintwork problems image zoom',
	SCRATCHES_SCUFFS: 'Scratches and scuffs Gallery image zoom',
	SELLER_DOCUMENTS_ADDITONAL_DOCUMENTS: 'Additional documents image zoom',
	SELLER_DOCUMENTS_BANK_STATEMENT: 'Bank statement image zoom',
	SELLER_DOCUMENTS_DEATH_CERTIFICATE: 'Death certificate image zoom',
	SELLER_DOCUMENTS_DRIVING_LICENCE: 'Driving licence image zoom',
	SELLER_DOCUMENTS_FINANCIAL_SETTLEMENT_LETTER: 'Finance settlement letter image zoom',
	SELLER_DOCUMENTS_LETTER_OF_CONSENT: 'Letter of consent image zoom',
	SELLER_DOCUMENTS_PASSPORT: 'Passport image zoom',
	SELLER_DOCUMENTS_PROOF_OF_PURCHASE: 'Proof of purchase image zoom',
	SELLER_DOCUMENTS_SOLICITORS_LETTER: "Solicitor's letter image zoom",
	SELLER_DOCUMENTS_UTILITY_BILL: 'Utility bill image zoom',
	SELLER_DOCUMENTS_V5: 'V5 image zoom',
	SELLER_DOCUMENTS_WILL: 'Will image zoom',
	SERVICE_RECORD: 'Service record image zoom',
	TYRE_PROBLEMS: 'Tyre problems image zoom',
	TYRE_THREADS: 'Tyres image zoom',
	WARNING_LIGHTS: 'Warning lights Gallery image zoom',
	WHEELS: 'Wheels image zoom',
	WIND_SCREEN_DAMAGE: 'Windscreen damage image zoom',
} as const;

export const GALLERY_EVENT_ACTIONS = {
	CLOSED: 'closed',
	NEXT_BUTTON_CLICKED: 'next button clicked',
	OPENED: 'opened',
	PREVIOUS_BUTTON_CLICKED: 'previous button clicked',
	VIEWED: 'image viewed',
} as const;
