import { isFeatureEnabled } from 'Context/FeaturesContext/FeaturesContext.helpers';
import { FEATURES } from 'Context/FeaturesContext/FeaturesContextConsts';

let GA_USER = null;

export const GA_TRACKING_ID = 'G-4YPX2KLG4X';

export const pageview = () => {
	if (!window.gtag) return;
	const { href, pathname } = window.location;
	const { referrer } = document;

	const user = Number.isInteger(GA_USER?.id)
		? {
				dimension1: GA_USER?.id.toString(),
		  }
		: {};

	// https://developers.google.com/analytics/devguides/collection/gtagjs/pages#page_view_event
	window.gtag('config', GA_TRACKING_ID, {
		page_location: href,
		page_path: pathname,
		page_referrer: referrer,
		send_page_view: false,
		...user,
	});

	window.gtag('event', 'page_view', {
		page_location: href,
		page_path: pathname,
		...user,
	});
};

export const event = ({
	action,
	isLegacyEvent = true,
	...eventParams // eslint-disable-line camelcase
}) => {
	if (!window.gtag) return;
	const isAnalyticsEventLoggerEnabled = isFeatureEnabled(FEATURES.analyticsEventLogger);

	if (isAnalyticsEventLoggerEnabled && isLegacyEvent) {
		// eslint-disable-next-line no-console
		console.info(action, {
			action,
			...eventParams,
		});
	}

	window.gtag('event', action, {
		...eventParams,
	});
};

export const setGAUser = (user) => {
	GA_USER = user;
};

export const initGAUser = () => {
	const gaUserProperties = Number.isInteger(GA_USER?.id)
		? {
				dealer_id: GA_USER?.dealerId.toString(),
				user_id: GA_USER?.id.toString(),
		  }
		: {};

	window.gtag('set', 'user_properties', gaUserProperties);
};

// isLegacyEvent is needed to prevent the analyticsEventLogger from logging the event twice
// When called from logAnalyticsEvent, isLegacyEvent is set to false
export const baseGAEvent = ({ action, category, isLegacyEvent = true, label }) =>
	event({
		action,
		event_action: action,
		event_category: category,
		event_label: label,
		isLegacyEvent,
	});
