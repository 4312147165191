import type { AxiosError } from 'axios';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import { redact } from 'Services/dataProvider.helpers';
import { logger } from 'Services/logger/logger';
import type { LogLevel } from 'Services/logger/logger.types';

import { DealerError } from './errors';
import { getAxiosErrorType } from './getAxiosErrorType';
import type { FormatAxiosErrorDetailsProps, HandleAxiosErrorProps } from './handleAxiosError.types';

// eslint-disable-next-line local-rules/function-naming
export const redactSensitiveFieldsFromAxiosError = (error: AxiosError): AxiosError =>
	redact(
		error,
		'config.auth',
		'config.headers["x-mway-user"]',
		'config.headers["Authorization"]',
		'config.httpsAgent',
		'request',
		'response.request',
	) as AxiosError;

const formatAxiosErrorDetails = ({ axiosError, context, level, sourceError }: FormatAxiosErrorDetailsProps) => {
	const axiosErrorStatus = Number(axiosError?.response?.status);
	const isWarning = axiosErrorStatus >= 400 && axiosErrorStatus < 500;
	const severity = level || ((isWarning ? 'warn' : 'error') as LogLevel);
	const error = sourceError || axiosError;
	const scope = error?.name || 'axiosError';
	const message = error?.message || 'An error occurred';
	const extra = context.extra || {};
	const axiosErrorType = getAxiosErrorType(axiosError);

	if (error instanceof DealerError) {
		(error as ErrorWithDetail).detail = axiosError;
	}

	const enrichedContext = {
		...context,
		extra: { ...extra, axiosError, axiosErrorType, sourceError },
	};

	return { context: enrichedContext, error, level: severity, message, scope };
};

export const handleAxiosError = ({ axiosError, context = {}, level, sourceError }: HandleAxiosErrorProps): void => {
	if (axios.isCancel(axiosError)) {
		// Assume all request cancellations are intentional so no need to handle them
		return;
	}

	if (isEmpty(axiosError)) {
		logger.warn({
			context: { ...context, sourceError },
			message: 'Empty axios error object passed to handleAxiosError',
			scope: 'handleAxiosError',
		});
		return;
	}

	const errorDetails = formatAxiosErrorDetails({ axiosError, context, level, sourceError });
	const { level: logLevel, ...enrichedErrorDetails } = errorDetails;

	logger[logLevel](enrichedErrorDetails);
};
