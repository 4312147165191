import {
	GetOrderFromSortParams,
	IsValidOrderParamValueParams,
	IsValidSortParamValueParams,
	Order,
	SortOption,
} from './useSortOrder.types';

export const isValidSortParamValue = ({ sort, sortOptions }: IsValidSortParamValueParams): boolean =>
	sortOptions.some(({ sort: sortOption }) => sort === sortOption);

// Checks sort/order values can be combined
export const isValidOrderParamValue = ({ adjustedSort, order, sortOptions }: IsValidOrderParamValueParams): boolean =>
	sortOptions.some(({ order: correspondingOrder, sort }) => sort === adjustedSort && order === correspondingOrder);

export const getOrderFromSort = ({ adjustedSort, sortOptions }: GetOrderFromSortParams): Order => {
	const { order: correspondingOrder } = sortOptions.find(({ sort }) => sort === adjustedSort) as SortOption;
	return correspondingOrder;
};
