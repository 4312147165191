import {
	abortRouteChange,
	getRedirectUrl,
	isAuthPage,
	isSignInUrl,
} from 'Components/RouterMiddleware/RouterMiddleware.helpers';

import useIsAuth from './useIsAuth';

const useSignInRedirect = () => {
	const isAuth = useIsAuth();

	const redirectIfNotSignedIn = (url) => {
		const redirectUrl = getRedirectUrl(url);

		const shouldRedirectToSignIn = !isAuth && isAuthPage(url) && !isSignInUrl(url);

		if (shouldRedirectToSignIn) {
			abortRouteChange({
				routerPushArgs: [
					redirectUrl,
					redirectUrl,
					{
						shallow: true,
					},
				],
			});
		}
	};

	return { redirectIfNotSignedIn };
};

export default useSignInRedirect;
