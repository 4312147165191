import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { KEEPER_DETAILS } = ANALYTICS_CATEGORIES;

export const triggerKeeperDetailsModalOpenEvent = (): void => {
	const eventPayload = getSnowplowEventPayload({
		category: KEEPER_DETAILS,
		name: 'dlr_keeper_details_modal_opened',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
