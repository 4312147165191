import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

export const triggerTradeSellerLearnMoreLinkClickedEvent = (enquiryId: number): void => {
	const eventPayload = getSnowplowEventPayload({
		category: VEHICLE_DETAILS,
		customData: {
			label: String(enquiryId),
		},
		name: `dlr_vehicle_details_trade_seller_learn_more_link_clicked`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
