import { ReactNode } from 'react';

import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';
import { AccountDateRange, AccountSlugs } from 'Types/account';

const { ACCOUNT } = ANALYTICS_CATEGORIES;

const { BIDDING_AND_PURCHASE_HISTORY } = ACCOUNT;

export type AccountCTAProps = {
	buttonLabel: ReactNode;
	slug: AccountSlugs;
	url: string;
};

export const triggerAccountPageSearchInputEvent = ({
	searchTerm,
	slug,
}: {
	searchTerm?: string;
	slug: AccountSlugs;
}): void => {
	const suffix = searchTerm ? 'input' : 'clear';

	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[slug],
		customData: {
			label: searchTerm,
		},
		name: `dlr_account_search_${suffix}`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerAccountPageResetFiltersEvent = (slug: AccountSlugs): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[slug],
		name: `dlr_account_clear_filters`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerAccountSortEvent = ({
	id,
	slug,
	sortingOrder,
}: {
	id: string;
	slug: AccountSlugs;
	sortingOrder: string;
}): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[slug],
		customData: {
			label: `${id} ${sortingOrder}`,
		},
		name: `dlr_account_sort`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerAccountDateFilterClicked = ({ filter, slug }: { filter: string; slug: AccountSlugs }): void => {
	if (!Object.values(AccountDateRange).includes(filter as AccountDateRange)) {
		return;
	}

	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[slug],
		customData: {
			label: filter,
		},
		name: 'dlr_account_date_filter_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerAccountPaginationEvent = (slug: AccountSlugs, page: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[slug],
		customData: {
			label: page,
		},
		name: 'dlr_account_pagination',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerAccountSlidingSidebarEvent = (isOpen: boolean): void => {
	const eventPayload = getSnowplowEventPayload({
		category: BIDDING_AND_PURCHASE_HISTORY,
		name: `dlr_account_sliding_sidebar_${isOpen ? 'open' : 'close'}`,
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerAccountErrorViewRefreshCTAClick = ({ buttonLabel, slug, url }: AccountCTAProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[slug],
		customData: {
			button_label: buttonLabel,
			url,
		},
		name: `dlr_account_error_view_refresh_cta_clicked`,
		schemaName: SNOWPLOW_EVENTS.CTA_CLICKED.SCHEMA,
		version: SNOWPLOW_EVENTS.CTA_CLICKED.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerAccountEmptyViewNoResultsCTAClick = ({ buttonLabel, slug, url }: AccountCTAProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[slug],
		customData: {
			button_label: buttonLabel,
			url,
		},
		name: `dlr_account_empty_view_no_results_cta_clicked`,
		schemaName: SNOWPLOW_EVENTS.CTA_CLICKED.SCHEMA,
		version: SNOWPLOW_EVENTS.CTA_CLICKED.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerAccountPageEmptyViewResetFiltersCTAClick = ({ buttonLabel, slug, url }: AccountCTAProps): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[slug],
		customData: {
			button_label: buttonLabel,
			url,
		},
		name: `dlr_account_reset_filters`,
		schemaName: SNOWPLOW_EVENTS.CTA_CLICKED.SCHEMA,
		version: SNOWPLOW_EVENTS.CTA_CLICKED.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerAccountVehicleDetailsClickedEvent = ({ id, slug }: { id: number; slug: AccountSlugs }): void => {
	const eventPayload = getSnowplowEventPayload({
		category: ACCOUNT[slug],
		customData: { label: String(id) },
		name: 'dlr_account_vehicle_details_clicked',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
};
