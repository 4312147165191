import { useEffect, useRef } from 'react';

const usePrevious = <T extends unknown, R extends number | undefined>(
	currentValue: T,
	entries?: R,
): undefined extends R ? T : Array<T> => {
	const previousValuesRef = useRef<Array<T>>([]);

	useEffect(() => {
		previousValuesRef.current = [currentValue, ...previousValuesRef.current].slice(0, entries ?? 1);
	}, [currentValue, entries]);

	if (entries === undefined) {
		// @ts-ignore -- We comply to return type + we have tests for it
		return previousValuesRef.current[0];
	}

	// @ts-ignore -- We comply to return type + we have tests for it
	return previousValuesRef.current;
};

export default usePrevious;
