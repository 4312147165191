/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import { SELLER_BANK_DETAILS_DISCLAIMERS } from 'Components/pages/SellerDetails/SellerDetails.consts';

import { ACCOUNT_CATEGORIES, ACCOUNT_SLUGS } from '../components/pages/AccountPage/AccountPage.consts';
import { ALL_FEATURES_RELATED_COOKIES } from '../contexts/FeaturesContext/FeaturesContextConsts';
import AccountPermitsChecker from '../controllers/AccountPermitsChecker/AccountPermitsChecker';
import VehiclePermitsChecker from '../controllers/VehiclePermitsChecker/VehiclePermitsChecker';

const address = PropTypes.shape({
	address1: PropTypes.string,
	address2: PropTypes.string,
	address3: PropTypes.string,
	city: PropTypes.string,
	postcode: PropTypes.string,
});

const stringOrNumber = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

const state = PropTypes.shape({
	category: PropTypes.string,
	id: PropTypes.number,
	name: PropTypes.string,
	slug: PropTypes.string,
});

const metaBasic = PropTypes.shape({
	height: stringOrNumber,
	rotate: stringOrNumber,
	scaleX: stringOrNumber,
	scaleY: stringOrNumber,
	width: stringOrNumber,
	x: stringOrNumber,
	y: stringOrNumber,
});

const meta = PropTypes.shape({
	canvas: PropTypes.shape({
		height: stringOrNumber,
		left: stringOrNumber,
		naturalHeight: stringOrNumber,
		naturalWidth: stringOrNumber,
		top: stringOrNumber,
		width: stringOrNumber,
	}),
	cropBox: PropTypes.shape({
		height: stringOrNumber,
		left: stringOrNumber,
		top: stringOrNumber,
		width: stringOrNumber,
	}),
	height: stringOrNumber,
	rotate: stringOrNumber,
	scaleX: stringOrNumber,
	scaleY: stringOrNumber,
	width: stringOrNumber,
	x: stringOrNumber,
	y: stringOrNumber,
});

const leadPhoto = PropTypes.shape({
	meta,
	url: PropTypes.string,
});

const photo = PropTypes.shape({
	createdAt: PropTypes.string,
	enquiryId: PropTypes.number,
	id: PropTypes.number,
	kind: PropTypes.string,
	meta,
	source: PropTypes.string,
	url: PropTypes.string,
});

const vehicleCardList = PropTypes.shape({
	displayPrice: PropTypes.number,
	distance: PropTypes.number,
	exteriorGrade: PropTypes.string,
	fuel: PropTypes.string,
	grade: PropTypes.string,
	highestBid: PropTypes.number,
	id: PropTypes.number,
	isVatQualifying: PropTypes.bool,
	leadPhoto,
	make: PropTypes.string,
	mileage: PropTypes.number,
	model: PropTypes.string,
	prettyVrm: PropTypes.string,
	priceHigh: PropTypes.number,
	sellerWillAcceptPrice: PropTypes.number, // LEGACY RESERVE PRICE
	soldFor: PropTypes.number,
	state,
	transmission: PropTypes.string,
	year: PropTypes.number,
});

const forwardedRef = PropTypes.oneOfType([
	PropTypes.func,
	PropTypes.shape({
		// eslint-disable-next-line react/forbid-prop-types
		current: PropTypes.any,
	}),
]);

const className = PropTypes.oneOfType([PropTypes.string, PropTypes.object]);

const bid = PropTypes.shape({
	createdAt: PropTypes.string,
	dealerContact: PropTypes.shape({
		id: PropTypes.number,
	}),
	id: PropTypes.number,
	value: PropTypes.number,
});

const userBids = PropTypes.shape({
	activeBidCount: PropTypes.number,
	usersBids: PropTypes.arrayOf(bid),
});

const getVehicleFiltersProps = (isQuery = false) => {
	const numberPropType = isQuery ? PropTypes.string : PropTypes.number;
	const stringArrayPropType = PropTypes.oneOfType([
		...(isQuery ? [PropTypes.string] : []),
		PropTypes.arrayOf(PropTypes.string),
	]);

	return {
		ageFrom: PropTypes.number,
		ageTo: PropTypes.number,
		displayPriceFrom: PropTypes.number,
		displayPriceTo: PropTypes.number,
		excludeVehiclesOnFinance: PropTypes.bool,
		exteriorGrade: stringArrayPropType,
		fuel: stringArrayPropType,
		grade: stringArrayPropType,
		includeSold: PropTypes.bool,
		includeUnderOffer: PropTypes.bool,
		isAvailableForCollection: PropTypes.bool,
		make: stringArrayPropType,
		maxDistance: PropTypes.number,
		mileageFrom: PropTypes.number,
		mileageTo: PropTypes.number,
		model: PropTypes.oneOfType([
			PropTypes.string,
			// to be removed afer model feature toggle is removed.
			stringArrayPropType,
			PropTypes.arrayOf(numberPropType),
		]),
		order: PropTypes.string,
		previousKeepersCountFrom: PropTypes.number,
		previousKeepersCountTo: PropTypes.number,
		selectedVehicle: PropTypes.number,
		sellerType: stringArrayPropType,
		serviceHistory: stringArrayPropType,
		sort: PropTypes.string,
		trade: PropTypes.bool,
		transmission: stringArrayPropType,
		vehicleClass: stringArrayPropType,
	};
};

const vehicleFilters = PropTypes.exact(getVehicleFiltersProps());

const vehicleFiltersQuery = PropTypes.exact(getVehicleFiltersProps(true));

const makes = PropTypes.arrayOf(
	PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		slug: PropTypes.string,
		total: PropTypes.number,
	}),
);

const models = PropTypes.arrayOf(
	PropTypes.shape({
		id: PropTypes.number,
		makeId: PropTypes.number,
		name: PropTypes.string,
	}),
);

const vehicleSortParams = PropTypes.exact({
	order: PropTypes.string,
	sort: PropTypes.string,
});

const sharedVehicleListProps = {
	dealerLatLng: PropTypes.arrayOf(PropTypes.number).isRequired,
	dealerPostcode: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	noVehiclesFound: PropTypes.bool,
	togglePanelFilters: PropTypes.func.isRequired,
	vehiclesCount: PropTypes.number.isRequired,
};

const user = PropTypes.shape({
	email: PropTypes.string,
	id: PropTypes.number,
	name: PropTypes.string,
	version: PropTypes.number,
});

const cookies = PropTypes.exact(
	ALL_FEATURES_RELATED_COOKIES.reduce(
		(acc, cookieName) => ({
			...acc,
			[cookieName]: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
		}),
		{},
	),
);

const vehicleDetailsLoggedIn = PropTypes.shape({
	additionalDetails: PropTypes.string,
	additionalSpec: PropTypes.string,
	availableFrom: PropTypes.string,
	availableUntil: PropTypes.string,
	bids: userBids,
	dentsDesc: PropTypes.string,
	equipment: PropTypes.string,
	equipmentV2: PropTypes.arrayOf(PropTypes.string),
	hasDents: PropTypes.bool,
	hasLockingWheelNut: PropTypes.bool,
	hasMissingParts: PropTypes.bool,
	hasOtherContactActiveBid: PropTypes.bool,
	hasPaintProblems: PropTypes.bool,
	hasPrivatePlate: PropTypes.bool,
	hasScratches: PropTypes.bool,
	hasScuffedAlloy: PropTypes.bool,
	hasServiceRecords: PropTypes.bool,
	hasToolsInBoot: PropTypes.bool,
	hasTyreProblems: PropTypes.bool,
	hasWarningLights: PropTypes.bool,
	hasWindscreenProblems: PropTypes.bool,
	highestBid: PropTypes.number,
	id: PropTypes.number,
	independentServiceStampsCount: PropTypes.number,
	interiorType: PropTypes.string,
	isTrade: PropTypes.bool,
	isTradeSeller: PropTypes.bool,
	isVatQualifying: PropTypes.bool,
	isVehicleOnFinance: PropTypes.bool,
	keeperStartDate: PropTypes.string,
	keysCount: PropTypes.number,
	lastMOTDate: PropTypes.string,
	mileage: PropTypes.number,
	minimumBid: PropTypes.shape({
		type: PropTypes.string,
		value: PropTypes.number,
	}),
	missingPartsDesc: PropTypes.string,
	officialServiceStampsCount: PropTypes.number,
	paintProblemsDesc: PropTypes.string,
	prettyVrm: PropTypes.string,
	previousKeepersCount: PropTypes.number,
	priceAverageCondition: PropTypes.number,
	priceGoodCondition: PropTypes.number,
	/** LEGACY RESERVE PRICE START */
	priceHigh: PropTypes.number,

	priceRetail: PropTypes.number,

	priceSource: PropTypes.string,

	priceUpdatedAt: PropTypes.string,

	scratchesDesc: PropTypes.string,

	scuffedAlloyDesc: PropTypes.string,

	seller: PropTypes.shape({
		distance: PropTypes.number,
		town: PropTypes.string,
	}),

	sellerWillAcceptPrice: PropTypes.number,

	serviceHistory: PropTypes.string,

	soldDate: PropTypes.string,

	soldFor: PropTypes.number,

	spec: PropTypes.shape({
		body: PropTypes.string,
		bodyCategory: PropTypes.string,
		colour: PropTypes.string,
		engineSize: PropTypes.number,
		firstRegistered: PropTypes.string,
		fuel: PropTypes.string,
		make: PropTypes.string,
		model: PropTypes.string,
		transmission: PropTypes.string,
		vin: PropTypes.string,
	}),

	state,

	tyreProblemsDesc: PropTypes.string,

	vehicleImages: PropTypes.arrayOf(photo),

	vrm: PropTypes.string,

	warningLightsDesc: PropTypes.string,

	windscreenProblemsDesc: PropTypes.string,

	year: PropTypes.number,
	/** LEGACY RESERVE PRICE END */
});

const vehicleDetailsPreview = PropTypes.shape({
	additionalDetails: PropTypes.string,
	additionalSpec: PropTypes.string,
	availableFrom: PropTypes.string,
	availableUntil: PropTypes.string,
	dentsDesc: PropTypes.string,
	equipment: PropTypes.string,
	equipmentV2: PropTypes.arrayOf(PropTypes.string),
	hasDents: PropTypes.string,
	hasLockingWheelNut: PropTypes.string,
	hasMissingParts: PropTypes.string,
	hasOtherContactActiveBid: PropTypes.bool,
	hasPaintProblems: PropTypes.string,
	hasPrivatePlate: PropTypes.string,
	hasScratches: PropTypes.string,
	hasScuffedAlloy: PropTypes.string,
	hasServiceRecords: PropTypes.string,
	hasToolsInBoot: PropTypes.string,
	hasTyreProblems: PropTypes.string,
	hasWarningLights: PropTypes.string,
	hasWindscreenProblems: PropTypes.string,
	id: PropTypes.number,
	independentServiceStampsCount: PropTypes.string,
	isTrade: PropTypes.bool,
	isVatQualifying: PropTypes.string,
	isVehicleOnFinance: PropTypes.string,
	keeperStartDate: PropTypes.string,
	keysCount: PropTypes.string,
	lastMOTDate: PropTypes.string,
	mileage: PropTypes.number,
	missingPartsDesc: PropTypes.string,
	officialServiceStampsCount: PropTypes.string,
	paintProblemsDesc: PropTypes.string,
	prettyVrm: PropTypes.string,
	previewDamageImages: PropTypes.arrayOf(photo),
	previousKeepersCount: PropTypes.string,
	priceAverageCondition: PropTypes.string,
	priceGoodCondition: PropTypes.string,
	/** LEGACY RESERVE PRICE START */
	priceHigh: PropTypes.number,

	priceRetail: PropTypes.string,

	priceSource: PropTypes.string,

	priceUpdatedAt: PropTypes.string,

	scratchesDesc: PropTypes.string,

	scuffedAlloyDesc: PropTypes.string,

	seller: PropTypes.shape({
		distance: PropTypes.string,
		town: PropTypes.string,
	}),

	sellerWillAcceptPrice: PropTypes.string,

	serviceHistory: PropTypes.string,

	soldDate: PropTypes.string,

	soldFor: PropTypes.string,

	spec: PropTypes.shape({
		body: PropTypes.string,
		bodyCategory: PropTypes.string,
		colour: PropTypes.string,
		engineSize: PropTypes.string,
		firstRegistered: PropTypes.string,
		fuel: PropTypes.string,
		interiorType: PropTypes.string,
		make: PropTypes.string,
		model: PropTypes.string,
		transmission: PropTypes.string,
		vin: PropTypes.string,
	}),

	state,

	tyreProblemsDesc: PropTypes.string,

	vehicleImages: PropTypes.arrayOf(photo),

	vrm: PropTypes.string,

	warningLightsDesc: PropTypes.string,

	wheelsAndTyresDamageImages: PropTypes.arrayOf(photo),

	windscreenProblemsDesc: PropTypes.string,

	year: PropTypes.number,
	/** LEGACY RESERVE PRICE END */
});

const vehicleDetails = PropTypes.oneOfType([vehicleDetailsLoggedIn, vehicleDetailsPreview]);

const vehiclesPermitsChecker = PropTypes.shape(
	Object.keys(new VehiclePermitsChecker()).reduce(
		(r, k) => ({
			[k]: PropTypes.func,
			...r,
		}),
		{ vehicle: vehicleDetails },
	),
);

const accountVehicle = PropTypes.shape({
	id: PropTypes.number,
	isVatQualifying: PropTypes.bool,
	leadPhoto,
	make: PropTypes.string,
	model: PropTypes.string,
	prettyVrm: PropTypes.string,
	soldDate: PropTypes.string,
	soldFor: PropTypes.number,
	state: PropTypes.shape({
		name: PropTypes.string,
		slug: PropTypes.string,
	}),
	userBid: PropTypes.shape({
		created_at: PropTypes.string,
		id: PropTypes.number,
		type: PropTypes.string,
		value: PropTypes.number,
	}),
});

const accountVehicles = PropTypes.shape({
	count: PropTypes.number,
	results: PropTypes.arrayOf(accountVehicle),
});

const accountSlugs = PropTypes.oneOf(Object.values(ACCOUNT_SLUGS));

const accountCategories = PropTypes.oneOf(Object.values(ACCOUNT_CATEGORIES));

const accountQuery = PropTypes.shape({
	category: accountCategories,
	limit: PropTypes.number,
	offset: PropTypes.number,
	slug: accountSlugs,
});

const accountPermitsChecker = PropTypes.shape(
	Object.keys(new AccountPermitsChecker()).reduce(
		(r, k) => ({
			[k]: PropTypes.func,
			...r,
		}),
		{
			category: accountCategories,
			slug: accountSlugs,
			vehicle: accountVehicle,
		},
	),
);

const bankDetailsDisclaimer = PropTypes.oneOf(Object.values(SELLER_BANK_DETAILS_DISCLAIMERS));

const sellerDetails = PropTypes.shape({
	address1: PropTypes.string,
	address2: PropTypes.string,
	address3: PropTypes.string,
	availableFrom: PropTypes.string,
	availableUntil: PropTypes.string,
	bankAccountNameMatchesV5: PropTypes.bool,
	city: PropTypes.string,
	collectionNotes: PropTypes.string,
	email: PropTypes.string,
	mobile: PropTypes.string,
	name: PropTypes.string,
	postcode: PropTypes.string,
	town: PropTypes.string,
	vehicle: PropTypes.shape({
		state: PropTypes.shape({
			slug: PropTypes.string,
		}),
	}),
});

const documentState = PropTypes.shape({
	additionalDoc: PropTypes.bool,
	failedReasons: PropTypes.arrayOf(PropTypes.string),
	kind: PropTypes.string,
	passReasons: PropTypes.arrayOf(PropTypes.string),
	reason: PropTypes.string,
	required: PropTypes.bool,
	review: PropTypes.string,
	tag: PropTypes.string,
	uploaded: PropTypes.bool,
});

const sellerDocument = PropTypes.shape({
	enquiryId: PropTypes.number,
	id: PropTypes.number,
	kind: PropTypes.string,
	meta: metaBasic,
	url: PropTypes.string,
});

const zoomEvents = PropTypes.shape({
	onDoubleClick: PropTypes.func,
	onSingleClick: PropTypes.func,
	onZoomExit: PropTypes.func,
	onZoomIn: PropTypes.func,
	onZoomOut: PropTypes.func,
});

const zoomEventsDefaults = {
	/* eslint-disable @typescript-eslint/no-empty-function */
	onDoubleClick: () => {},
	onSingleClick: () => {},
	onZoomExit: () => {},
	onZoomIn: () => {},
	onZoomOut: () => {},
	/* eslint-enable @typescript-eslint/no-empty-function */
};

const sellerDocuments = PropTypes.arrayOf(sellerDocument);

const sellerDocumentsState = PropTypes.arrayOf(documentState);

const tableProps = PropTypes.shape({
	'data-cy': PropTypes.string,
});

const transportOption = PropTypes.shape({
	price: PropTypes.number,
	type: PropTypes.string,
});

const transport = PropTypes.shape({
	cancelled: PropTypes.bool,
	noTransportEntry: PropTypes.bool,
	noTransportTypeSet: PropTypes.bool,
	selectedTransport: PropTypes.oneOfType([PropTypes.bool, transportOption]),
});

const nullableString = PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]);

const transportProviderType = PropTypes.shape({
	assignedAt: nullableString,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
});

const deliveryType = PropTypes.shape({
	plannedAt: nullableString,
});

const collectionType = PropTypes.shape({
	collected: PropTypes.bool.isRequired,
	plannedAt: nullableString,
});

const deliveryJobStateType = PropTypes.shape({
	reason: nullableString,
	stage: PropTypes.string.isRequired,
	status: PropTypes.string.isRequired,
	updatedAt: PropTypes.string.isRequired,
});

const jobDetailsType = PropTypes.shape({
	createdAt: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	state: deliveryJobStateType.isRequired,
});

const transportType = PropTypes.shape({
	price: PropTypes.number,
	provider: PropTypes.oneOfType([transportProviderType, PropTypes.oneOf([null])]),
	type: PropTypes.string.isRequired,
});

const deliveryDetails = PropTypes.shape({
	collection: collectionType.isRequired,
	delivery: deliveryType.isRequired,
	job: jobDetailsType.isRequired,
	transport: transportType.isRequired,
});

const savedFilterOrderConfig = {
	field: PropTypes.string,
	fields: PropTypes.arrayOf(PropTypes.string),
	formatter: PropTypes.func,
	isBoolean: PropTypes.bool,
	max: PropTypes.number,
	min: PropTypes.number,
	suffix: PropTypes.string,
	translation: PropTypes.string,
	translationsPath: PropTypes.string,
};

const savedFiltersOrder = PropTypes.arrayOf(PropTypes.shape(savedFilterOrderConfig));

const savedFilter = PropTypes.shape({
	createdAt: PropTypes.string.isRequired,
	filterData: PropTypes.shape(getVehicleFiltersProps()),
	id: PropTypes.number.isRequired,
});

const savedFiltersResponse = PropTypes.shape({
	filters: PropTypes.arrayOf(savedFilter),
	maxFilters: PropTypes.number.isRequired,
});

const savedFiltersStore = PropTypes.shape({
	createSavedFilter: PropTypes.func.isRequired,
	currentFilter: PropTypes.number,
	deleteSavedFilter: PropTypes.func.isRequired,
	items: PropTypes.arrayOf(savedFilter),
	maxFilters: PropTypes.number,
	setCurrentFilter: PropTypes.func.isRequired,
});

const vehicleFiltersStore = PropTypes.shape({
	items: vehicleFilters,
	resetValues: PropTypes.func.isRequired,
	setOnChange: PropTypes.func.isRequired,
	setValues: PropTypes.func.isRequired,
});

const route = PropTypes.shape({
	as: PropTypes.string.isRequired,
	href: PropTypes.string.isRequired,
});

export default {
	accountCategories,
	accountPermitsChecker,
	accountQuery,
	accountSlugs,
	accountVehicle,
	accountVehicles,
	address,
	bankDetailsDisclaimer,
	bid,
	className,
	cookies,
	deliveryDetails,
	forwardedRef,
	leadPhoto,
	makes,
	meta,
	models,
	photo,
	route,
	savedFilter,
	savedFilterOrderConfig,
	savedFiltersOrder,
	savedFiltersResponse,
	savedFiltersStore,
	sellerDetails,
	sellerDocuments,
	sellerDocumentsState,
	sharedVehicleListProps,
	state,
	stringOrNumber,
	tableProps,
	transport,
	transportOption,
	user,
	userBids,
	vehicleCardList,
	vehicleDetails,
	vehicleFilters,
	vehicleFiltersQuery,
	vehicleFiltersStore,
	vehicleSortParams,
	vehiclesPermitsChecker,
	zoomEvents,
	zoomEventsDefaults,
};
