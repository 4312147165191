import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from '../analytics.const';
import { SimilarVehiclesContext } from '../analytics.types';

import { getContextForSimilarVehicles } from './helpers/event.helpers';

const { INSIGHTS, VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

export const SOLD_INSIGHTS_EVENT_PREFIX = 'sold_insights';
interface TriggerRecommendedVehicleCardLoadedParams {
	eventsPrefix: string;
	id: number;
}

interface TriggerRecommendedVehicleCardClickedParams {
	eventsPrefix?: string;
	id: number;
	url: string;
}

interface TriggerRecommendedVehicleCardIsShortlistedParams {
	eventsPrefix?: string;
	id: number;
	url: string;
}

const getPayloadDefaults = (eventType: 'CLICK' | 'LOAD', eventsPrefix?: string) => ({
	category: eventsPrefix === SOLD_INSIGHTS_EVENT_PREFIX ? INSIGHTS : VEHICLE_DETAILS,
	schemaName: SNOWPLOW_EVENTS[eventType].SCHEMA,
	version: SNOWPLOW_EVENTS[eventType].VERSION,
});

const getEventNamePrefix = (eventsPrefix?: string): string => {
	return eventsPrefix || 'dlr';
};

export const triggerRecommendedVehicleCardLoaded = ({
	eventsPrefix,
	id,
}: TriggerRecommendedVehicleCardLoadedParams): void => {
	const eventPayload = getSnowplowEventPayload({
		...getPayloadDefaults('LOAD', eventsPrefix),
		customData: { label: `${id}` },
		name: `${getEventNamePrefix(eventsPrefix)}_recommended_vehicle_card_loaded`,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerRecommendedVehicleCardClicked = ({
	eventsPrefix,
	id,
	url,
}: TriggerRecommendedVehicleCardClickedParams): void => {
	const eventPayload = getSnowplowEventPayload({
		...getPayloadDefaults('CLICK', eventsPrefix),
		customData: { label: `${id}`, url: `${url}` },
		name: `${getEventNamePrefix(eventsPrefix)}_recommended_vehicle_card_clicked`,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerRecommendedVehicleCardIsShortlisted = ({
	eventsPrefix,
	id,
	url,
}: TriggerRecommendedVehicleCardIsShortlistedParams): void => {
	const eventPayload = getSnowplowEventPayload({
		...getPayloadDefaults('CLICK', eventsPrefix),
		customData: { label: `${id}`, url: `${url}` },
		name: `${getEventNamePrefix(eventsPrefix)}_recommended_vehicle_card_shortlist_clicked`,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerRecommendedSeeAllVehiclesClicked = (url: string, eventsPrefix?: string): void => {
	const eventPayload = getSnowplowEventPayload({
		...getPayloadDefaults('CLICK', eventsPrefix),
		customData: { url: `${url}` },
		name: `${getEventNamePrefix(eventsPrefix)}_recommended_see_all_vehicles_clicked`,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerRecommendedVehiclesLoaded = ({
	enquiryIds,
	eventsPrefix,
	version,
}: SimilarVehiclesContext): void => {
	const context = getContextForSimilarVehicles({ enquiryIds, version });
	const eventPayload = getSnowplowEventPayload({
		context,
		...getPayloadDefaults('LOAD', eventsPrefix),
		name: `${getEventNamePrefix(eventsPrefix)}_recommended_vehicles_loaded`,
	});
	logAnalyticsEvent({ eventPayload });
};

export const triggerRecommendedVehiclesLoadFailed = ({ eventsPrefix }: SimilarVehiclesContext = {}): void => {
	const eventPayload = getSnowplowEventPayload({
		...getPayloadDefaults('LOAD', eventsPrefix),
		name: `${getEventNamePrefix(eventsPrefix)}_recommended_vehicles_load_failed`,
	});
	logAnalyticsEvent({ eventPayload });
};

export const triggerRecommendedVehiclesNextArrowClicked = (eventsPrefix?: string): void => {
	const eventPayload = getSnowplowEventPayload({
		...getPayloadDefaults('CLICK', eventsPrefix),
		name: `${getEventNamePrefix(eventsPrefix)}_recommended_vehicle_carousel_next_button_clicked`,
	});

	logAnalyticsEvent({ eventPayload });
};

export const triggerRecommendedVehiclesPreviousArrowClicked = (eventsPrefix?: string): void => {
	const eventPayload = getSnowplowEventPayload({
		...getPayloadDefaults('CLICK', eventsPrefix),
		name: `${getEventNamePrefix(eventsPrefix)}_recommended_vehicle_carousel_previous_button_clicked`,
	});

	logAnalyticsEvent({ eventPayload });
};
