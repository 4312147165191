import { z } from 'zod';

export const MotDataStatusSchema = z.enum(['EXEMPT', 'PASSED', 'FAILED', 'NOT_AVAILABLE']);

export const MotDataSchema = z.object({
	advisories: z.array(z.string()),
	MOTAdvisoriesUnavailable: z.boolean(),
	status: MotDataStatusSchema,
});

export const GetVehicleMotSchema = z.object({
	lastMOTDate: z.string().nullable(),
	motData: MotDataSchema,
});
