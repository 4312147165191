import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { isDev } from 'Utilities/helpers';

const routerStore = () => ({
	prevQuery: null,
	prevRoute: null,
	prevRouteAsPath: null,
});

const useRouterStore = create(
	isDev
		? devtools(routerStore, {
				enabled: isDev,
				name: 'RouterStore',
		  })
		: routerStore,
);

export default useRouterStore;
