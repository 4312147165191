import omit from 'lodash/omit';
import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';

import * as SavedFiltersAPI from 'Services/api/savedFilters';
import { clearError, setError } from 'Stores/FilterStore/FilterStoreHelpers';
import { INTENT_TYPES } from 'Utilities/FormBase/FormBase.helpers';
import { getAxiosResponseStatus, isDev } from 'Utilities/helpers';

import {
	COULD_NOT_CREATE_SAVED_SEARCH,
	COULD_NOT_DELETE_SAVED_SEARCH,
	COULD_NOT_LOAD_SAVED_SEARCHES,
	COULD_NOT_UPDATE_SAVED_SEARCH,
	MAX_NUMBER_OF_ALLOWED_SEARCHES_REACHED,
	OMITTED_FIELDS,
} from './SavedFilters.conf';
import { filterExists } from './SavedFilters.helpers';

const savedFiltersStore = (set, get) => ({
	clearError: clearError(set),
	createSavedFilter: async ({ filterData, ...rest }) => {
		// eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
		const { filters, setError } = get();

		try {
			const { filter, totalCount } = await SavedFiltersAPI.createSavedFilter({
				payload: {
					filterData: omit(filterData, OMITTED_FIELDS),
					...rest,
				},
			});

			const { id: currentFilter } = filter;

			const alreadyExists = filterExists(filters, currentFilter);

			set(
				() => ({
					currentFilter,
					/**
					 * V1 needs the new filter to be inserted into the store.
					 * V2 navigates back to the list and refetches all saved filters.
					 * This causes a race condition between the fetch insertion and the
					 * creation insertion.
					 * Check if the filter doesn't exist before inserting.
					 * This line can be removed when V1 is removed.
					 */
					filters: [!alreadyExists && filter, ...filters],
					totalCount,
				}),
				false,
				{ filterData, ...rest, type: 'createSavedFilter' },
			);
		} catch (err) {
			const status = getAxiosResponseStatus(err);
			switch (status) {
				case 500:
					return setError({ message: COULD_NOT_CREATE_SAVED_SEARCH });
				case 422:
					return setError({ intent: INTENT_TYPES.WARNING, message: MAX_NUMBER_OF_ALLOWED_SEARCHES_REACHED });
				default:
					return setError({ message: err });
			}
		}

		return null;
	},
	currentFilter: undefined,
	deleteSavedFilter: async (id, callback) => {
		// eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
		const { setError } = get();
		try {
			await SavedFiltersAPI.deleteSavedFilter({ id });
			set(
				(state) => {
					const { currentFilter, filters } = state;
					const nextFilters = filters.filter((filter) => filter.id !== id);
					const nextCurrentFilter = currentFilter === id ? undefined : id;
					callback?.();
					return {
						currentFilter: nextCurrentFilter,
						filters: nextFilters,
					};
				},
				false,
				{ callback, id, type: 'deleteSavedFilter' },
			);
		} catch (err) {
			return setError({ message: COULD_NOT_DELETE_SAVED_SEARCH });
		}
		return null;
	},
	error: false,
	filters: [],
	getFilterIdByIndex: (index) => {
		const { filters } = get();
		return filters[index]?.id ? filters[index]?.id : undefined;
	},
	getSavedFilter: (savedFilterId) => {
		const { filters } = get();

		return filters?.find((filter) => filter?.id === savedFilterId) || filters?.[0] || {};
	},
	getSavedFilters: async () => {
		// eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
		const { setError } = get();

		try {
			const { filters, maxFilters, totalCount } = await SavedFiltersAPI.getSavedFilters();

			set({ filters, maxFilters, totalCount }, false, {
				filters,
				maxFilters,
				totalCount,
				type: 'getSavedFilters',
			});
		} catch (err) {
			return setError({ message: COULD_NOT_LOAD_SAVED_SEARCHES });
		}

		return null;
	},
	isClickedOutsidePillButton: false,
	isSavedSearchPillOpen: false,
	isUniqueFilter: false,
	maxFilters: undefined,
	setCurrentFilter: (filterId) => {
		set({ currentFilter: filterId }, false, { filterId, type: 'setCurrentFilter' });
	},
	setError: setError(set),
	setFilters: (filters) => {
		set({ filters }, false, { filters, type: 'setFilters' });
	},
	setIsClickedOutsidePillButton: (isClickedOutsidePillButton) => {
		set({ isClickedOutsidePillButton }, false, { isClickedOutsidePillButton, type: 'setIsClickedOutsidePillButton' });
	},
	setIsSavedSearchPillOpen: (isPillOpen) => {
		set({ isSavedSearchPillOpen: isPillOpen }, false, { isPillOpen, type: 'setIsSavedSearchPillOpen' });
	},
	setIsUniqueFilter: (isUniqueFilter) => {
		set({ isUniqueFilter }, false, { type: 'setIsUniqueFilter' });
	},
	totalCount: undefined,
	updateSavedFilter: async ({ filterData, ...rest }, callback) => {
		// eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
		const { filters: existingFilters, setError } = get();

		try {
			const updatedFilter = await SavedFiltersAPI.updateSavedFilter({
				payload: {
					filterData: omit(filterData, OMITTED_FIELDS),
					...rest,
				},
			});

			const { id } = updatedFilter;
			const filters = existingFilters.map((filter) => (filter?.id === id ? updatedFilter : filter));

			set({ filters }, false, { filterData, ...rest, type: 'updateSavedFilter' });
			callback();
		} catch (err) {
			const status = getAxiosResponseStatus(err);
			switch (status) {
				case 500:
					return setError({ message: COULD_NOT_UPDATE_SAVED_SEARCH });
				default:
					return setError({ message: err });
			}
		}

		return null;
	},
});

const useSavedFiltersStore = create(
	subscribeWithSelector(
		isDev
			? devtools(savedFiltersStore, {
					enabled: isDev,
					name: 'SavedFiltersStore',
			  })
			: savedFiltersStore,
	),
);

export const useSavedFilters = () => useSavedFiltersStore((store) => store.filters);
export const useCurrentFilter = () => useSavedFiltersStore((store) => store.currentFilter);
export const useIsUniqueFilterInStore = () => useSavedFiltersStore((store) => store.isUniqueFilter);
export const useIsSavedSearchPillOpen = () => useSavedFiltersStore((store) => store.isSavedSearchPillOpen);
export const useSetCurrentFilter = () => useSavedFiltersStore((store) => store.setCurrentFilter);
export const useSetIsUniqueFilterInStore = () => useSavedFiltersStore((store) => store.setIsUniqueFilter);
export const useSetIsSavedSearchPillOpen = () => useSavedFiltersStore((store) => store.setIsSavedSearchPillOpen);
export const useGetSavedFilter = () => useSavedFiltersStore((store) => store.getSavedFilter);
export const useGetSavedFilters = () => useSavedFiltersStore((store) => store.getSavedFilters);
export const useSetIsClickedOutsidePillButton = () =>
	useSavedFiltersStore((store) => store.setIsClickedOutsidePillButton);
export const useGetFilterIdByIndex = () => useSavedFiltersStore((store) => store.getFilterIdByIndex);
export default useSavedFiltersStore;
