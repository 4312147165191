import pick from 'lodash/pick';

import { AccountDateRange } from 'Types/account';

/* Disable sort to prevent account menu items re-ordering */
/* eslint-disable sort-keys-fix/sort-keys-fix */
export const ACCOUNT_CATEGORIES = Object.freeze({
	BIDS: 'bids',
	PURCHASES: 'purchases',
	SAVED_SEARCHES: 'savedSearches',
	SHORTLIST: 'shortlist',
	WALLET: 'wallet',
	BILLING: 'billing',
});

export const ACCOUNT_PAGE_CATEGORIES = Object.freeze(pick(ACCOUNT_CATEGORIES, ['BIDS', 'PURCHASES']));
/* eslint-enable sort-keys-fix/sort-keys-fix */

export const ACCOUNT_CATEGORIES_DEFAULT_DATE_RANGE = Object.freeze({
	[ACCOUNT_CATEGORIES.BIDS]: AccountDateRange.ALL,
	[ACCOUNT_CATEGORIES.PURCHASES]: AccountDateRange.ALL,
});

export const NO_SLUG_CATEGORIES = Object.freeze([
	ACCOUNT_CATEGORIES.BILLING,
	ACCOUNT_CATEGORIES.SAVED_SEARCHES,
	ACCOUNT_CATEGORIES.WALLET,
]);

export const ACCOUNT_SLUGS = Object.freeze({
	ACTIVE: 'active',
	ALL: 'all',
	CANCELLED: 'cancelled',
	COMPLETE: 'complete',
	DIDNT_WIN: 'didnt-win',
	UNDER_OFFER: 'under-offer',
});

export const ACCOUNT_ALLOWED_SLUGS = Object.freeze({
	[ACCOUNT_CATEGORIES.BIDS]: [ACCOUNT_SLUGS.ACTIVE, ACCOUNT_SLUGS.UNDER_OFFER, ACCOUNT_SLUGS.DIDNT_WIN],
	[ACCOUNT_CATEGORIES.PURCHASES]: [ACCOUNT_SLUGS.COMPLETE, ACCOUNT_SLUGS.CANCELLED],
});

export const GA_ACCOUNT_CATEGORIES = Object.freeze({
	[ACCOUNT_CATEGORIES.BIDS]: 'Bidding and Purchase history',
	[ACCOUNT_CATEGORIES.PURCHASES]: 'Bidding and Purchase history',
	[ACCOUNT_CATEGORIES.BILLING]: 'Billing',
	[ACCOUNT_CATEGORIES.SAVED_SEARCHES]: 'Saved searches',
	[ACCOUNT_CATEGORIES.WALLET]: 'Wallet',
});
