import { getMakes } from 'Services/api/vehicles';
import { ERRORS } from 'Utilities/consts';
import { fetchWithExceptionLogger } from 'Utilities/error/fetchWithExceptionLogger';

const fetchMakesWithExceptionLogger = async (context, query = {}) =>
	fetchWithExceptionLogger({
		callback: getMakes.bind(null, context, query),
		errorMessage: ERRORS.UNABLE_TO_FETCH_INITIAL_MAKES,
		extra: { context },
		isWarning: true,
	});

export default fetchMakesWithExceptionLogger;
