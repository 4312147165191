import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { ACCOUNT_SETTINGS } = ANALYTICS_CATEGORIES;

export const triggerAccountSettingsSidebarLinkClickedEvent = (link: string): void =>
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: ACCOUNT_SETTINGS,
			customData: { label: link },
			name: 'dlr_sidebar_link_clicked',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});

export const triggerAccountSettingsGuideLinkClickedEvent = (title: string): void =>
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: ACCOUNT_SETTINGS,
			customData: { label: title },
			name: 'dlr_homepage_help_center_guide_clicked',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});

export const triggerAccountSettingsSeeAllGuidesLinkClickedEvent = (): void =>
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: ACCOUNT_SETTINGS,
			name: 'dlr_homepage_see_all_guides_clicked',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});

export const triggerAccountSettingsProductCardClickedEvent = (title: string): void =>
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: ACCOUNT_SETTINGS,
			customData: { label: title },
			name: 'dlr_homepage_motorway_product_clicked',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});
