import { useContext } from 'react';
import { StoreApi, useStore } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createGenericStoreWithDevtools } from 'Stores/store';
import { ListContext } from 'Stores/StoresContext/StoresContext';
import { ListType } from 'Types/listType';
import { LIST_TYPES } from 'Utilities/consts';
import { isDev } from 'Utilities/helpers';

import { ListStore } from './ListStore.types';

const createListStore = (initialListType: ListType = LIST_TYPES.auction) =>
	devtools<ListStore>(
		(set) => ({
			listType: initialListType,
			setListType: (listType: ListStore['listType']) => {
				set({ listType }, false, { listType, type: 'setListType' });
			},
			setShouldRefreshVehicleList: (shouldRefreshVehicleList: ListStore['shouldRefreshVehicleList']) => {
				set({ shouldRefreshVehicleList }, false, { shouldRefreshVehicleList, type: 'setShouldRefreshVehicleList' });
			},
			shouldRefreshVehicleList: false,
		}),
		{ enabled: isDev, name: 'ListStore' },
	);

export const createListStoreWithInitial = (listType?: ListType): StoreApi<ListStore> =>
	createGenericStoreWithDevtools(createListStore(listType));

export const useListStore = <T>(selector: (state: ListStore) => T, equalityFn?: () => boolean): T => {
	const store = useContext(ListContext);
	if (!store) {
		throw new Error('Missing ListContext.Provider in the tree');
	}

	return useStore(store, selector, equalityFn);
};

export const useListType = (): ListStore['listType'] => useListStore((store) => store.listType);
export const useSetListType = (): ListStore['setListType'] => useListStore((store) => store.setListType);
export const useShouldRefreshVehicleList = (): ListStore['shouldRefreshVehicleList'] =>
	useListStore((store) => store.shouldRefreshVehicleList);
export const useSetShouldRefreshVehicleList = (): ListStore['setShouldRefreshVehicleList'] =>
	useListStore((store) => store.setShouldRefreshVehicleList);

export default useListStore;
