import { snakeCase } from 'lodash';

import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { BIDDING } = ANALYTICS_CATEGORIES;

const { GOOGLE_ANALYTICS, SNOWPLOW } = ANALYTICS_PROVIDERS;

const { CLICK } = SNOWPLOW_EVENTS;

interface TriggerOfferCancelledEventParams {
	bidAmount: number;
	offerLabel: string;
}

export const triggerOfferCancelledEvent = ({ bidAmount, offerLabel }: TriggerOfferCancelledEventParams): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: BIDDING,
				customData: { label: `${bidAmount}` },
				name: `dlr_${snakeCase(offerLabel)}_cancelled`,
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
		{
			eventPayload: {
				action: `${offerLabel} cancelled`,
				category: BIDDING,
				isLegacyEvent: false,
				label: bidAmount,
			},
			provider: GOOGLE_ANALYTICS,
		},
	]);
};
