import curryRight from 'lodash/fp/curryRight';

import { getFieldNameParts, MinMaxFilters, reachValue } from 'Components/VehicleList/filters/Filters.helpers';
import { VEHICLE_LIST_FILTER_NAME, VEHICLE_VALUES_TYPES } from 'Components/VehicleList/VehicleListFilters.consts';
import { triggerSliderFilterEvent } from 'Services/analytics/events';

const onSliderChange = ({ id, value }) => {
	const { fieldType, nameOfField } = getFieldNameParts(id);
	triggerSliderFilterEvent({ fieldType, nameOfField, value });
};

const {
	AGE_FROM,
	AGE_TO,
	DISPLAY_PRICE_FROM,
	DISPLAY_PRICE_TO,
	EXCLUDE_VEHICLES_ON_FINANCE,
	EXTERIOR_GRADE,
	FUEL,
	INCLUDE_SOLD,
	INCLUDE_UNDER_OFFER,
	IS_AVAILABLE_FOR_COLLECTION,
	MAKE,
	MAX_DISTANCE,
	MILEAGE_FROM,
	MILEAGE_TO,
	MODEL,
	ORDER,
	PREVIOUS_KEEPERS_COUNT_FROM,
	PREVIOUS_KEEPERS_COUNT_TO,
	SELECTED_VEHICLE,
	SELLER_TYPE,
	SERVICE_HISTORY,
	SORT,
	TRADE,
	TRANSMISSION,
	VEHICLE_CLASS,
} = VEHICLE_LIST_FILTER_NAME;

const vehicleListFilterConfig = {
	[AGE_FROM]: {
		id: AGE_FROM,
		modifier: curryRight(reachValue)(MinMaxFilters[AGE_FROM]),
		onChange: onSliderChange,
		type: VEHICLE_VALUES_TYPES.NUMBER,
		value: null,
	},
	[AGE_TO]: {
		id: AGE_TO,
		modifier: curryRight(reachValue)(MinMaxFilters[AGE_TO]),
		onChange: onSliderChange,
		type: VEHICLE_VALUES_TYPES.NUMBER,
		value: null,
	},
	[DISPLAY_PRICE_FROM]: {
		id: DISPLAY_PRICE_FROM,
		modifier: curryRight(reachValue)(MinMaxFilters[DISPLAY_PRICE_FROM]),
		onChange: onSliderChange,
		type: VEHICLE_VALUES_TYPES.NUMBER,
		value: null,
	},
	[DISPLAY_PRICE_TO]: {
		id: DISPLAY_PRICE_TO,
		modifier: curryRight(reachValue)(MinMaxFilters[DISPLAY_PRICE_TO]),
		onChange: onSliderChange,
		type: VEHICLE_VALUES_TYPES.NUMBER,
		value: null,
	},
	[EXCLUDE_VEHICLES_ON_FINANCE]: {
		id: EXCLUDE_VEHICLES_ON_FINANCE,
		modifier: curryRight(reachValue)(MinMaxFilters[EXCLUDE_VEHICLES_ON_FINANCE]),
		onChange: onSliderChange,
		type: VEHICLE_VALUES_TYPES.NUMBER,
		value: false,
	},
	[EXTERIOR_GRADE]: {
		id: EXTERIOR_GRADE,
		type: VEHICLE_VALUES_TYPES.ARRAY,
		value: [],
	},
	[FUEL]: {
		id: FUEL,
		type: VEHICLE_VALUES_TYPES.ARRAY,
		value: [],
	},
	[INCLUDE_SOLD]: {
		id: INCLUDE_SOLD,
		type: VEHICLE_VALUES_TYPES.BOOL,
		value: false,
	},
	[INCLUDE_UNDER_OFFER]: {
		id: INCLUDE_UNDER_OFFER,
		type: VEHICLE_VALUES_TYPES.BOOL,
		value: false,
	},
	[IS_AVAILABLE_FOR_COLLECTION]: {
		id: IS_AVAILABLE_FOR_COLLECTION,
		type: VEHICLE_VALUES_TYPES.BOOL,
		value: false,
	},
	[MAKE]: {
		id: MAKE,
		type: VEHICLE_VALUES_TYPES.ARRAY,
		value: [],
	},
	[MAX_DISTANCE]: {
		id: MAX_DISTANCE,
		modifier: curryRight(reachValue)(MinMaxFilters[MAX_DISTANCE]),
		onChange: onSliderChange,
		type: VEHICLE_VALUES_TYPES.NUMBER,
		value: null,
	},
	[MILEAGE_FROM]: {
		id: MILEAGE_FROM,
		modifier: curryRight(reachValue)(MinMaxFilters[MILEAGE_FROM]),
		onChange: onSliderChange,
		type: VEHICLE_VALUES_TYPES.NUMBER,
		value: null,
	},
	[MILEAGE_TO]: {
		id: MILEAGE_TO,
		modifier: curryRight(reachValue)(MinMaxFilters[MILEAGE_TO]),
		onChange: onSliderChange,
		type: VEHICLE_VALUES_TYPES.NUMBER,
		value: null,
	},
	[MODEL]: {
		id: MODEL,
		type: VEHICLE_VALUES_TYPES.ARRAY,
		value: [],
	},
	[ORDER]: {
		excludeFromCount: true,
		id: ORDER,
		value: 'DESC',
	},
	[PREVIOUS_KEEPERS_COUNT_FROM]: {
		id: PREVIOUS_KEEPERS_COUNT_FROM,
		modifier: curryRight(reachValue)(MinMaxFilters[PREVIOUS_KEEPERS_COUNT_FROM]),
		onChange: onSliderChange,
		type: VEHICLE_VALUES_TYPES.NUMBER,
		value: null,
	},
	[PREVIOUS_KEEPERS_COUNT_TO]: {
		id: PREVIOUS_KEEPERS_COUNT_TO,
		modifier: curryRight(reachValue)(MinMaxFilters[PREVIOUS_KEEPERS_COUNT_TO]),
		onChange: onSliderChange,
		type: VEHICLE_VALUES_TYPES.NUMBER,
		value: null,
	},
	[SELECTED_VEHICLE]: {
		id: SELECTED_VEHICLE,
		value: null,
	},
	[SELLER_TYPE]: {
		id: SELLER_TYPE,
		type: VEHICLE_VALUES_TYPES.ARRAY,
		value: [],
	},
	[SERVICE_HISTORY]: {
		id: SERVICE_HISTORY,
		type: VEHICLE_VALUES_TYPES.ARRAY,
		value: [],
	},
	[SORT]: {
		id: SORT,
		value: '',
	},
	[TRADE]: {
		id: TRADE,
		value: false,
	},
	[TRANSMISSION]: {
		id: TRANSMISSION,
		type: VEHICLE_VALUES_TYPES.ARRAY,
		value: [],
	},
	[VEHICLE_CLASS]: {
		id: VEHICLE_CLASS,
		type: VEHICLE_VALUES_TYPES.ARRAY,
		value: [],
	},
};

export default vehicleListFilterConfig;
