import { createContext, useRef } from 'react';

import { createFilterStoreWithInitial } from 'Stores/FilterStore/VehicleListFilter/VehicleListFilterStore';
import { createListStoreWithInitial } from 'Stores/ListStore/ListStore';
import { createMakeStoreWithInitial } from 'Stores/MakesStore/MakesStore';
import { createModelsStoreWithInitial } from 'Stores/ModelsStore/ModelsStore';
import { createSaleTimesStoreWithInitial } from 'Stores/SaleTimesStore/SaleTimesStore';
import { useVehiclePaymentDataStoreRouterEffect } from 'Stores/VehiclePaymentDataStore/VehiclePaymentDataStore';

import { mapQueryFiltersToFilterStoreInitialValues } from './StoresContext.helpers';
import {
	FilterContextStore,
	ListContextStore,
	MakesContextStore,
	ModelsContextStore,
	SaleTimeContextStore,
	StoresProviderProps,
} from './StoresContext.types';

export const MakesContext = createContext<MakesContextStore>(undefined);
export const ModelsContext = createContext<ModelsContextStore>(undefined);
export const FilterContext = createContext<FilterContextStore>(undefined);
export const ListContext = createContext<ListContextStore>(undefined);
export const SaleTimesContext = createContext<SaleTimeContextStore>(undefined);

const VehiclePaymentDataComponent = () => {
	useVehiclePaymentDataStoreRouterEffect();

	return null;
};

export const StoresProvider: React.FC<StoresProviderProps> = ({ children, initialValues }) => {
	const { filters, listType, makes, models, query, saleTimes } = initialValues || {};
	const hasSetupStores = useRef<boolean>(false);
	const makesStoreRef = useRef<MakesContextStore>();
	const modelsStoreRef = useRef<ModelsContextStore>();
	const filterStoreRef = useRef<FilterContextStore>();
	const ListStoreRef = useRef<ListContextStore>();
	const saleTimesStoreRef = useRef<SaleTimeContextStore>();

	const setupStores = () => {
		makesStoreRef.current = createMakeStoreWithInitial(makes);
		modelsStoreRef.current = createModelsStoreWithInitial(models);
		saleTimesStoreRef.current = createSaleTimesStoreWithInitial(saleTimes);

		const { getMakesBySlugs } = makesStoreRef.current.getState();
		const { getModelIds } = modelsStoreRef.current.getState();

		const hasUserSetSortInQuery = Boolean(query?.sort);
		const adjustedFilters = mapQueryFiltersToFilterStoreInitialValues({ filters, getMakesBySlugs, getModelIds });

		filterStoreRef.current = createFilterStoreWithInitial({
			filters: adjustedFilters,
			hasUserSetSort: hasUserSetSortInQuery,
		});
		ListStoreRef.current = createListStoreWithInitial(listType);
	};

	if (!hasSetupStores.current) {
		setupStores();
		hasSetupStores.current = true;
	}

	const contexts = (
		<SaleTimesContext.Provider value={saleTimesStoreRef.current}>
			<FilterContext.Provider value={filterStoreRef.current}>
				<MakesContext.Provider value={makesStoreRef.current}>
					<ModelsContext.Provider value={modelsStoreRef.current}>
						<ListContext.Provider value={ListStoreRef.current}>{children}</ListContext.Provider>
					</ModelsContext.Provider>
				</MakesContext.Provider>
			</FilterContext.Provider>
		</SaleTimesContext.Provider>
	);

	const effects = (
		<>
			<VehiclePaymentDataComponent />
		</>
	);

	return (
		<>
			{contexts}
			{effects}
		</>
	);
};
