import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { SIGN_IN_PAGE, SIGN_UP_FORM } = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerSignInClickedEvent = (): void => {
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: SIGN_IN_PAGE,
			customData: {
				button_label: 'Sign in',
				url: '/signin',
			},
			name: 'sign_in_button_clicked',
			schemaName: SNOWPLOW_EVENTS.CTA_CLICKED.SCHEMA,
			version: SNOWPLOW_EVENTS.CTA_CLICKED.VERSION,
		}),
	});
};

export const triggerFormSubmittedEvent = (label: string): void => {
	logAnalyticsEvent({
		eventPayload: {
			action: 'Form submitted',
			category: SIGN_UP_FORM,
			isLegacyEvent: false,
			label: 'Form submitted',
		},
		provider: GOOGLE_ANALYTICS,
	});

	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: SIGN_UP_FORM,
			customData: { label },
			name: 'dlr_form_submitted',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});
};

export const triggerFormStepCompleteEvent = (label: string): void => {
	logAnalyticsEvent({
		eventPayload: {
			action: 'Form step complete',
			category: SIGN_UP_FORM,
			isLegacyEvent: false,
			label,
		},
		provider: GOOGLE_ANALYTICS,
	});

	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: SIGN_UP_FORM,
			customData: { label },
			name: 'dlr_form_step_complete',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});
};

export const triggerFormValidationErrorEvent = (label: string): void => {
	logAnalyticsEvent({
		eventPayload: {
			action: 'Form validation error',
			category: SIGN_UP_FORM,
			isLegacyEvent: false,
			label,
		},
		provider: GOOGLE_ANALYTICS,
	});

	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: SIGN_UP_FORM,
			customData: { label },
			name: 'dlr_form_validation_error',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});
};

export const triggerFormSubmissionError = (): void => {
	logAnalyticsEvent({
		eventPayload: {
			action: 'Form submission error',
			category: SIGN_UP_FORM,
			isLegacyEvent: false,
			label: 'Form submission error',
		},
		provider: GOOGLE_ANALYTICS,
	});

	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category: SIGN_UP_FORM,
			customData: { label: 'Form submission error' },
			name: 'dlr_form_submission_error',
			schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
			version: SNOWPLOW_EVENTS.CLICK.VERSION,
		}),
	});
};
