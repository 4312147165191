import React, { createContext, PropsWithChildren, useContext } from 'react';
import getConfig from 'next/config';

import { isSSR } from 'Utilities/helpers';

interface Location {
	href: string;
	origin: string;
	pathname: string;
}

interface LocationProviderProps {
	location: Location;
}

const { publicRuntimeConfig } = getConfig();

const { DEALER_WEBAPP_URL } = publicRuntimeConfig;

export const LocationContext = createContext<Location>({} as Location);

export const LocationProvider: React.FC<PropsWithChildren<LocationProviderProps>> = ({ children, location }) => {
	const locationData: Location = isSSR() ? location : window.location;

	return <LocationContext.Provider value={locationData}>{children}</LocationContext.Provider>;
};

export const useLocation = (): Location => useContext(LocationContext);

interface GetServerSideLocationDataArgs {
	pathname: string;
}

export const getServerSideLocationData = ({ pathname }: GetServerSideLocationDataArgs): Location => ({
	href: pathname && DEALER_WEBAPP_URL + pathname,
	origin: DEALER_WEBAPP_URL,
	pathname,
});
