/* eslint-disable max-classes-per-file,class-methods-use-this */

import { isIterable } from '../../utilities/helpers';
import {
	activeStatuses,
	availableStatesForBIN,
	FOR_SALE_CATEGORY,
	highestBidAvailableStates,
	notYetForSaleVehicleStates,
	SOLD_CATEGORY,
	UNSOLD_CATEGORY,
} from '../../utilities/vehicleState';

const bundle = (first, rest) => [].concat(isIterable(first) ? [...first] : first, ...rest);

export const VEHICLE_PURCHASE_TYPES = {
	AUCTION: 'AUCTION',
	SALE: 'SALE',
};

export class VehiclePermitsCheckerBase {
	constructor(vehicle) {
		this.vehicle = vehicle;
	}

	getVehicleState = () => this.vehicle.state ?? {};

	hasVehicleState = (slug, ...slugs) => bundle(slug, slugs).includes(this.getVehicleState().slug);

	hasVehicleCategory = (category, ...categories) =>
		bundle(category, categories).includes(this.getVehicleState().category);
}

class VehiclePermitsChecker extends VehiclePermitsCheckerBase {
	getId = () => this.vehicle.id;

	getUserBid = () => {
		try {
			const {
				bids: { usersBids },
			} = this.vehicle;
			return usersBids[0] ?? null;
		} catch {
			return null;
		}
	};

	hasUserBid = () => this.getUserBid() !== null;

	selectPriceType = (slug, category, prices) => {
		const { binPrice, reservePrice, soldForPrice } = prices;

		if (notYetForSaleVehicleStates.includes(slug)) {
			return reservePrice;
		}

		if (slug === FOR_SALE_CATEGORY.SALE_ACTIVE) {
			return reservePrice;
		}

		if (slug === FOR_SALE_CATEGORY.UNDER_OFFER) {
			return reservePrice;
		}

		if (category === SOLD_CATEGORY.name) {
			return soldForPrice;
		}

		if (availableStatesForBIN.includes(slug)) {
			return binPrice;
		}

		return reservePrice;
	};

	// To be removed with legacy soldFor field
	getVehiclePrice = () => {
		const { category, slug } = this.getVehicleState();

		const showVAT = this.vehicle.isVatQualifying;

		const reservePrice = { label: 'reservePrice', showVAT, value: this.vehicle.sellerWillAcceptPrice };
		const binPrice = { label: 'buyItPrice', showVAT, value: this.vehicle.sellerWillAcceptPrice };
		const soldForPrice = { label: 'soldFor', showVAT, value: this.vehicle.soldFor };

		return this.selectPriceType(slug, category, {
			binPrice,
			reservePrice,
			soldForPrice,
		});
	};

	getDisplayPrice = () => {
		const { category, slug } = this.getVehicleState();

		const showVAT = this.vehicle.isVatQualifying;

		const reservePrice = { label: 'reservePrice', showVAT, value: this.vehicle.displayPrice };
		const binPrice = { label: 'buyItPrice', showVAT, value: this.vehicle.displayPrice };
		const soldForPrice = { label: 'soldFor', showVAT, value: this.vehicle.displayPrice };

		return this.selectPriceType(slug, category, {
			binPrice,
			reservePrice,
			soldForPrice,
		});
	};

	getHighestBid = () => ({
		label: this.hasVehicleState(availableStatesForBIN) ? VEHICLE_PURCHASE_TYPES.SALE : VEHICLE_PURCHASE_TYPES.AUCTION,
		showVAT: this.vehicle.isVatQualifying,
		value: this.vehicle.highestBid,
	});

	hasDisplayPrice = () => {
		const { value } = this.getDisplayPrice();

		return !!value;
	};

	getVehiclePurchaseType = () =>
		[
			{
				type: VEHICLE_PURCHASE_TYPES.AUCTION,
				value: this.hasVehicleState(FOR_SALE_CATEGORY.SALE_ACTIVE),
			},
			{
				type: VEHICLE_PURCHASE_TYPES.SALE,
				value: this.hasVehicleState(FOR_SALE_CATEGORY.FOR_SALE),
			},
		].find(({ value }) => value) || {};

	displaySaleInfoTag = () => {
		const hasVehicleForSaleState = this.hasVehicleState(FOR_SALE_CATEGORY.FOR_SALE);

		return !hasVehicleForSaleState;
	};

	displayDateSold = () => {
		const hasVehicleSoldState = this.hasVehicleCategory(SOLD_CATEGORY.name);

		return hasVehicleSoldState;
	};

	displayHighestBid = () => {
		const hasVehicleBiddingAllowedState = this.hasVehicleState(highestBidAvailableStates);
		const { value } = this.getHighestBid();

		return hasVehicleBiddingAllowedState && !!value;
	};

	displayValuations = () => {
		const hasVehicleSoldState = this.hasVehicleCategory(SOLD_CATEGORY.name);

		return !hasVehicleSoldState;
	};

	displaySaleTimeCountdown = (shouldUseProcessingStatus) => {
		const hasVehicleSaleActiveState = this.hasVehicleState(FOR_SALE_CATEGORY.SALE_ACTIVE);
		const isVehicleNotYetForSale = this.hasVehicleState(notYetForSaleVehicleStates);

		return (isVehicleNotYetForSale || hasVehicleSaleActiveState) && !shouldUseProcessingStatus;
	};

	displayAddBid = (shouldUseProcessingStatus) => {
		const hasVehicleBiddingAllowedState = this.hasVehicleState(activeStatuses);
		const isOwnedByUserDealership = this.vehicle?.isOwnedByUserDealership;
		const shouldDisplayAddBidInput = hasVehicleBiddingAllowedState && !shouldUseProcessingStatus;

		return shouldDisplayAddBidInput && !isOwnedByUserDealership;
	};

	displayMakeOffer = () => {
		const hasVehicleForSaleState = this.hasVehicleState(FOR_SALE_CATEGORY.FOR_SALE);
		const isOwnedByUserDealership = this.vehicle?.isOwnedByUserDealership;

		return hasVehicleForSaleState && !isOwnedByUserDealership;
	};

	displaySellerDetails = () => {
		// postcode is only returned if you are the winner for this enquiry
		const { seller = {} } = this.vehicle;
		const { postcode } = seller;
		return postcode !== undefined;
	};

	displayPrepCosts = () => {
		const hasCorrectState = this.hasVehicleState(
			FOR_SALE_CATEGORY.FOR_SALE,
			FOR_SALE_CATEGORY.SALE_ACTIVE,
			FOR_SALE_CATEGORY.READY_FOR_SALE,
			FOR_SALE_CATEGORY.UNDER_OFFER,
			UNSOLD_CATEGORY.UNSOLD_BELOW_RESERVE_BID,
		);
		const { initialValuation, prepCosts } = this.vehicle;

		return Boolean(prepCosts && initialValuation && hasCorrectState);
	};
}

export default VehiclePermitsChecker;
