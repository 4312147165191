import { VEHICLE_CHARGING_CABLES } from 'Utilities/consts';

import type { YesNoDash } from './vehicle.schema';

interface Ancestry {
	dateFrom: string;
	dateTo: string;
	recordType: string;
	source: string;
}

export const getTaxiUse = (ancestry?: Ancestry[]): Nullable<Omit<Ancestry, 'recordType'>> => {
	const taxiRecords = ancestry?.find(({ recordType }) => recordType?.toLowerCase() === 'taxi');

	if (!taxiRecords) {
		return null;
	}

	const { dateFrom, dateTo, source } = taxiRecords;
	return {
		dateFrom,
		dateTo,
		source,
	};
};

export const isValidVehicle = (identifierIssues: Nullable<string[]>): boolean => {
	const hasIdentifierIssues = Boolean(Array.isArray(identifierIssues) && identifierIssues?.length);
	return !hasIdentifierIssues;
};

export const getYesNoValue = (value?: boolean | null): Nullable<string> => {
	if (typeof value === 'undefined' || value === null) {
		return null;
	}
	return value ? 'Yes' : 'No';
};

const chargingCables = {
	'3PinDomestic': VEHICLE_CHARGING_CABLES.THREE_PIN_DOMESTIC,
	fast: VEHICLE_CHARGING_CABLES.FAST_CHARGING,
} as const;

export const getChargingCable = (value: (keyof typeof chargingCables)[] | null): string =>
	value ? value?.map((cable) => chargingCables[cable]).join(', ') || 'None' : '-';

export const getYesNoDashValue = (value?: boolean | null): YesNoDash => {
	if (typeof value === 'undefined' || value === null) {
		return '-';
	}
	return value ? 'Yes' : 'No';
};
