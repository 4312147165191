import maxBy from 'lodash/maxBy';

import { VehiclePermitsCheckerBase } from '../../../controllers/VehiclePermitsChecker/VehiclePermitsChecker';
import { BIDS_ALLOWED_STATES, BIDS_COUNT_ALLOWED_STATES } from '../../../utilities/biddingState';
import { highestBidAvailableStates } from '../../../utilities/vehicleState';

const getActiveBidCount = (bids) => bids?.filter((bid) => BIDS_COUNT_ALLOWED_STATES.includes(bid.state)).length || 0;

const getUserBids = (bids, { dealerContactId, dealerId }) =>
	bids?.filter(
		(bid) =>
			bid.dealer.id === dealerId && bid.dealerContact.id === dealerContactId && BIDS_ALLOWED_STATES.includes(bid.state),
	) || [];

/** LEGACY PROXY BIDDING START */
const resolveHighestBid = (bids = []) =>
	maxBy(
		bids?.filter((bid) => BIDS_ALLOWED_STATES.includes(bid.state)),
		'value',
	);
/** LEGACY PROXY BIDDING END */

export const getBids = (bids, { dealerContactId, dealerId }) => {
	const activeBidCount = getActiveBidCount(bids);
	const usersBids = getUserBids(bids, { dealerContactId, dealerId });
	return { activeBidCount, usersBids };
};

/** LEGACY PROXY BIDDING START */
export const getHighestBid = (vehicle, bids) => {
	const permits = new VehiclePermitsCheckerBase(vehicle);
	const shouldIncludeHighestBid = permits.hasVehicleState(highestBidAvailableStates);

	return shouldIncludeHighestBid ? resolveHighestBid(bids)?.value : undefined;
};
/** LEGACY PROXY BIDDING END */
