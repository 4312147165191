import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import isError from 'lodash/isError';

import type { DatadogLogLevel, LogContext, Logger } from './logger.types';

type logToDatadogProps = { context?: LogContext; level: DatadogLogLevel; message: string };

const logToDatadog = ({ context, level, message }: logToDatadogProps): void => {
	const error = context?.error ?? undefined;
	datadogLogs.logger[level](message, context, error);
	datadogRum.addAction(`[${level.toUpperCase()}] ${message}`, context); // Actions used to log within RUM

	// NOTE: RUM errors will appear in `Error Tracking` in Datadog RUM.
	// Use this sparingly, as it will create a lot of noise.
	if (level === 'error') {
		const rumError = isError(error) ? error : new Error(message);
		datadogRum.addError(rumError, context); // Report error to RUM
	}
};

// NOTE: This should not be used directly. Use the logger instead.

export const datadogClientLogger: Logger = {
	critical: (message, context) => logToDatadog({ context, level: 'error', message }),
	debug: (message, context) => logToDatadog({ context, level: 'debug', message }),
	error: (message, context) => logToDatadog({ context, level: 'error', message }),
	fatal: (message, context) => logToDatadog({ context, level: 'error', message }),
	info: (message, context) => logToDatadog({ context, level: 'info', message }),
	trace: (message, context) => logToDatadog({ context, level: 'info', message }),
	warn: (message, context) => logToDatadog({ context, level: 'warn', message }),
};
