import type { z } from 'zod';

import { formatDate } from 'Utilities/formatters';

import type { GetVehicle } from '../helpers/vehicle.schema';
import { getTaxiUse, getYesNoValue, isValidVehicle } from '../helpers/vehicle.schema.helpers';
import type { MotSchema } from '../schemas/limitations.schema';
import { FALLBACK } from '../schemas/schema.const';

interface HpiHistoryCheckProps {
	identifierIssues: GetVehicle['identifierIssues'];
	limitations: GetVehicle['limitations'];
}

const getMotExpiryDate = (mot?: z.infer<typeof MotSchema>) => {
	if (!mot) {
		return '';
	}

	return mot.isInvalid ? formatDate(mot.expiry) : '';
};

const getHpiHistoryCheck = ({ identifierIssues, limitations }: HpiHistoryCheckProps) => {
	if (!limitations) {
		return null;
	}

	return {
		completedOnDate: formatDate(limitations.fetchedAt),
		financeAgreementsCount: (limitations.financeAgreements ?? []).length,
		hasBeenImported: Boolean(limitations.import?.isImported),
		hasBeenSalvaged: Boolean(limitations.salvageAuctions?.length),
		hasBeenStolen: Boolean(limitations.hasBeenStolen),
		hasBeenWrittenOffByDamage: Boolean(limitations.hasBeenWrittenOffByDamage),
		hasBeenWrittenOffByTheft: Boolean(limitations.hasBeenWrittenOffByTheft),
		hasSecurityWatch: Boolean(limitations.hasSecurityWatch),
		isMileageClocked: Boolean(limitations.isMileageClocked),
		isPlateTransferred: Boolean(limitations.isPlateTransferred),
		isVehicleValid: isValidVehicle(identifierIssues),
		motExpiryDate: getMotExpiryDate(limitations.mot),
		taxiUse: getTaxiUse(limitations.ancestry),
	};
};

// The type for this is inferred in VehicleData
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const detailsSectionTransformer = (vehicle: GetVehicle) => ({
	firstRegistered: formatDate(vehicle.spec.firstRegistered),
	hpiHistoryCheck: getHpiHistoryCheck({ identifierIssues: vehicle.identifierIssues, limitations: vehicle.limitations }),
	isVatQualifying: getYesNoValue(vehicle.isVatQualifying),
	/*
	 * isVehicleValid value is used twice because of the legacy architecture of
	 * reused code which requires this field in hpiHistoryCheck.
	 * In some cases limitation comes back as null but we still
	 * want to show modal if identifier issues array is empty.
	 */
	isVehicleValid: isValidVehicle(vehicle.identifierIssues),
	keeperStartDate: formatDate(vehicle.keeperStartDate),
	mileageCheck: vehicle.mileageRecords,
	numberOfKeys: vehicle.keysCount ? vehicle.keysCount : FALLBACK,
	onFinance: getYesNoValue(vehicle.isVehicleOnFinance),
	previousOwners: {
		count: vehicle.previousKeepersCount,
		keepers: vehicle.keepersList,
	},
	privatePlate: getYesNoValue(vehicle.hasPrivatePlate),
	registration: vehicle.prettyVrm,
	sellerKeepingPlate: getYesNoValue(vehicle.keepPrivatePlate),
	vin: vehicle.spec.vin,
	vrm: vehicle.vrm,
});
